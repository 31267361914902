export const getLocalToken = () => localStorage.getItem('HABITAT_TOKEN');

export const saveLocalToken = (token: string) => localStorage.setItem('HABITAT_TOKEN', token);

export const removeLocalToken = () => localStorage.removeItem('HABITAT_TOKEN');

export const transformTree = ({tree, deep, level, hideCatId}: {tree: any; deep?: number, level?: number, hideCatId?: number | null}) => {
    if (!tree) return []
    const localLevel = level ? ++level : 1

    if (localLevel === deep) return []

    if (hideCatId && tree[hideCatId]) {
        delete tree[hideCatId]
    }
    
    return Object.values(tree).map((ch: any) => {
        return {
            ...ch,
            children: transformTree({tree: ch.children, level: localLevel, deep, hideCatId}) || []
        }
    })
}