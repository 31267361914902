import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { CategoryState } from './state';

export const mutations = {
    // setCategory(state: CategoryState, payload: ICategoryProfile) {
    //     const categories = state.categories.filter((category: ICategoryProfile) => category.id !== payload.id);
    //     categories.push(payload);
    //     state.categories = categories;
    // },
    setCategories(state: CategoryState, payload: any) {
        state.categories = payload;
    },
    setCategoriesTree(state: CategoryState, payload: any) {
        state.categoriesTree = payload;
    },
    setParentCategoriesTree(state: CategoryState, payload: any) {
        state.parentCategoriesTree = payload;
    },
    setPreselectedCategoriesTree(state: CategoryState, payload: any) {
        state.preselectedCategoriesTree = payload;
    },
    setFilterByTypeOfWorks(state: CategoryState, payload: string) {
        state.filterByTypeOfWorks = payload
    }
}

const { commit } = getStoreAccessors<CategoryState, State>('');
// export const commitSetCategory= commit(mutations.setCategory);
export const commitSetCategories = commit(mutations.setCategories);
export const commitSetCategoriesTree = commit(mutations.setCategoriesTree);
export const commitSetParentCategoriesTree = commit(mutations.setParentCategoriesTree);
export const commitSetPreselectedCategoriesTree = commit(mutations.setPreselectedCategoriesTree);
export const commitSetFilterByTypeOfWorks = commit(mutations.setFilterByTypeOfWorks);