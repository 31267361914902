






































































































/* eslint-disable */
import { Component, Vue } from "vue-property-decorator";
import { IUserProfileCreate } from "@/interfaces";
import { dispatchGetUsers, dispatchCreateUser } from "@/store/admin/actions";

@Component
export default class CreateUser extends Vue {
  public valid = false;
  public fullName = "";
  public email = "";
  public phone = "";
  public address = "";
  public city = "";
  public zip_code = "";
  public isActive = true;
  public isSuperuser = false;
  public setPassword = false;
  public password1 = "";
  public password2 = "";

  public async mounted() {
    this.reset();
  }

  public reset() {
    this.password1 = "";
    this.password2 = "";
    this.fullName = "";
    this.email = "";
    this.isActive = true;
    this.isSuperuser = false;
    this.$validator.reset();
  }

  public cancel() {
    this.$router.back();
  }

  public async submit(disabledRedirect) {
    if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
      const updatedProfile: IUserProfileCreate = {
        email: this.email,
      };
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      updatedProfile.password = this.password1;

      updatedProfile.phone = this.phone;
      updatedProfile.address = this.address;
      updatedProfile.city = this.city;
      updatedProfile.zip_code = this.zip_code;

      const user = await dispatchCreateUser(this.$store, updatedProfile);
      if (!disabledRedirect) {
        this.$router.push("/main/admin/users");
      }
      if (user.id && disabledRedirect) {
        this.$router.replace(`/main/admin/users/edit/${user.id}`);
      }
    }
  }
}
