var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.items,"single-expand":_vm.singleExpand,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expand-content-td",attrs:{"colspan":headers.length}},[_c('v-data-table',{staticClass:"expand-table-style",attrs:{"headers":headers,"items":_vm.formatChildList(item)},scopedSlots:_vm._u([{key:"item.button-popup",fn:function(ref){
var item = ref.item;
return [(!item.children)?_c('v-icon',{on:{"click":function($event){return _vm.popupEvent(item)}}},[_vm._v("mdi-drag")]):_vm._e()]}},{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editEvent(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteEvent(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}],null,true)})],1)]}},{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.editEvent(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteEvent(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }