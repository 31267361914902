



































































































































































/* eslint-disable */
import { IPdfContent, IPdfContentFiles, IQuotaPdfDefaultSettings } from '@/interfaces';
import {
    dispatchUpdateDefaultPdfAssurance,
    dispatchUpdateDefaultPdfCertifications,
    dispatchUpdatePdfContent
} from '@/store/admin/actions';
import { Component, Vue } from 'vue-property-decorator';
@Component({
    components: {
        CropImage: () => import('@/components/ui/CropImage.vue')
    }
})
export default class PdfSettings extends Vue {
    $refs!: {
        formQuotaPdfSettings: HTMLFormElement
    }
    wysiwygOptions = {
        // debug: 'info',
        modules: {
            toolbar: [
                [{ size: [ 'small', false, 'large', 'huge' ]}],
                ['bold', 'italic', 'underline', 'strike', {'color': []}],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['link'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                [{ 'align': 'left'}, { 'align': 'center'}, {'align': 'right'}, {'align': 'justify'}],
                ['clean']
            ]
        },
        placeholder: this.$t('termsAndConditions'),
        readOnly: true,
        // theme: 'snow',
    }

    unwatch: any = null
    validQuotaPdfSettings: boolean = false;
    fileButtonLoader: boolean = false
    quotaPdfSettings: IQuotaPdfDefaultSettings = this.$store.state.admin.quotaPdfSettings as IQuotaPdfDefaultSettings
    pdfContent: IPdfContent = this.$store.state.admin.pdfContent as IPdfContent
    pdfContentFiles: IPdfContentFiles = {} as IPdfContentFiles

    created() {
        this.unwatch = this.$store.watch(
            (state) => [state.admin.quotaPdfSettings, state.admin.pdfContent],
            (res) => {
                this.pdfContent = res[1]
                this.quotaPdfSettings = res[0]
            },
        );
    }

    beforeDestroy() {
        this.unwatch();
    }

    async submitFiles():Promise<void> {
        this.$refs["formQuotaPdfSettings"].validate()
        
        if (this.validQuotaPdfSettings) {
        if (
            this.quotaPdfSettings.certificationsDefaultPDF && this.quotaPdfSettings.assuranceDefaultPDF) {
            this.fileButtonLoader = true

            if (this.quotaPdfSettings.certificationsDefaultPDF[0] instanceof File) {
            await dispatchUpdateDefaultPdfCertifications(this.$store, this.quotaPdfSettings.certificationsDefaultPDF)
            }

            if (this.quotaPdfSettings.assuranceDefaultPDF[0] instanceof File) {
            await dispatchUpdateDefaultPdfAssurance(this.$store, this.quotaPdfSettings.assuranceDefaultPDF)
            }

            
            await dispatchUpdatePdfContent(this.$store, {
              ...this.pdfContentFiles,
              conditions: this.pdfContent.conditions,
              legal_info: this.pdfContent.legal_info
            })
            this.fileButtonLoader = false
        }
        }
    }

    setLogo(payload: {name: string, file: File}) {
        this.pdfContentFiles[payload.name] = payload.file
    }

    addImage(event) {
      if (event) {
          let reader = new FileReader();
          reader.onload = (e) => {
            this.pdfContent.team = e?.target?.result as string;
          };
          reader.readAsDataURL(event);
      }
    }
}
