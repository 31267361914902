import { Locales } from "./locales";
import { locale } from "@/env";

import en from "./en.json";
import fr from "./fr.json";

export const messages = {
  [Locales.en]: en,
  [Locales.fr]: fr
};

export const defaultLocale = Locales[locale];