

























































































/* eslint-disable */
import { Component, Mixins } from 'vue-property-decorator'
import {readAdminOneRoom } from '@/store/admin/getters';
import { RoomCategories } from './mixins'
import {
    dispatchGetRoomById,
    dispatchUpdateRoom
} from '@/store/admin/actions';
import { dispatchGetRoomsPreselectedCategoriesTree, dispatchGetCategories } from '@/store/categories/actions';

@Component
export default class EditRoom extends Mixins(RoomCategories) {

    get room(): any {
        return readAdminOneRoom(this.$store)(+this.$router.currentRoute.params.id);
    }
    
    fillModel() {
     if (this.room) {
         this.name = this.room.name;
         this.selectedImg = this.room.image;
         this.defaultRoom = this.room.default;
        this.selectedCategory = this.room.categories?.filter(c => c.preselected === true).map(category => {
            return category.category_id
        });
        this.selectedPreselectedCategory = this.room.categories?.filter(c => c.preselected === false).map(category => {
            return category.category_id
        });
     }
    }

    async submit(disabledRedirect) {
        if (await this.$validator.validateAll()) {

            const formData = new FormData()
            formData.append('name', this.name);
            formData.append('image', this.image);
            formData.append('default', `${this.defaultRoom || false}`)

            await dispatchUpdateRoom(this.$store, {id: this.room?.id, formData}).then(async (data) => {
              this.updateRoomCategories(data.id)
            });
            if (!disabledRedirect) {
              await this.$router.push('/main/admin/rooms/all');
            }
        }
    }

    async mounted(): Promise<void> {
        await dispatchGetRoomById(this.$store, +this.$route.params.id);
        await dispatchGetCategories(this.$store)
        await dispatchGetRoomsPreselectedCategoriesTree(this.$store, this.$route.params.id)
        this.fillModel();
    }
}
