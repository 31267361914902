var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"expand-table-style",attrs:{"headers":_vm.headers,"items":_vm.services,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleEditButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.currentItem = item; _vm.dialog = true}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}])}),_c('ConfirmDialog',{attrs:{"show":_vm.dialog},on:{"closeModal":function($event){_vm.dialog = false; _vm.currentItem = null},"confirmModalAgree":function($event){return _vm.deleteEvent(_vm.currentItem)}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }