var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('manageQuotas'))+" ")]),_c('v-spacer')],1),(_vm.quotas)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.filteredQuotas,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"header.manager_name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.managerName ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Entrez le terme de recherche"},model:{value:(_vm.managerName),callback:function ($$v) {_vm.managerName=$$v},expression:"managerName"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.managerName = ''}}},[_vm._v(_vm._s(_vm.$t('clean')))])],1)])]}},{key:"header.client_name",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"offset-y":"","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.clientName ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-text-field',{staticClass:"pa-4",attrs:{"type":"text","label":"Entrez le terme de recherche"},model:{value:(_vm.clientName),callback:function ($$v) {_vm.clientName=$$v},expression:"clientName"}}),_c('v-btn',{staticClass:"ml-2 mb-2",attrs:{"small":"","text":"","color":"primary"},on:{"click":function($event){_vm.clientName = ''}}},[_vm._v(_vm._s(_vm.$t('clean')))])],1)])]}},{key:"header.custom_status",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-menu',{attrs:{"close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":_vm.customStatus ? 'primary' : ''}},[_vm._v(" mdi-filter ")])],1)]}}],null,true)},[_c('div',{staticStyle:{"background-color":"white","width":"280px"}},[_c('v-list',_vm._l((_vm.customStatusList),function(listItem,i){return _c('v-list-item',{key:i,on:{"click":function($event){return _vm.handleQuotaStatusFilter(listItem)}}},[_c('v-list-item-title',[_vm._v(_vm._s(listItem))])],1)}),1)],1)])]}},{key:"item.history_date",fn:function(ref){
var item = ref.item;
return [(item.history_date.values.length)?_c('div',{staticClass:"date-wrapper"},[_c('ul',{staticClass:"date-list",staticStyle:{"list-style":"none"}},_vm._l((item.history_date.values),function(history){return _c('li',{key:history.id},[_c('span',[_vm._v(_vm._s(history.date))])])}),0),_c('div',{staticStyle:{"padding-left":"10px"}},[(item.history_date.values.length !== item.history_date.pages && 3 < item.history_date.pages)?_c('button',{staticClass:"date-more-button",staticStyle:{"color":"#1976d2"},on:{"click":function($event){return _vm.nextPage(item.history_date.pages, item)}}},[_vm._v("Suite")]):_vm._e()])]):_vm._e()]}},{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.handleEditQuotaButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleDeleteAction(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}],null,false,692623254)}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }