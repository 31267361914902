











/* eslint-disable */
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import {dispatchCreateQuota} from '@/store/admin/actions';
import QuotaForm from "./QuotaForm.vue";

@Component({
    components: {
        "quota-form": QuotaForm
    }
})
export default class CreateQuota extends Vue {

    //COMPUTED
    get projectId() {
        return this.$route.params.projectId
    }
    get currentDate() {
        return (new Date()).toISOString().split('T')[0]
    }
    //METHODS
    async submit(values) {
        if (await this.$validator.validateAll()) {
            const updatedConfig = {
                project_id: '',
                edit: '',
                name: '',
                custom_status: '',
                hours_of_execution: '',
                price_stage_1: 0,
                price_stage_2: 0,
                price_stage_3: 0,
                create_date: '',
                start_work_date: '',
                visit_date: '',
            };
            updatedConfig.name = values.name;
            updatedConfig.project_id = this.projectId;
            updatedConfig.edit = values.edit;
            updatedConfig.custom_status = values.custom_status;
            updatedConfig.create_date = this.currentDate;
            updatedConfig.hours_of_execution = values.hours_of_execution;
            updatedConfig.price_stage_1 = values.paymentConditions.acompte.value;
            updatedConfig.price_stage_2 = values.paymentConditions.demarage.value;
            updatedConfig.price_stage_3 = values.paymentConditions.situation.value;
            updatedConfig.start_work_date = values.start_work_date;
            updatedConfig.visit_date = values.visit_date;
            const quota = await dispatchCreateQuota(this.$store, updatedConfig);
            if (!values.disabledRedirect) {
                await this.$router.push('/main/admin/quotas/list');
            }
            if (quota.id && values.disabledRedirect) {
                await this.$router.replace(`/main/admin/quotas/edit/${quota.id}`);
            }
        }
    }
}

