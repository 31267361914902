/* eslint-disable */
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { CategoryState } from './state';
import { ICategoryTree } from '@/interfaces';
import { i18n } from '@/plugins/i18n';
import { transformTree } from '@/utils';

export const getters = {
    getCategories: (state: CategoryState) => {
        if (state.categories && state.filterByTypeOfWorks === '') {
            let list = {}
            for (const catKey in state.categories) {
                if (state.categories[catKey].children) {
                    for (const childKey in state.categories[catKey].children) {
                        list[childKey] = state.categories[catKey].children[childKey]
                    }
                }
            }
            return transformTree({tree: list, deep: 3}).sort((prev, next) => prev.id - next.id)
        }

        if (state.categories && state.filterByTypeOfWorks !== '' && state.categories[state.filterByTypeOfWorks]) {
            let categories = state.categories[state.filterByTypeOfWorks]
            for (let key in categories.children ) {
                categories.children[key].typeName = state.categories[state.filterByTypeOfWorks].name

                if (categories[key]?.children) {
                    let children2 = { ...categories[key].children }
                    for (let key2 in children2) {
                        children2[key2].typeName = state.categories[state.filterByTypeOfWorks].name
                        children2[key2].typeId = state.categories[state.filterByTypeOfWorks].id
                    }
                    categories.children = children2
                }
            }
            return Object.values<ICategoryTree>(categories.children).sort((prev, next) => prev.id - next.id) 
        }

        return []
    },
    getTypeOfWorks: (state: CategoryState) => {
        let list = state.categories ? Object.values(state.categories) : []
        list = list.map(c => {
            for (let key in c.children) {
                c.children[key].parent_id = c.id
            }
            return c
        })
        return list.sort((prev, next) => prev.id - next.id);
    },
    getOptionsTypeOfWorks: (state: CategoryState) => {
        let options = [{value: '', key: `${i18n.t('all')}`},]
        let otherOptions = state.categories && Object.values(state.categories).sort((prev, next) => prev.id - next.id).map(c => {
            return {
                value: c.id,
                key: c.name
            }
        })
        return otherOptions ? [...options, ...otherOptions] : options 
    },
    getCategoryTreeForTypeOfWorkPage: (state: CategoryState) => {
        return transformTree({tree: state.categories, deep: 3})
    },
    getCategoryTreeForCategoryPage: (state: CategoryState) => {
        return (catId: number | null) => {
            return transformTree({tree: state.categories, deep: 3, hideCatId: catId })
        }
    },
    getCategoriesWithoutTypeOfWork: (state: CategoryState) => {
        let list = {}
        for (const catKey in state.categories) {
            if (state.categories[catKey].children) {
                for (const childKey in state.categories[catKey].children) {
                    list[childKey] = state.categories[catKey].children[childKey]
                }
            }
        }
        return transformTree({tree: list, deep: 3}).sort((prev, next) => prev.id - next.id)
    },
    getPreselectedCategoriesWithoutTypeOfWork: (state: CategoryState) => {
        let list = {}
        for (const catKey in state.preselectedCategoriesTree) {
            if (state.preselectedCategoriesTree[catKey].children) {
                for (const childKey in state.preselectedCategoriesTree[catKey].children) {
                    list[childKey] = state.preselectedCategoriesTree[catKey].children[childKey]
                }
            }
        }
        return transformTree({tree: list, deep: 3}).sort((prev, next) => prev.id - next.id)
    }
};

const { read } = getStoreAccessors<CategoryState, State>('');
export const readCategories = read(getters.getCategories);
export const readTypeOfWorks = read(getters.getTypeOfWorks);
export const readOptionsTypeOfWorks = read(getters.getOptionsTypeOfWorks);
export const readCategoryTreeForTypeOfWorkPage = read(getters.getCategoryTreeForTypeOfWorkPage);
export const readCategoryTreeForCategoryPage = read(getters.getCategoryTreeForCategoryPage);
export const readCategoriesWithoutTypeOfWork = read(getters.getCategoriesWithoutTypeOfWork);
export const readPreselectedCategoriesWithoutTypeOfWork = read(getters.getPreselectedCategoriesWithoutTypeOfWork);