












/* eslint-disable */
import { Vue, Component, Prop } from 'vue-property-decorator'
@Component
export default class DataTableRowHandler extends Vue {
    @Prop([String]) readonly itemClass!: "";
    @Prop([Object]) readonly item!: {};
    @Prop([Array]) readonly headers!: [];

    get getClass() {
        return this.itemClass;
    }

    columnName(header: any) {
        return `item.${header?.value}`;
    }

    getAlignment(header: any) {
        const align = header.align ? header.align : "right";
        return `text-align: ${align}`;
    }
    getNonSlotValue(item: any, header: any) {
        const val = item[header.value];

        if (val) {
        return val;
        }

        return "";
    }
}
