


























/* eslint-disable */
import { IService } from '@/interfaces';
import { dispatchCreateAssociateServices, dispatchGetServices, dispatchGetServicesByCatId } from '@/store/admin/actions';
import { readServicesOfCategory } from '@/store/admin/getters';
import { Vue, Component, Watch } from 'vue-property-decorator'

@Component
export default class ServiceCategories extends Vue {
  loading = false
  selectedServices: IService[] = []

  get servicesCat() {
    let list = readServicesOfCategory(this.$store)(this.$route.params.id)
    return Array.isArray(list) ? list : []
  }

  get services() {
    return this.$store.state.admin.services
  }

  @Watch('servicesCat', { immediate: true })
  public async onServicesCatChange(
      newVal: IService[]
  ) {
    this.selectedServices = [...newVal]
  }

  async handlerProductAutocomplete(payload: IService[]) {
    this.loading = true
    let selectedServices = [...this.servicesCat]
    let serviceCategories = payload.map((s) => {
      return {
        category_id: +this.$route.params.id,
        service_id: s.id,
        position: s.position || 0
      }
    })
    let deleteServices = selectedServices
      .filter(s => serviceCategories.find((sc) => sc.service_id ===  s.id) === undefined)
      .map(s => {
        return {
          category_id: +this.$route.params.id,
          service_id: s.id,
        }
      })
    await dispatchCreateAssociateServices(this.$store, {
      deleteService: deleteServices.length > 0 ? deleteServices : null,
      createService: serviceCategories
    }).then(async () => {})
    await dispatchGetServicesByCatId(this.$store, this.$route.params.id)
    this.loading = false
  }
}
