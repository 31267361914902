






















/* eslint-disable */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component
export default class AlertDialog extends Vue {
    @Prop(Boolean) readonly show: false = false;
    @Prop({type: String, default: "title"}) readonly title!: String;
    @Prop({type: String, default: "450"}) readonly maxWidth!: String;

    dialog = false;
    

    @Watch('show', { immediate: true })
    onValueChanged(newVal: boolean): void {
        this.dialog = newVal;
    }
}
