










































































































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import {
    dispatchDeleteQuota,
    dispatchGetAllQuotas,
    dispatchGetProject,
    dispatchGetQuotasStatuses,
    dispatchGetUsers,
    dispatchGetProjectHistories
} from '@/store/admin/actions';
import {readAdminUsers, readProjects, readQuotas} from "@/store/admin/getters";

@Component
export default class AllQuotas extends Vue {
    //DATA
    headers = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('customStatus'),
            sortable: true,
            value: 'custom_status',
            align: 'left',
        },
        {
            text: this.$t('validationDate'),
            sortable: true,
            value: 'history_date',
            align: 'left',
        },
        {
            text: this.$t('projectName'),
            sortable: true,
            value: 'project_name',
            align: 'left',
        },
        {
            text: this.$t('manager'),
            sortable: true,
            value: 'manager_name',
            align: 'left',
        },
        {
            text: this.$t('client'),
            sortable: true,
            value: 'client_name',
            align: 'left',
        },
        {
            text: this.$t('edit'),
            sortable: true,
            value: 'edit',
            align: 'left',
        },
        {
            text: this.$t('actions'),
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        },
    ];
    managerName = '';
    clientName = '';
    customStatus = '';
    currentQuotaPages = {}


    //COMPUTED
    get filteredQuotas() {

        let conditions: any = [];

        if (this.managerName) {
            conditions.push(this.filterManagerName);
        }
        if (this.clientName) {
            conditions.push(this.filterClientName);
        }
        if (this.customStatus) {
            conditions.push(this.filterCustomStatus);
        }

        if (conditions.length > 0) {
            return this.quotas.filter((qouta) => {
                return conditions.every((condition) => {
                    return condition(qouta);
                })
            })
        }

        return this.quotas;
    }



    get quotas() {
        return this.$store.state.admin.allQuotas.map(quota => {
            const projectName: any = this.projects.find((project) => project.id == quota.project_id);
            const clientName = projectName.user.full_name
            const manager = this.users.find((user) => user.id == projectName?.manager_id);
            const historyDateList = this.projectHistories.length ? this.projectHistories?.filter((history) => history.quota_id == quota?.id) : [];

            if (Object.keys(this.currentQuotaPages).length !== this.$store.state.admin.allQuotas.length) {
                this.$set(this.currentQuotaPages, `quota${quota.id}`, 1);
            }

            const updatedQuota: any = {
                ...quota
            }
            updatedQuota.custom_status = this.customStatusList[quota.custom_status];
            updatedQuota.edit = updatedQuota.edit ? this.$t('true') : this.$t('false');
            updatedQuota.project_name = projectName ? projectName.devis_name : '';
            updatedQuota.client_name = clientName ? clientName : '';
            updatedQuota.manager_name = manager ? manager.full_name : '';
            updatedQuota.history_date = {
                values: historyDateList.slice(0,  this.currentQuotaPages[`quota${quota.id}`] * 3),
                pages: historyDateList.length
            }
            return updatedQuota
        }).sort((prev, next) => prev.id - next.id);
    }
    get users() {
        return readAdminUsers(this.$store);
    }
    get projects() {
        return readProjects(this.$store)
    }
    get projectHistories() {
        return this.$store.state.admin.projectHistories.length ? this.$store.state.admin.projectHistories.filter(history => history.status_id == 2) : []
    }
    get customStatusList(): any {
        let modifiedList = {}
        modifiedList = {
            ...this.$store.state.admin.quotasStatuses,
            5:  this.$t('all')
        }
        return modifiedList
    }
    //METHODS
    nextPage(totalPages, quota){
        let currentPageNumber = JSON.parse(JSON.stringify(this.currentQuotaPages[`quota${quota.id}`]));
        let updatedPageNumber = +currentPageNumber + 1;
        if( this.currentQuotaPages[`quota${quota.id}`] < totalPages)  this.$set(this.currentQuotaPages, `quota${quota.id}`, updatedPageNumber);
    }

    filterManagerName(item) {
        return item.manager_name?.toLowerCase().includes(this.managerName.toLowerCase());
    }
    filterClientName(item) {
        return item.client_name?.toLowerCase().includes(this.clientName.toLowerCase());
    }
    filterCustomStatus(item) {
        return item.custom_status?.toLowerCase().includes(this.customStatus.toLowerCase());
    }

    async getTableData() {
        const status_id = '2';
        await dispatchGetAllQuotas(this.$store).then( async () => {
            const quotasIdList = this.$store.state.admin.allQuotas.map(quota => quota.id).join(',');
            await dispatchGetProjectHistories(this.$store, {quota_id: quotasIdList, status_id: status_id});
        });
    }



    handleQuotaStatusFilter(filterValue) {
        if (filterValue === this.$t('all')) {
            this.customStatus = '';
        } else {
            this.customStatus = filterValue
        }
    }
    handleEditQuotaButton(quota) {
        const path = `/main/admin/project/${quota.project_id}/quotas/edit/${quota.id}`;
        this.$router.push({path});
    }
    async handleDeleteAction(quota) {
        await dispatchDeleteQuota(this.$store, quota.id);
        await dispatchGetAllQuotas(this.$store);
    }
    async mounted(): Promise<void> {
        await dispatchGetUsers(this.$store, {queryParams: '?limit=3500'});
        await dispatchGetProject(this.$store);
        await dispatchGetQuotasStatuses(this.$store);
        this.getTableData()

    }
}
