import { mutations } from './mutations';
import { getters } from './getters';
import { actions } from './actions';
import { AdminState } from './state';
import { getCompanyName, getFavicon } from '@/utils/storage';
import { IEmailSettings, IPdfContent } from '@/interfaces';

const defaultState: AdminState = {
  users: [],
  superUsers: [],
  projects: [],
  rooms: [],
  adminOneRoom: null,
  categories: [],
  quotas: [],
  allQuotas: [],
  settings: [],
  emailSettings: {
    logo: "/images/logo-placeholder.jpg",
  } as IEmailSettings,
  platformParameters: {
    id: null,
    name: getCompanyName(),
    main_logo: "/images/logo-placeholder.jpg",
    logo: "/images/logo-placeholder.jpg",
    favicon: getFavicon()
  },
  pdfContent: {
    id: null,
    footer_logo: "/images/logo-placeholder.jpg",
    header_logo: "/images/logo-placeholder.jpg",
    main_logo: "/images/logo-placeholder.jpg",
    legal_info: "",
    conditions: "",
    team: ""
  } as IPdfContent,
  quotaPdfSettings: {
    certificationsDefaultPDF: [],
    assuranceDefaultPDF: [],
    legalInformation: "",
    legalInformationId: null,
    ourTeam: null,
    termsAndConditions: null
  },
  quotasPdfFiles: {
    certificationsOwnPDF: [],
    assuranceOwnPDF: [],
  },
  products: [],
  serviceCombinations: [],
  serviceCombinationProducts: [],
  services: [],
  relatedServiceProducts: [],
  serviceById: {},
  serviceCategory: [],
  serviceByCatId: [],
  serviceCategories: [],
  relatedProducts: [],
  serviceProducts: [],
  serviceProductPrices: [],
  units: [],
  unit: {},
  // categoriesTree: [],
  // preselectedCategoriesTree: [],
  // parentCategoriesTree: [],
  roomsCategories: [],
  roomsCategory: [],
  quotasStatuses: [],
  projectHistories: [],
  messages: []
};

export const adminModule = {
  state: defaultState,
  mutations,
  actions,
  getters,
};
