















/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { readCategories } from '@/store/categories/getters';
import { dispatchDeleteCategory, dispatchGetCategories } from '@/store/categories/actions';

@Component
export default class Services extends Vue {

    //DATA
    nav: Array<object> = [
        {
            title: 'Service',
            routeName: 'service-edit'
        },
        {
            title: 'Type',
            routeName: 'combination-services-settings'
        },
        {
            title: this.$t('relatedProducts'),
            routeName: 'related-products-services-settings'
        }
    ];

    //COMPUTED
    get categories() {
        return readCategories(this.$store);
    }

    //METHODS
    async handleMenuButton(value) {
        await dispatchDeleteCategory(this.$store, value.id)
        await dispatchGetCategories(this.$store);
    }
    handleEditButton(value) {
        this.$router.push({name: 'main-admin-category-edit', params: {id: value.id}})
    }
    //HOOKS
    async mounted(): Promise<void> {

    }
}
