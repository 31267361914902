var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('manageProjects'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/projects/create"}},[_vm._v(_vm._s(_vm.$t('createProject')))])],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.projects,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('td',[_vm._v(_vm._s(props.item.full_name))]),_c('td',[_vm._v(_vm._s(props.item.email))]),_c('td',[(props.item.is_active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1),_c('td',[(props.item.is_superuser)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()],1)]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){$event.stopPropagation();return _vm.handleEditButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleMenuButton(item,'delete')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }