





































































































/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { readCombinations } from '@/store/admin/getters';
import {
    dispatchGetServiceCombinations,
    dispatchCreateServiceCombination,
    dispatchUpdateServiceCombination,
    dispatchDeleteServiceCombination,
    dispatchDeleteService
} from '@/store/admin/actions';

@Component
export default class ServicesCombinations extends Vue {

    //DATA
    headers: Array<object> = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('price'),
            sortable: true,
            value: 'price',
            align: 'left',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        }
    ];
    defaultCombination: any = [];
    valid: boolean = false;
    dialog: boolean = false;

    editButton: boolean = false;
    combinationId: number | string = '';

    name: string = '';
    price: number = 0;
    default: boolean = false;

    //COMPUTED
    get combinations() {
        return readCombinations(this.$store);
    }
    get serviceId() {
        return this.$route.params.id
    }

    //METHODS
    async deleteEvent(value) {
        await dispatchDeleteServiceCombination(this.$store, value.id)
        await dispatchGetServiceCombinations(this.$store, this.serviceId);
    }
    async handlerDefaultCombination(value) {
        const combination = value.item;
        const dataObject = {
            name: '',
            price: '',
            default: false,
            service_id: this.serviceId
        };
        if (value.value) {
            dataObject.name = combination.name;
            dataObject.price = combination.price;
            dataObject.default = true;

            await dispatchUpdateServiceCombination(this.$store, {id: combination.id, dataObject});
            await dispatchGetServiceCombinations(this.$store, this.serviceId);
        }
        if (!value.value) {
            dataObject.name = combination.name;
            dataObject.price = combination.price;
            dataObject.default = false;
            await dispatchUpdateServiceCombination(this.$store, {id: combination.id, dataObject});;
        }
    }
    handleEditButton(value) {
        this.combinationId = value.id;
        this.name = value.name;
        this.price = value.price;
        this.default = value.default;
        this.editButton = true
        this.dialog = true
    }
    handleCreateCombination() {
        this.name = '';
        this.price = 0;
        this.editButton = false
        this.dialog = true
    }
    async getServiceCombinations() {
        await dispatchGetServiceCombinations(this.$store, this.serviceId);
        const defaultCombination = this.combinations.find(combination => combination.default === true);
        this.defaultCombination.push(defaultCombination)
    }
    async submit() {
        this.dialog = false;
        if (await this.$validator.validateAll()) {
            const dataObject = {
                name: this.name,
                price: this.price,
                default: false,
                service_id: this.serviceId
            }
            await dispatchCreateServiceCombination(this.$store, dataObject);
        }
    }
    async update() {
        this.dialog = false;
        this.editButton = false;
        if (await this.$validator.validateAll()) {
            const dataObject = {
                name: this.name,
                price: this.price,
                default: this.default,
                service_id: this.serviceId
            }
            await dispatchUpdateServiceCombination(this.$store, {id: this.combinationId, dataObject});
            await dispatchGetServiceCombinations(this.$store, this.serviceId);
        }
    }
    //HOOKS
    async mounted(): Promise<void> {
        await this.getServiceCombinations()
    }
}
