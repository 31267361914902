















































/* eslint-disable */
import { Vue, Mixins } from 'vue-property-decorator'
import { EditCategory as EditCategoryMixin } from './mixins'
import { dispatchGetCategories, dispatchUpdateCategory } from '@/store/categories/actions';
import { readCategoryTreeForTypeOfWorkPage } from '@/store/categories/getters';


export default class EditTypeOfWork extends Mixins(EditCategoryMixin) {
    get categories() {
        return readCategoryTreeForTypeOfWorkPage(this.$store)
    }

    async submit(disabledRedirect) {
        if (this.currentCategory && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            const formData = new FormData()
            const parentId = '1';

            formData.append('name', this.name);
            formData.append('description', this.description);
            formData.append('image', this.image);
            formData.append('id', `${this.currentCategory.id}`);
            formData.append('parent_id', parentId);

            await dispatchUpdateCategory(this.$store, { id: this.currentCategory.id, formData });
            await dispatchGetCategories(this.$store)
            if (!disabledRedirect) {
                await this.$router.push('/main/admin/type-of-work/all');
            }
        }
    }

}
