import { i18n } from '@/plugins/i18n'
export const getCompanyName = () => {
    const companyName = localStorage.getItem('company_name') || i18n.t("companyNameDefault")
    const pageTitle = document.querySelector("title")
    if (pageTitle) {
        pageTitle.innerHTML = `${companyName}`
    }
    return `${companyName}`
}

export const setCompanyName = (name: string) => {
    localStorage.setItem('company_name', name)
    const pageTitle = document.querySelector("title")
    if (pageTitle) {
        pageTitle.innerHTML = `${name}`
    }
}

export const getFavicon = () => {
    const favicon = localStorage.getItem('favicon') || ""
    const faviconDom = document.querySelector('link[rel="icon"]')
    if (favicon && faviconDom) {
        faviconDom.setAttribute('href', favicon)
    }
    return favicon
}

export const setFavicon = (url: string) => {
    localStorage.setItem('favicon', url)
    const faviconDom = document.querySelector('link[rel="icon"]')
    if (url && faviconDom) {
        faviconDom.setAttribute('href', url)
    }
}