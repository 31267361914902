import Vue from 'vue'
import './component-hooks';
import './plugins/axios'
import './plugins/vee-validate';
import { i18n, setI18nLanguage } from './plugins/i18n'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueQuillEditor from 'vue-quill-editor'

import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

Vue.use(VueQuillEditor, /* { default global options } */)

Vue.use(VueLodash, { name: 'custom', lodash: lodash })


Vue.config.productionTip = false

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App),
  created() {
    setI18nLanguage(this);
  }
}).$mount('#app')
