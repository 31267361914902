




























































































































































































































































































































































  /* eslint-disable */
  import {Component, Vue} from 'vue-property-decorator';

  import {  IProjectProfileUpdate } from '@/interfaces';
  import {
      dispatchGetProject,
      dispatchUpdateProject,
      dispatchGetQuotas,
      dispatchGetUsers,
      dispatchDeleteQuota,
      dispatchGetSuperUsers, dispatchGetQuotasStatuses
  } from '@/store/admin/actions';
  import {readAdminOneProject, readAdminUsers, readQuotas,} from '@/store/admin/getters';
  import axios from "axios";
  import {apiUrl, VHClientQuotaUrl} from "@/env";
  import {api} from "@/api";
  import {readToken} from "@/store/main/getters";
import { getLocalToken } from '@/utils';
  @Component
  export default class EditProject extends Vue {
      //DATA
      valid = false;
      serverResponse: any = null;
      date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
      menu2 = false;
      headers = [
          {
              text: this.$t('ID'),
              sortable: true,
              value: 'id',
              align: 'left',
          },
          {
              text: this.$t('name'),
              sortable: true,
              value: 'name',
              align: 'left',
          },
          {
              text: this.$t('edit'),
              sortable: true,
              value: 'edit',
              align: 'left',
          },
          {
              text: this.$t('customStatus'),
              sortable: true,
              value: 'custom_status',
              align: 'left',
          },
          {
              text: this.$t('actions'),
              value: 'button-edit',
              width: '50px',
              sortable: false,
              align: 'center',
          },
          {
              text: '',
              value: 'button-menu',
              width: '50px',
              sortable: false,
              align: 'center',
          },
      ];
      address_construction =  '';
      email =  '';
      initial_message =  '';
      postal_code =  '';
      type_of_extension = ''
      type_of_elevation = ''
      address = '';
      city = '';
      type_of_object = '';
      type_of_renovation = '';
      need_an_architect = '';
      need_an_education = '';
      product_class = '';
      criterias: any = [];
      budget = 0;
      need_credit = '';
      start_date: any = null;
      end_date: string | null = '';
      state_of_object = '';
      simple_choice = '';
      square = '';
      selectedUser: any = null;
      selectedProjectManager: any = null;
      dialog = false
      IdQuotaToDelete: any = ''
      devis_name = '';
      showTooltip = false;
      fieldRules = [
          value => !!value || `${this.$t('required')}.`
      ];
      rulesNumber = [ v => {
          if(v) {
              return /^\d+$/.test(v) || 'Seul numéro'
          } else {
              return true
          }
      }];

      //COMPUTED
      get token() {
          return readToken(this.$store)
      }
      get authHeaders() {
          return {
              headers: {
                  Authorization: `Bearer ${this.$store.state.main.token}`,
              },
          };
      }
      get disableProjectNameField() {
          return !!this.project?.devis_id
      }
      get quotas() {
          return readQuotas(this.$store).map(quota => {
              const updatedQuota: any = {
                  ...quota
              }
              updatedQuota.custom_status = this.customStatusList[quota.custom_status];
              updatedQuota.edit = updatedQuota.edit ? this.$t('true') : this.$t('false')
              return updatedQuota
          });
      }
      get customStatusList(): any {
          return this.$store.state.admin.quotasStatuses
      }
      get VHClientQuotaUrl() {
          return VHClientQuotaUrl
      }
      get project() {
          return readAdminOneProject(this.$store)(+this.$router.currentRoute.params.id);
      }
      get quotasCount() {
          return this.quotas.length >= 2
      }
      get usersWithoutProject() {
          let userList: any = []
          let currentUser = this.users.find(user => {
              const projectOwnerId: any = this.project?.owner_id
              return user.id == projectOwnerId
          })
          userList = readAdminUsers(this.$store).filter(user => !user.is_superuser && user.projects.length === 0);
          userList.unshift(currentUser)
          return userList
      }
      get users() {
          return readAdminUsers(this.$store)
      }
      get superUsers() {
          return this.$store.state.admin.superUsers
      }
      get typeOfElevationValues() {
          const typeOfRenovation = this.serverResponse.type_of_elevation
          return this.createValuesForInput(typeOfRenovation)
      }
      get typeOfExtensionValues() {
          const typeOfRenovation = this.serverResponse.type_of_extension
          return this.createValuesForInput(typeOfRenovation)
      }
      get typeOfObjectValues() {
          const typeOfObject = this.serverResponse.type_of_object
          return this.createValuesForInput(typeOfObject)
      }
      get typeOfRenovationValues() {
          const typeOfRenovation = this.serverResponse.type_of_renovation
          return this.createValuesForInput(typeOfRenovation)
      }
      get simpleChoiceValues() {
          const simpleChoice = this.serverResponse.simple_choice
          return this.createValuesForInput(simpleChoice)
      }
      get productClassValues() {
          const productClass = this.serverResponse.product_class
          return this.createValuesForInput(productClass)
      }
      get criteriasValues() {
          const criteriasValues = this.serverResponse.criterias
          return this.createValuesForInput(criteriasValues)
      }
      get budgetValues() {
          const budgetValues = this.serverResponse.budget
          return this.createValuesForInput(budgetValues)
      }
      get dateValues() {
        const start_date = this.serverResponse.start_date
        return this.createValuesForInput(start_date)
      }
      get stateOfObjectValues() {
          const stateOfObjectValues = this.serverResponse.state_of_object
          return this.createValuesForInput(stateOfObjectValues)
      }
      //METHODS
      async handleDeleteAction(item) {
          this.dialog = true
          this.IdQuotaToDelete = item.id
      }
      async deleteQuota() {
          this.dialog = false
          await dispatchDeleteQuota(this.$store, this.IdQuotaToDelete);
          await dispatchGetQuotas(this.$store, this.$route.params?.id);
      }
      handleTableRowClick(quota) {
          let url = `${this.VHClientQuotaUrl}/quota/${quota.id}/sum?fromAdmin=true`
          let n = window.open(url);
          n?.focus();
      }
      handleCreateQuotaButton() {
          this.$router.push({name: 'main-admin-quota-create', params: {projectId: this.$route.params.id}});
      }
      handleEditQuotaButton(quota) {
          const path = `/main/admin/project/${this.$route.params.id}/quotas/edit/${quota.id}`;
          this.$router.push({path});
      }
      reset() {
          this.address = ''
          this.city = ''
          this.type_of_object = ''
          this.type_of_renovation = ''
          this.need_an_architect = ''
          this.need_an_education = ''
          this.product_class = ''
          this.criterias = []
          this.budget = 0
          this.need_credit = ''
          this.start_date = ''
          this.end_date = ''
          this.state_of_object = ''
          this.simple_choice = ''
          this.square = ''
          this.selectedUser = null
          this.devis_name = ''
          this.selectedProjectManager = ''
          this.email = ''
          this.address_construction =  ''
          this.initial_message =  ''
          this.postal_code =  ''
          this.type_of_extension = ''
          this.type_of_elevation = ''
      }
      fillModel() {
          if (this.project) {
              const criteriasArray =  this.project?.criterias?.replace(/\s+/g, '').split(",")
              this.address = this.project.address
              this.city = this.project.city
              this.type_of_object = this.project.type_of_object
              this.type_of_renovation = this.project.type_of_renovation
              this.product_class = this.project.product_class
              this.criterias = criteriasArray
              this.start_date = this.project.start_date ? `${this.project.start_date}` : null
              this.end_date = this.project.end_date as string
              this.state_of_object = this.project.state_of_object
              this.simple_choice = this.project.simple_choice
              this.square = this.project.square
              this.budget = this.project.budget
              this.need_credit = this.project.need_credit
              this.need_an_education = this.project.need_an_education
              this.need_an_architect = this.project.need_an_architect
              this.devis_name = this.project.devis_name
              this.email = this.project.email
              this.address_construction =  this.project.address_construction
              this.initial_message =  this.project.initial_message
              this.postal_code =  this.project.postal_code
              this.type_of_extension = this.project.type_of_extension
              this.type_of_elevation = this.project.type_of_elevation
              this.selectedProjectManager = this.superUsers.find(user => {
                  const projectManagerId: any = this.project?.manager_id
                  return user.id == projectManagerId
              })
              this.selectedUser = this.users.find(user => {
                  const projectOwnerId: any = this.project?.owner_id
                  return user.id == projectOwnerId
              })
          }
      }
      cancel() {
          this.$router.back();
      }
      createValuesForInput(dict): any {
          if (dict) {
              const valuesList: any = []
              const entries = Object.entries(dict)
              for (const [key, value] of entries) {
                  const valueItem = {text: value, value: key}
                  valuesList.push(valueItem)
              }
              return valuesList
          }
      }
      async dicts() {
          const dicts = await axios.get<any>(`${apiUrl}/api/v1/projects/dicts`, this.authHeaders)
          this.serverResponse = dicts.data
      }

      customFilter (item, queryText) {
        const textOne = item.full_name?.toLowerCase()
        const textTwo = item.email?.toLowerCase()
        const searchText = queryText.toLowerCase()

        return textOne?.indexOf(searchText) > -1 ||
          textTwo?.indexOf(searchText) > -1
      }

      async submit(disabledRedirect) {
          if (await this.$validator.validateAll()) {
              const criteriasList = this.criterias.join(", ")
              const updatedProfile: IProjectProfileUpdate = {
                address: this.address,
                city: this.city,
                type_of_object: this.type_of_object,
                type_of_renovation: this.type_of_renovation,
                need_an_architect: this.need_an_architect,
                need_an_education: this.need_an_education,
                need_credit: this.need_credit,
                product_class: this.product_class,
                criterias: criteriasList,
                budget: this.budget,
                start_date: this.start_date,
                end_date: this.end_date !== "" || this.end_date !== null  ? this.end_date : null,
                state_of_object: this.state_of_object,
                simple_choice: this.simple_choice,
                square: this.square,
                owner_id: this.selectedUser?.id,
                devis_name: this.devis_name,
                manager_id: this.selectedProjectManager?.id,
                email: this.email,
                address_construction: this.address_construction,
                initial_message: this.initial_message,
                postal_code: this.postal_code,
                type_of_extension: this.type_of_extension,
                type_of_elevation: this.type_of_elevation,
              };

              await dispatchUpdateProject(this.$store, { id: this.project!.id, project: updatedProfile });
              if (!disabledRedirect) {
                await this.$router.push('/main/admin/projects/all');
              }
          }
      }
      async mounted(): Promise<void> {
          await dispatchGetProject(this.$store);
          await dispatchGetUsers(this.$store, {queryParams: '?limit=3500'});
          await dispatchGetSuperUsers(this.$store, {queryParams: '?limit=3500&super_user=true'});
          await dispatchGetQuotas(this.$store, this.$route.params?.id);
          await dispatchGetQuotasStatuses(this.$store)
          await this.dicts();
          this.fillModel();
      }
  }
