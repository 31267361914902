













































































/* eslint-disable */
import { Vue, Component, Watch } from 'vue-property-decorator'
import uniqBy from 'lodash.uniqby';
import draggable from "vuedraggable";
import { readRooms } from '@/store/admin/getters';
import {dispatchGetRooms, dispatchDeleteRoom, dispatchUpdateRoomPosition, dispatchUpdateRoom} from '@/store/admin/actions';
import { readToken } from "@/store/main/getters";
import DataTableRowHandler from "@/components/ui/DataTableRowHandler.vue" 
import { IPositionDto, IRoomProfile } from '@/interfaces';

@Component({
  components: {
    draggable,
    DataTableRowHandler,
    AlertDialog: () => import("@/components/ui/AlertDialog.vue"),
    ConfirmDialog: () => import("@/components/ui/ConfirmDialog.vue")
  }
})
export default class Rooms extends Vue {
  updatePositionLoader: boolean = false
  defaultRoom: number | null = null
  rerenderList = 1
  dialog = false
  alertDialog = false
  currentItem = null

    //DATA
    headers = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: this.$t('roomName'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('associatedSubCategories'),
            sortable: true,
            value: 'categories',
            align: 'left',
        },
        {
            text: this.$t('default'),
            sortable: false,
            value: 'default',
            align: 'center',
        },
        {
          text: '',
          sortable: false,
          value: 'button-drag',
          align: 'left',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        },
    ];
    list: IRoomProfile[] = []
    //COMPUTED
    get token() {
        return readToken(this.$store)
    }
    get rooms(): IRoomProfile[] {
        let rooms = readRooms(this.$store);
        let roomList: any = []
        rooms.forEach(room => {
            let updatedRoom: any = JSON.parse(JSON.stringify(room))
            updatedRoom.categories = uniqBy(updatedRoom.categories, 'category_id').map(category => {
              let cat: any = category
              return cat.name
            }).join(', ')
            roomList.push(updatedRoom)
        })
        return roomList
    }
    @Watch('rooms', { immediate: true })
    public async onRoomsChange(
        newVal
    ) {
      this.list = [...newVal]
    }
    //METHODS
    handleEditButton(value) {
        this.$router.push({name: 'main-admin-room-edit', params: {id: value.id}})
    }
    handleDeleteButton(item) {
      this.currentItem = item
      if (item.default) {
        this.alertDialog = true;
        return false;
      }
      this.dialog = true
    }
    async deleteRoom(item) {
      if (item) {
        await dispatchDeleteRoom(this.$store, item.id);
      }
    }
    onDropCallback() {
      this.updatePosition()
    }

    async updatePosition() {
      let list: IPositionDto[] = this.list.map((room, index) => {
        return {
          id: room.id,
          position: index
        }
      })

      this.updatePositionLoader = true
      await dispatchUpdateRoomPosition(this.$store, list) 
      this.rerenderList++
      this.updatePositionLoader = false
    }
    async updateDefaultRoom(payload) {
      this.updatePositionLoader = true
      const formData = new FormData()
      formData.append('default', `${true}`)
      await dispatchUpdateRoom(this.$store, {id: payload.id, formData})
      this.updatePositionLoader = false
      this.rerenderList++
    }
    //HOOKS
    async mounted(): Promise<void> {
        await dispatchGetRooms(this.$store);
        this.defaultRoom = this.rooms.find(r => r.default)?.id || null
    }
}
