/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router';
import RouterComponent from '@/components/RouterComponent.vue';

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      component: () => import(/* webpackChunkName: "start" */ '@/views/main/Start.vue'),
      children: [
        {
          path: 'login',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
        },
        // {
        //   path: 'recover-password',
        //   component: () => import(/* webpackChunkName: "recover-password" */ './views/PasswordRecovery.vue'),
        // },
        // {
        //   path: 'reset-password',
        //   component: () => import(/* webpackChunkName: "reset-password" */ './views/ResetPassword.vue'),
        // },
        {
          path: 'main',
          component: () => import(/* webpackChunkName: "main" */ '@/views/main/Main.vue'),
          children: [
            {
              path: 'dashboard',
              component: () => import(/* webpackChunkName: "main-dashboard" */ '@/views/main/Dashboard.vue'),
            },
            {
              path: 'settings',
              name: 'settingsPage',
              component: () => import(/* webpackChunkName: "main-dashboard" */ '@/views/main/admin/Settings/Settings.vue'),
            },
            {
              path: 'profile',
              component: RouterComponent,
              redirect: 'profile/view',
              children: [
                {
                  path: 'view',
                  component: () => import(
                      /* webpackChunkName: "main-profile" */ '@/views/main/profile/UserProfile.vue'),
                },
                {
                  path: 'edit',
                  component: () => import(
                      /* webpackChunkName: "main-profile-edit" */ '@/views/main/profile/UserProfileEdit.vue'),
                },
                {
                  path: 'password',
                  component: () => import(
                      /* webpackChunkName: "main-profile-password" */ '@/views/main/profile/UserProfileEditPassword.vue'),
                },
              ],
            },
            {
              path: 'admin',
              component: () => import(/* webpackChunkName: "main-admin" */ '@/views/main/admin/Admin.vue'),
              redirect: 'admin/users/all',
              children: [
                {
                  path: 'users',
                  redirect: 'users/all',
                },
                {
                  path: 'users/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users" */ '@/views/main/admin/Users/AdminUsers.vue'),
                },
                {
                  path: 'users/edit/:id',
                  name: 'main-admin-users-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users-edit" */ '@/views/main/admin/Users/EditUser.vue'),
                },
                {
                  path: 'users/create',
                  name: 'main-admin-users-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/Users/CreateUser.vue'),
                },
                  //PROJECT
                {
                  path: 'projects/view/:id',
                  name: 'main-admin-project-view',
                  component: () => import(
                      /* webpackChunkName: "main-admin-project-view" */ '@/views/main/admin/Projects/ViewProject.vue'),
                },
                {
                  path: 'projects/edit/:id',
                  name: 'main-admin-project-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-project-edit" */ '@/views/main/admin/Projects/EditProject.vue'),
                },
                {
                  path: 'projects/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-projects" */ '@/views/main/admin/Projects/Projects.vue'),
                },
                {
                  path: 'projects/create',
                  name: 'main-admin-projects-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/Projects/CreateProjects.vue'),
                },
                //ROOM
                {
                  path: 'rooms/edit/:id',
                  name: 'main-admin-room-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-room-edit" */ '@/views/main/admin/Rooms/EditRoom.vue'),
                },
                {
                  path: 'rooms/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-rooms" */ '@/views/main/admin/Rooms/Rooms.vue'),
                },
                {
                  path: 'rooms/create',
                  name: 'main-admin-room-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-room-create" */ '@/views/main/admin/Rooms/CreateRoom.vue'),
                },
                //CATEGORIES
                {
                  path: 'categories/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-projects" */ '@/views/main/admin/Categories/Categories.vue'),
                },
                {
                  path: 'categories/create',
                  name: 'main-admin-category-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/Categories/CreateCategory.vue'),
                },
                {
                  path: 'categories/edit/:id',
                  name: 'main-admin-category-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quota-edit" */ '@/views/main/admin/Categories/EditCategory.vue'),
                },
                {
                  path: 'type-of-work/all',
                  name: 'type-of-work',
                  component: () => import(
                      /* webpackChunkName: "main-admin-projects" */ '@/views/main/admin/Categories/TypeOfWork.vue'),
                },
                {
                  path: 'type-of-work/create',
                  name: 'main-admin-type-of-work-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-users-create" */ '@/views/main/admin/Categories/CreateTypeOfWork.vue'),
                },
                {
                  path: 'type-of-work/edit/:id',
                  name: 'main-admin-type-of-work-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quota-edit" */ '@/views/main/admin/Categories/EditTypeOfWork.vue'),
                },
                //QUOTAS
                {
                  path: 'quotas/view/:id',
                  name: 'main-admin-quotas-view',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quotas-view" */ '@/views/main/admin/Quotas/ViewQuota.vue'),
                },
                {
                  path: 'project/:project_id/quotas/edit/:id',
                  name: 'main-admin-quota-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quota-edit" */ '@/views/main/admin/Quotas/EditQuota.vue'),
                },
                {
                  path: 'quotas/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quotas" */ '@/views/main/admin/Quotas/Quotas.vue'),
                },
                {
                  path: 'quotas/create',
                  name: 'main-admin-quota-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quota-create" */ '@/views/main/admin/Quotas/CreateQuota.vue'),
                },
                {
                  path: 'quotas/list',
                  component: () => import(
                      /* webpackChunkName: "main-admin-quotas-list-page" */ '@/views/main/admin/QuotasPage/QuotasPage.vue'),
                },
                //PRODUCTS
                {
                  path: 'products/all',
                  component: () => import(
                      /* webpackChunkName: "main-admin-products" */ '@/views/main/admin/Products/Products.vue'),
                },
                {
                  path: 'products/create',
                  name: 'main-admin-product-create',
                  component: () => import(
                      /* webpackChunkName: "main-admin-product-create" */ '@/views/main/admin/Products/CreateProduct.vue'),
                },
                {
                  path: 'products/edit/:id',
                  name: 'main-admin-product-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-product-edit" */ '@/views/main/admin/Products/EditProduct.vue'),
                },
                //SERVICES
                {
                  path: 'services/',
                  component: () => import(
                      /* webpackChunkName: "main-admin-services" */ '@/views/main/admin/Services/Services.vue'),
                  children: [
                    {
                      path: '',
                      name: 'services-all',
                      component: () => import(
                          /* webpackChunkName: "main-admin-services" */ '@/views/main/admin/Services/ServiceList.vue'),
                    },
                    {
                      path: 'create-service',
                      name: 'basic-services-settings',
                      component: () => import(
                          /* webpackChunkName: "main-admin-services" */ '@/views/main/admin/Services/ServicesBasicSettings.vue'),
                    },
                    {
                      path: 'service/:id',
                      component: () => import(
                          /* webpackChunkName: "main-admin-service-edit" */ '@/views/main/admin/Services/Service.vue'),
                      children: [
                        {
                          path: '',
                          name: 'service-edit',
                          component: () => import(
                              /* webpackChunkName: "combination-services-settings" */ '@/views/main/admin/Services/EditService.vue'),
                        },{
                          path: 'combination',
                          name: 'combination-services-settings',
                          component: () => import(
                              /* webpackChunkName: "combination-services-settings" */ '@/views/main/admin/Services/ServicesCombination.vue'),
                        },
                        {
                          path: 'related-products',
                          name: 'related-products-services-settings',
                          component: () => import(
                              /* webpackChunkName: "related-products-services-settings" */ '@/views/main/admin/Services/ServicesRelatedProduct.vue'),
                        }
                      ]
                    },
                  ]
                },
                // {
                //   path: 'services/view/:id',
                //   name: 'main-admin-services-view',
                //   component: () => import(
                //       /* webpackChunkName: "main-admin-services-view" */ '@/views/main/admin/Quotas/ViewQuota.vue'),
                // },
                {
                  path: 'services/edit/:id',
                  name: 'main-admin-service-edit',
                  component: () => import(
                      /* webpackChunkName: "main-admin-service-edit" */ '@/views/main/admin/Services/EditService.vue'),
                }
              ],
            },
          ],
        },
      ],
    },
    {
      path: '/*', redirect: '/',
    },
  ]
});