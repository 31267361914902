


















/* eslint-disable */
import { Component, Mixins } from 'vue-property-decorator'
import { Categories as CategoriesMixin } from './mixins'
import { readTypeOfWorks, readCategories } from '@/store/categories/getters';
import TableExpandableRow from "@/components/ui/TableExpandableRow.vue";
import CategoryService from './CategoryServices/CategoryServices.vue'

@Component({
    components: {
        TableExpandableRow,
        CategoryService
    },
})
export default class Categories extends Mixins(CategoriesMixin) {
    headers = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
            width: '4%' 
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
            width: '20%'
        },
        {
            text: this.$t('description'),
            sortable: true,
            value: 'description',
            align: 'left',
            width: '61%'
        },
        {
            text: '',
            value: 'button-popup',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'data-table-expand'
        },
    ];

    get categories() {
        return readTypeOfWorks(this.$store);
    }

    handleEditButton(value) {
        if (!value.parent_id) {
            this.$router.push({ name: 'main-admin-type-of-work-edit', params: { id: value.id } })
            return false;
        }
        this.$router.push({name: 'main-admin-category-edit', params: {id: value.id}, query: { type: value.parent_id }})
    }
}
