










































































































































































/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import {
    dispatchGetSettings,
    dispatchUpdateSettings,
    dispatchSaveAccessToken,
    dispatchGetUnits,
    dispatchCreateUnit,
    dispatchDeleteUnit,
    dispatchUpdateUnit,
    dispatchGetDefaultPdfCertifications,
    dispatchGetDefaultPdfAssurance,
    dispatchGetEmailSettings,
    dispatchGetPdfContent,
} from '@/store/admin/actions';
import { getSettings } from '@/store/admin/getters';

@Component({
  components: {
    GeneralSettings: () => import("./General.vue"),
    EmailSettings: () => import("./Email.vue"),
    PdfSettings: () => import("./Pdf.vue"),
  }
})
export default class Settings extends Vue {
  public client_id = '';
  public client_secret = '';
  public access_token = '';
  public refresh_token = '';

  //UNITS OF MEASURES DATA
    headers: Array<object> = [
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('shortName'),
            sortable: true,
            value: 'short_name',
            align: 'left',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        }
    ];
    valid: boolean = false;
    dialog: boolean = false;
    editButton: boolean = false;
    callback_email: string = '';

    editUnit = {
        name: '',
        short_name: '',
        id: ''
    }

    routeToBack = ''
  //COMPUTED
    get units() {
        return this.$store.state.admin.units
    }
    get me() {
        return this.$store.state.main.userProfile
    }

  //METHODS
    handleCreateUnit() {
        this.editUnit.name = '';
        this.editUnit.short_name = '';
        this.editButton = false
    }
    async handleEditButton(value) {
      this.editUnit.name = value.name;
      this.editUnit.short_name = value.short_name;
      this.editUnit.id = typeof value.id === 'string' ? value.id.replace('/') : value.id;
      this.editButton = true
      this.dialog = true
    }
    async deleteEvent(value) {
        await dispatchDeleteUnit(this.$store, value.id)
        await dispatchGetUnits(this.$store)
    }
    async submitUnitForm(type, hasRoute) {
        if(type === 'create') {
            await dispatchCreateUnit(this.$store, this.editUnit)
            await dispatchGetUnits(this.$store)
            this.dialog = false
        }
        if(type === 'update') {
            await dispatchUpdateUnit(this.$store, {id: this.editUnit.id, data: this.editUnit})
            await dispatchGetUnits(this.$store)
            this.dialog = false
            if (this.routeToBack) {
                await this.$router.push(this.routeToBack)
            }
        }

    }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public async mounted():Promise<void> {
    await dispatchGetSettings(this.$store);
    await dispatchGetEmailSettings(this.$store);
    await dispatchGetUnits(this.$store);
    await dispatchGetPdfContent(this.$store);
    await dispatchGetDefaultPdfAssurance(this.$store);
    await dispatchGetDefaultPdfCertifications(this.$store);
    let settings = getSettings(this.$store);
    this.client_id = settings?.client_id;
    this.callback_email = settings?.emails ? settings?.emails : this.me?.email;
    this.client_secret = settings?.client_secret;
    this.access_token = settings?.access_token;
    this.refresh_token = settings?.refresh_token;

    if (this.$route.query.unit) {      
      const urlParams = new URLSearchParams(this.$route.query.unit as string);
      const params = Object.fromEntries(urlParams);
      this.handleEditButton(params)
    }

    if (this.$route.query.code) {
      let result = await dispatchSaveAccessToken(this.$store, {'code': this.$route.query.code})
      this.access_token = result?.access_token
      this.refresh_token = result?.refresh_token
      await this.$router.push('/main/settings');
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public connect():void {
    window.location.href = "https://accounts.zoho.com/oauth/v2/auth?scope=ZohoCRM.modules.ALL&"+
               "client_id="+this.client_id+"&response_type=code&access_type=offline&"+
               "redirect_uri="+document.location.origin + document.location.pathname
  }

  public cancel():void {
    this.$router.back();
  }

  public async submit(type):Promise<void> {
        let dataToSend: any = {};
        if (type === 'emails') {
            dataToSend.emails = this.callback_email;
        }
        if (type === 'zohoParams') {
            dataToSend.client_id = this.client_id;
            dataToSend.client_secret = this.client_secret;
        }

      if (await this.$validator.validateAll()) {
        await dispatchUpdateSettings(this.$store, dataToSend);
      }
  }

}
