











































/* eslint-disable */
import { Component, Mixins } from 'vue-property-decorator'
import { Categories as CategoriesMixin } from './mixins'
import TableExpandableRow from "@/components/ui/TableExpandableRow.vue";
import CategoryService from './CategoryServices/CategoryServices.vue'
import { readCategories, readOptionsTypeOfWorks } from '@/store/categories/getters';
import { commitSetFilterByTypeOfWorks } from '@/store/categories/mutations';

@Component({
    components: {
        TableExpandableRow,
        CategoryService
    },
})
export default class Categories extends Mixins(CategoriesMixin) {
    headers = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
            width: '4%' 
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
            width: '21%'
        },
        {
            text: this.$t('typeOfWorks'),
            sortable: false,
            value: 'typeName',
            align: 'left',
            width: '20%'
        },
        {
            text: this.$t('description'),
            sortable: true,
            value: 'description',
            align: 'left',
            width: '40%'
        },
        {
            text: '',
            value: 'button-popup',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'data-table-expand'
        },
    ];

    get categories() {
        return readCategories(this.$store);
    }

    get currentFilter() {
        return this.$store.state.category.filterByTypeOfWorks;
    }

    get options() {
        return readOptionsTypeOfWorks(this.$store);
    }

    handleEditButton(value) {
        this.$router.push({name: 'main-admin-category-edit', params: {id: value.id}, query: { type: value.typeId }})
    }

    changeFilter(payload) {
        commitSetFilterByTypeOfWorks(this.$store, payload)
    }
}
