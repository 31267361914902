/* eslint-disable */
import {IUserProfile, IProjectProfile, IRoomProfile, ICategoryProfile, ISettings, IMessage, IPlatformParameters, IEmailSettingsUpdateDto, IEmailSettings, IPdfContent} from '@/interfaces';
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';
import { setCompanyName, setFavicon } from '@/utils/storage';

export const mutations = {
    setUsers(state: AdminState, payload: IUserProfile[]) {
        state.users = payload;
    },
    setSuperUsers(state: AdminState, payload: IUserProfile[]) {
        state.superUsers = payload;
    },
    setUser(state: AdminState, payload: IUserProfile) {
        const users = state.users.filter((user: IUserProfile) => user.id !== payload.id);
        users.push(payload);
        state.users = users;
    },
    clearUserList(state: AdminState) {
        state.users = [];
    },

// projects mutations
    setProjects(state: AdminState, payload: IProjectProfile[]) {
        state.projects = payload;
    },
    setProject(state: AdminState, payload: IProjectProfile) {
        const projects = state.projects.filter((project: IProjectProfile) => project.id !== payload.id);
        projects.push(payload);
        state.projects = projects;
    },
// rooms mutations
    setRooms(state: AdminState, payload: IRoomProfile[]) {
        state.rooms = payload;
    },
    setAdminOneRoom(state: AdminState, payload: IRoomProfile) {
        state.adminOneRoom = payload
    },
    setRoom(state: AdminState, payload: IRoomProfile) {
        const rooms = state.rooms.filter((room: IRoomProfile) => room.id !== payload.id);
        rooms.push(payload);
        state.rooms = rooms;
    },
    setRoomsCategories(state: AdminState, payload: any) {
        state.roomsCategories = payload;
    }
    ,setRoomsCategory(state: AdminState, payload: any) {
        state.roomsCategory = payload;
    },
// categories mutations
    // setCategory(state: AdminState, payload: ICategoryProfile) {
    //     const categories = state.categories.filter((category: ICategoryProfile) => category.id !== payload.id);
    //     categories.push(payload);
    //     state.categories = categories;
    // },
    // setCategories(state: AdminState, payload: any) {
    //     state.categories = payload;
    // },
    // setCategoriesTree(state: AdminState, payload: any) {
    //     state.categoriesTree = payload;
    // },
    // setParentCategoriesTree(state: AdminState, payload: any) {
    //     state.parentCategoriesTree = payload;
    // },
    // quotas mutations
    setQuota(state: AdminState, payload ) {
        const quotas = state.rooms.filter((quota) => quota.id !== payload.id);
        quotas.push(payload);
        state.rooms = quotas;
    },
    setQuotas(state: AdminState, payload ) {
        state.quotas = payload;
    },
    setAllQuotas(state: AdminState, payload ) {
        state.allQuotas = payload;
    },
    setQuotasStatuses(state: AdminState, payload ) {
        state.quotasStatuses = payload;
    },
    updateQuota(state: AdminState, payload) {
        let quotaIndex = state.quotas.findIndex(quota => quota.id === payload.id)
        console.log(quotaIndex)
        if (quotaIndex <= 0) {
            state.quotas[quotaIndex] = payload
            state.quotas = [...state.quotas]
        }
    },

    // rooms settings
    setSettings(state: AdminState, payload: ISettings[]) {
        state.settings = payload;
    },
    //products mutations
    setProduct(state: AdminState, payload) {
        const products = state.products.filter((product => product.id !== payload.id));
        products.push(payload);
        state.products = products;
    },
    setProducts(state: AdminState, payload: any) {
        state.products = payload;
    },
    // combinations mutations
    setServiceCombination(state: AdminState, payload) {
        const combinations = state.serviceCombinations.filter((combination) => combination.id !== payload.id);
        combinations.push(payload);
        state.serviceCombinations = combinations;
    },
    setServiceCombinationProducts(state: AdminState, payload) {
        const combinationProducts = state.serviceCombinations.filter((combination) => combination.id !== payload.id);
        combinationProducts.push(payload);
        state.serviceCombinationProducts = combinationProducts;
    },
    setServiceCombinations(state: AdminState, payload: any) {
        state.serviceCombinations = payload;
    },
    //services
    saveServiceName(state: AdminState, payload) {
        const combinations = state.serviceCombinations.filter((combination) => combination.id !== payload.id);
        combinations.push(payload);
        state.serviceCombinations = combinations;
    },
    setService(state: AdminState, payload) {
        const service = state.services.filter((service) => service.id !== payload.id);
        service.push(payload);
        state.services = service;
    },
    setServices(state: AdminState, payload: any) {
        state.services = payload;
    },
    setServiceById(state: AdminState, payload: any) {
        state.serviceById = payload;
    },
    setServiceCategories(state: AdminState, payload: any) {
        state.serviceCategories = payload;
    },
    // setPreselectedCategoriesTree(state: AdminState, payload: any) {
    //     state.preselectedCategoriesTree = payload;
    // },
    setServiceCategory(state: AdminState, payload: any) {
        state.serviceCategory = payload;
    },
    setServicesByCatId(state: AdminState, payload) {
        state.serviceByCatId = payload
    },
 //related products mutations
    setRelatedProduct(state: AdminState, payload) {
        const products = state.products.filter((product => product.id !== payload.id));
        products.push(payload);
        state.relatedProducts = products;
    },
    setServiceProduct(state: AdminState, payload) {
        const serviceProducts = state.serviceProducts.filter((serviceProducts => serviceProducts.id !== payload.id));
        serviceProducts.push(payload);
        state.serviceProducts = serviceProducts;
    },
    commitSetServiceProducts(state: AdminState, payload: any) {
        state.serviceProducts = payload;
    },
    commitSetRelatedServiceProducts(state: AdminState, payload: any) {
        state.relatedServiceProducts = payload;
    },
    commitSetServiceProductPrices(state: AdminState, payload: any) {
        state.serviceProductPrices = payload.reverse();
    },
    // units mutations
    setUnits(state: AdminState, payload) {
        state.units = payload;
    },
    setUnit(state: AdminState, payload) {
        state.unit = payload;
    },
    //HISTORIES
    setProjectHistory(state: AdminState, payload) {
        state.projectHistories = payload;
    },
    // MESSAGE
    setMessage(state: AdminState, payload) {
        state.messages = payload;
    },
    // PDF SETTINGS
    setPdfQuotaDefaultLegalsInforamation(state: AdminState, payload: {id: number, description: string}) {
        const {id: legalInformationId, description: legalInformation} = payload;
        state.quotaPdfSettings = Object.assign({...state.quotaPdfSettings}, {legalInformation, legalInformationId});
    },
    setPdfQuotaDefaultAssurance(state: AdminState, payload: File[]) {
        state.quotaPdfSettings = Object.assign({...state.quotaPdfSettings}, {assuranceDefaultPDF: payload});
    },
    setPdfQuotaDefaultCertifications(state: AdminState, payload: File[]) {
        state.quotaPdfSettings = Object.assign({...state.quotaPdfSettings}, {certificationsDefaultPDF: payload});
    },
    setPdfQuotaCertifications(state: AdminState, payload: File[]) {
        state.quotasPdfFiles.certificationsOwnPDF = payload
    },
    setPdfQuotaAssurance(state: AdminState, payload: File[]) {
        state.quotasPdfFiles.assuranceOwnPDF = payload
    },
    // Platform Parameters
    setPlatformParameters(state: AdminState, payload: IPlatformParameters) {
        if (payload && payload.name) {
            state.platformParameters = payload
            setCompanyName(payload.name)
            setFavicon(payload.favicon)
        }
    },
    // Email Settings
    setEmailSettings(state: AdminState, payload: IEmailSettings) {
        if (payload && payload.id) {
            state.emailSettings = payload
        }
    },
    // Pdf logo
    setPdfContent(state: AdminState, payload: IPdfContent) {
        if (payload && payload.id) {
            const { conditions, footer_logo, header_logo, legal_info, main_logo, team } = payload
            state.pdfContent = {
                id: payload.id,
                conditions: conditions ?? "",
                footer_logo: footer_logo ?? "/images/logo-placeholder.jpg",
                header_logo: header_logo ?? "/images/logo-placeholder.jpg",
                legal_info: legal_info ?? "",
                main_logo: main_logo ?? "/images/logo-placeholder.jpg",
                team: team ?? ""
            }
        }
    },
};

const { commit } = getStoreAccessors<AdminState, State>('');
//USERS
export const commitSetUser = commit(mutations.setUser);
export const commitSetUsers = commit(mutations.setUsers)
export const commitSetSuperUsers = commit(mutations.setSuperUsers)
export const commitClearUserList = commit(mutations.clearUserList)
//PROJECTS
export const commitSetProject = commit(mutations.setProject);
export const commitSetProjects = commit(mutations.setProjects);
//ROOMS
export const commitSetRoom = commit(mutations.setRoom);
export const commitSetRooms = commit(mutations.setRooms);
export const commitSetAdminOneRoom = commit(mutations.setAdminOneRoom);
//CATEGORIES
// export const commitSetCategory= commit(mutations.setCategory);
// export const commitSetCategories = commit(mutations.setCategories);
// export const commitSetCategoriesTree = commit(mutations.setCategoriesTree);
// export const commitSetParentCategoriesTree = commit(mutations.setParentCategoriesTree);
//ROOMS
export const commitSetQuota = commit(mutations.setQuota);
export const commitSetQuotas = commit(mutations.setQuotas);
export const commitSetAllQuotas = commit(mutations.setAllQuotas);
export const commitSetQuotasStatuses = commit(mutations.setQuotasStatuses);
export const commitSetRoomsCategories = commit(mutations.setRoomsCategories);
export const commitSetRoomsCategory = commit(mutations.setRoomsCategory);

// Quota
export const commitUpdateQuota = commit(mutations.updateQuota);

export const commitSetSettings = commit(mutations.setSettings);
//PRODUCTS
export const commitSetProduct= commit(mutations.setProduct);
export const commitSetProducts = commit(mutations.setProducts);
export const commitSetServiceProduct= commit(mutations.setServiceProduct);

//CATEGORIES
export const commitSetServiceCombination = commit(mutations.setServiceCombination);
export const commitSetServiceCombinationProducts = commit(mutations.setServiceCombinationProducts);
export const commitSetServiceCombinations = commit(mutations.setServiceCombinations);
// export const commitSetPreselectedCategoriesTree = commit(mutations.setPreselectedCategoriesTree);

//SERVICES
export const commitSetService = commit(mutations.setService);
export const commitSetServiceById = commit(mutations.setServiceById);
export const commitSetServiceCategories = commit(mutations.setServiceCategories);
export const commitSetServiceCategory = commit(mutations.setServiceCategory);
export const commitSetServices = commit(mutations.setServices);
export const commitSetServicesByCatId = commit(mutations.setServicesByCatId);
//RELATED PRODUCTS
export const commitSetRelatedProduct= commit(mutations.setRelatedProduct);
export const commitSetServiceProducts = commit(mutations.commitSetServiceProducts);
export const commitSetRelatedServiceProduct = commit(mutations.commitSetRelatedServiceProducts);
export const commitSetServiceProductPrices = commit(mutations.commitSetServiceProductPrices);
//UNITS
export const commitSetUnits = commit(mutations.setUnits);
export const commitSetUnit = commit(mutations.setUnit);
//HISTORIES
export const commitSetProjectHistory = commit(mutations.setProjectHistory);
// MESSAGE
export const commitSetMessages = commit(mutations.setMessage);
// PDF SETTINGS
export const commitSetPdfQuotaDefaultLegalsInforamation = commit(mutations.setPdfQuotaDefaultLegalsInforamation);
export const commitSetPdfQuotaDefaultAssurance = commit(mutations.setPdfQuotaDefaultAssurance);
export const commitSetPdfQuotaDefaultCertifications = commit(mutations.setPdfQuotaDefaultCertifications);
export const commitSetPdfQuotaCertifications = commit(mutations.setPdfQuotaCertifications);
export const commitSetPdfQuotaAssurance = commit(mutations.setPdfQuotaAssurance);
// Platform Parameters
export const commitSetPlatformParameters = commit(mutations.setPlatformParameters);
// Email settings
export const commitSetEmailSettings = commit(mutations.setEmailSettings);
// Pdf logo
export const commitSetPdfContent = commit(mutations.setPdfContent);