






















/* eslint-disable */
import { Component, Vue } from 'vue-property-decorator';
import NotificationsManager from '@/components/NotificationsManager.vue';
import { readIsLoggedIn } from '@/store/main/getters';
import { dispatchCheckLoggedIn } from '@/store/main/actions';
import { dispatchGetPlatformParameters } from './store/admin/actions';

@Component({
    components: {
        NotificationsManager,
    },
})
export default class App extends Vue {
    get loggedIn() {
        return readIsLoggedIn(this.$store);
    }
    public async created() {
        await dispatchCheckLoggedIn(this.$store);
        await dispatchGetPlatformParameters(this.$store)
    }
}
