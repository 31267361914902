




































/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { dispatchGetServices, dispatchDeleteService } from '@/store/admin/actions';

@Component({
    components: {
        ConfirmDialog: () => import("@/components/ui/ConfirmDialog.vue")
    }
})
export default class ServiceList extends Vue {
    dialog = false
    currentItem = null

    //DATA
    headers: Array<object> = [
        {
            text: '',
            sortable: false,
            align: 'center',
        },
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('shortDescription'),
            sortable: true,
            value: 'short_description',
            align: 'left',
        },
        {
            text: this.$t('price'),
            sortable: true,
            value: 'default_price',
            align: 'left',
        },
        {
            text: this.$t('tax'),
            sortable: true,
            value: 'tax',
            align: 'left',
        },
        {
            text: this.$t('reference'),
            sortable: true,
            value: 'reference',
            align: 'left',
        },
        {
            text: this.$t('additionalUnits'),
            sortable: true,
            value: 'additional_units',
            align: 'left',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        }
    ];

    //COMPUTED
    get services() {
        return this.$store.state.admin.services.sort((prev, next) => prev.id - next.id);
    }

    //METHODS
    handleEditButton(value) {
        this.$router.push({name: 'service-edit', params: {id: value.id}})
    }
    async deleteEvent(value) {
        if (value) {
            await dispatchDeleteService(this.$store, value.id);
            await dispatchGetServices(this.$store);
        }
    }
    editEvent(value) {
        this.$router.push({name: 'main-admin-service-edit', params: {id: value.id}})
    }
    //HOOKS
    async mounted(): Promise<void> {
      await dispatchGetServices(this.$store)
    }
}
