export const priceFormat = (price: any): string => {
    return (price || 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ' ').replace('.', ',')
};

export const itemPrice = (item: any) => {
    let priceUnit = item?.price || 0;
    if (priceUnit !== 0 && itemAmount(item) !== 0) {
        priceUnit = item?.price / itemAmount(item);
    }
    return priceUnit;
}

export const itemAmount = (item: any): number => {
    const amount = item?.amount || 0
    let additionalUnits = item?.service?.additional_units || 0
    if (item.type === "product" || item.type === "custom-product") {
        additionalUnits = 0
    }
    return additionalUnits !== 0 ? amount * (additionalUnits !== 0 ? additionalUnits / 100 : 1) + amount : amount
}

export const getName = (item: any): string => {
    let name = `${item?.service?.name ? item?.service?.name : "-"}`
    if (item.type === "service") {

        name = `
            <div>${item?.service?.name}</div>
            <div class="fs-i fw-500">Type: ${item?.service_combination?.name ?? 'Standard'}</div>
            <div class="fs-i fw-500">Référence: ${item?.service?.reference ?? ''}</div>
            <div class="fs-i fw-500" style="display: ${item?.service?.description ? 'block' : 'none'}">${item?.service?.description ?? ''}</div>
          `
    }
    if (item.type === "custom-service") {
        name = `
            <div>${item?.service?.name}</div>
            <div class="fs-i fw-500" style="display: ${item?.service?.description ? 'block' : 'none'}">${item?.service?.description ?? ''}</div>
          `
    }
    if (item.type === "product") {
        name = `
            <div>${item?.product?.name}</div>
            <div class="fs-i fw-500" style="display: ${item?.product?.service_combination ? 'block' : 'none'}">Type: ${item?.product?.service_combination?.name ?? ''}</div>
            <div class="fs-i fw-500">Référence: ${item?.product?.reference ?? ''}</div>
            <div class="fs-i fw-500">Description: ${item?.product?.description ?? ''}</div>
          ` || "-"
    }
    if (item.type === "custom-product") {
        name = `
            <div>Fourniture choisie par le client</div>
            <div class="fs-i fw-500">
              Nom du fournisseur: ${item.custom_product.name ?? ''}<br> 
              Marque: ${item.custom_product.mark ?? ''}<br>
              Référence Produit: ${item.custom_product.reference ?? ''}<br>
              Prix du produit: ${item.custom_product.client_price ?? priceFormat(0)} €<br>
              Vos commentaires: ${item.custom_product.description ?? ''}
            </div>
          `
    }
    return name
}