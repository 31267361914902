

































import {Component, Vue} from 'vue-property-decorator';
import {readLoginError} from '@/store/main/getters';
import {dispatchLogIn} from '@/store/main/actions';
import { getCompanyName } from '@/utils/storage';

@Component
export default class Login extends Vue {
  public email = '';
  public password = '';
  public title = this.$store.state.admin.platformParameters.name

  public get loginError() {
    return readLoginError(this.$store);
  }

  public submit() {
    dispatchLogIn(this.$store, {username: this.email, password: this.password});
  }
}
