























/* eslint-disable */
import Vue from 'vue'
import {Prop} from 'vue-property-decorator'
import {dispatchGetQuotas} from '@/store/admin/actions';
import {readQuotas} from "@/store/admin/getters";

export default class Quotas extends Vue {
    //PROPS
    @Prop() readonly projectId : any;
    //DATA
    headers = [
        {
            text: this.$t('name'),
            sortable: true,
            value: 'quota_name',
            align: 'left',
        },
        {
            text: this.$t('status'),
            sortable: true,
            value: 'status',
            align: 'left',
        }
    ]
    //COMPUTED
    get quotas() {
        return readQuotas(this.$store)
    }
    //METHODS
    handleEditButton(value) {
        this.$router.push({name: 'main-admin-room-edit', params: {id: value.id}})
    }
    handleMenuButton(item) {

    }
    async mounted(): Promise<void> {
        await dispatchGetQuotas(this.$store, this.projectId);
    }
}

