


























































































/* eslint-disable */
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { RoomCategories } from './mixins'
import { readRooms} from '@/store/admin/getters';
import {
    dispatchCreateRoom,
    dispatchGetRooms,
    dispatchUpdateRoomPosition,
} from '@/store/admin/actions';
import { dispatchGetCategoriesTree } from '@/store/categories/actions';
import { IPositionDto } from '@/interfaces';

@Component
export default class CreateRooms extends Mixins(RoomCategories) {

    serverResponse =  {};

    get rooms() {
        return readRooms(this.$store)
    }

    async submit(disabledRedirect) {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {

            const formData = new FormData()

            formData.append('name', this.name);
            formData.append('image', this.image);
            formData.append('default', `${this.defaultRoom || false}`)

            const room: any = await dispatchCreateRoom(this.$store, formData).then( async data => {
                this.updateRoomCategories(data.id)
                this.updatePosition()
                return data
            });
            if (!disabledRedirect) {
              await this.$router.push('/main/admin/rooms/all');
            }
            if (room.id && disabledRedirect) {
              await this.$router.replace(`/main/admin/rooms/edit/${room.id}`);
            }
        }
    }

    async updatePosition() {
      let list: IPositionDto[] = this.rooms.map((room, index) => {
        return {
          id: room.id,
          position: ++index
        }
      })

      await dispatchUpdateRoomPosition(this.$store, list)
    }

    async mounted(): Promise<void> {
        await dispatchGetRooms(this.$store);
        await dispatchGetCategoriesTree(this.$store)
        this.reset();
    }
}
