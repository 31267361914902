import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import { messages, defaultLocale } from "@/i18n/index";

Vue.use(VueI18n);


export const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale
});

export function setI18nLanguage(vueInstance: any, lang= defaultLocale): string {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  (document.querySelector("html") as unknown as HTMLInputElement).setAttribute("lang", lang);
  vueInstance.$vuetify.lang.current = lang
  return lang;
}