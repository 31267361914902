

















































































































    /* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';

import { appName } from '@/env';
import { readDashboardMiniDrawer, readDashboardShowDrawer, readHasAdminAccess } from '@/store/main/getters';
import { commitSetDashboardShowDrawer, commitSetDashboardMiniDrawer } from '@/store/main/mutations';
import { dispatchUserLogOut } from '@/store/main/actions';
import { dispatchGetUnits } from '@/store/admin/actions';

const routeGuardMain = async (to, from, next) => {
  if (to.path === '/main') {
    next('/main/dashboard');
  } else {
    next();
  }
};

@Component
export default class Main extends Vue {
    data() {
      return {
        // will be reactive as it is declared via `data` hook.
        navbarMenuItems: [
          { title: 'Profile', icon: 'mdi-account', path: '/main/profile', action: 'redirect' },
          { title: 'Logout', icon: 'mdi-close', action: 'logout' },
        ],
        mainMenuItems: [
          { title: this.$t('dashboard'), icon: 'mdi-view-dashboard', path: '/dashboard' },
          { title: this.$t('profile'), icon: 'mdi-account', path: '/main/profile/view' },
          // { title: this.$t('editProfile'), icon: 'mdi-account-edit', path: '/main/profile/edit' },
        ],
        adminMenuItems: [
          { title: this.$t('settings'), icon: 'mdi-cog', path: '/main/settings' },
          { title: this.$t('users'), icon: 'mdi-account-multiple', path: '/main/admin/users/all' },
          { title: this.$t('projects'), icon: 'mdi-folder-multiple', path: '/main/admin/projects/all' },
          { title: this.$t('quotas'), icon: 'mdi-folder-multiple', path: '/main/admin/quotas/list' },
          { title: this.$t('rooms'), icon: 'mdi-note-multiple', path: '/main/admin/rooms/all' },
          { title: this.$t('typeOfWorks'), icon: 'mdi-folder-plus', path: '/main/admin/type-of-work/all' },
          { title: this.$t('categories'), icon: 'mdi-folder-plus', path: '/main/admin/categories/all' },
          { title: this.$t('products'), icon: 'mdi-folder-plus', path: '/main/admin/products/all' },
          { title: this.$t('services'), icon: 'mdi-folder', path: '/main/admin/services' },
        ],
        systemMenuItem: { title: this.$t('logOut'), icon: 'mdi-close', action: 'logout' },
        right: null,
      }
    }

  //HOOKS

  public beforeRouteEnter(to, from, next) {
    routeGuardMain(to, from, next);
  }

  public beforeRouteUpdate(to, from, next) {
    routeGuardMain(to, from, next);
  }

  //COMPUTED

  get miniDrawer() {
    return readDashboardMiniDrawer(this.$store);
  }

  get showDrawer() {
    return readDashboardShowDrawer(this.$store);
  }

  set showDrawer(value) {
    commitSetDashboardShowDrawer(this.$store, value);
  }

  get appName() {
    return this.$store.state.admin.platformParameters.name
  }

  //METHODS

  public handleNavbarMenuButton(item) {
    if (item?.action && item?.action === 'logout') {
        this.logout()
    }
    if (item?.action && item?.action === 'redirect') {
        this.$router.push({path: `${item.path}`})
    }
  }

  public switchShowDrawer() {
    commitSetDashboardShowDrawer(
      this.$store,
      !readDashboardShowDrawer(this.$store),
    );
  }

  public switchMiniDrawer() {
    commitSetDashboardMiniDrawer(
      this.$store,
      !readDashboardMiniDrawer(this.$store),
    );
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async logout() {
    await dispatchUserLogOut(this.$store);
  }

  async mounted() {
    await dispatchGetUnits(this.$store);
  }
}
