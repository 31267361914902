



























































































































import { Cropper } from 'vue-advanced-cropper';
import "vue-advanced-cropper/dist/style.css";
import { Vue, Prop, Component, Watch } from 'vue-property-decorator'

function getMimeType(file, fallback = null) {
	const byteArray = (new Uint8Array(file)).subarray(0, 4);
    let header = '';
    for (let i = 0; i < byteArray.length; i++) {
       header += byteArray[i].toString(16);
    }
	switch (header) {
        case "89504e47":
            return "image/png";
        case "47494638":
            return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
            return "image/jpeg";
        default:
            return fallback;
    }
}

@Component({
    components: {
        Cropper,
    }
})
export default class CropImage extends Vue {
    @Prop(String) readonly name!: string | '';
    @Prop(String) readonly textButton!: string | 'Change logo';
    @Prop(String) readonly textTitle!: string | 'Upload logo';
    @Prop(String) readonly imgUrl!: string | null;
    @Prop(Object) readonly imgSize!: {width: number, height: number};
    image: {src: string | null, type: null | string, preview: null | string} = {
        src: null,
        type: null,
        preview: this.imgUrl
    }
    dialog = false

    @Watch('imgUrl', { immediate: true })
    public async onImgUrl(
        newVal: string
    ) {
      this.image.preview = newVal
    }

    crop() {
        const cropper = this.$refs.cropper as InstanceType<typeof Cropper> | null;
        if (cropper) {
            const { canvas } = cropper.getResult();

            if (canvas && this.image.type) {
                canvas.toBlob((blob) => {
                this.$emit("upload", {name: this.name, file: blob})
                this.image.preview = canvas.toDataURL()
                }, this.image.type);
            }
        }
        
    }
    reset() {
        this.image.src = null
        this.image.type = null
    }

    clickButton() {
        const file = this.$refs.file as HTMLInputElement
        file.click()
    }
    clickUploadButton() {
        this.crop()
        this.dialog = false
        this.reset()
    }
    loadImage(event) {
        const { files } = event.target;
        if (files && files[0]) {
            if (this.image.src) {
                URL.revokeObjectURL(this.image.src)
            }
            const blob = URL.createObjectURL(files[0]);
            const reader = new FileReader();
            reader.onload = (e) => {
                this.image.src = blob
                this.image.type = getMimeType(e.target?.result, files[0].type)
            };
            reader.readAsArrayBuffer(files[0]);
        }
    }

    destroyed() {
		if (this.image.src) {
			URL.revokeObjectURL(this.image.src)
		}
	}
    
    
}
