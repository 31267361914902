var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('manageUsers'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/users/create"}},[_vm._v(_vm._s(_vm.$t('createUser')))])],1),_c('div',[_c('v-tabs',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_vm._l((_vm.nav),function(tab,index){return _c('v-tab',{key:index,on:{"click":function($event){return _vm.getUsers(tab.queryParams)}}},[_vm._v(" "+_vm._s(tab.title)+" ")])}),_c('v-tabs-slider')],2),_c('div',{staticClass:"d-flex justify-end"},[_c('div',{staticClass:"search-input"},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"outlined":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]),(_vm.users.length)?[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.users,"footer-props":{'items-per-page-options': [_vm.itemsPerPage]},"search":_vm.search,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"item.check_isActive",fn:function(ref){
var item = ref.item;
return [(item.is_active)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.check_superUser",fn:function(ref){
var item = ref.item;
return [(item.is_superuser)?_c('v-icon',[_vm._v("mdi-check")]):_vm._e()]}},{key:"item.button",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.handleEditButton(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleMenuButton(item,'delete')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}},{key:"item.projects",fn:function(ref){
var item = ref.item;
return [(item.projects.length > 0)?_c('div',{staticClass:"date-wrapper"},[_c('ul',{staticClass:"date-list",staticStyle:{"list-style":"none","padding-left":"0"}},_vm._l((item.projects),function(project,index){return _c('li',{key:project.id},[_c('RouterLink',{attrs:{"to":{name: 'main-admin-project-edit', params: {id: project.id}}}},[_vm._v(_vm._s(((index + 1) + "."))+" "+_vm._s(project.devis_name))])],1)}),0)]):_vm._e()]}},{key:"item.projects_m",fn:function(ref){
var item = ref.item;
return [(item.projects_m.length > 0)?_c('div',{staticClass:"date-wrapper"},[_c('ul',{staticClass:"date-list",staticStyle:{"list-style":"none","padding-left":"0"}},_vm._l((item.projects_m),function(project,index){return _c('li',{key:project.id},[_c('RouterLink',{attrs:{"to":{name: 'main-admin-project-edit', params: {id: project.id}}}},[_vm._v(_vm._s(((index + 1) + "."))+" "+_vm._s(project.devis_name))])],1)}),0)]):_vm._e()]}}],null,false,1103440627)}),_c('AlertDialog',{attrs:{"show":_vm.alertDialog,"title":_vm.currentItem && _vm.tab === 1 ? 'confirmUserIsAdmin' : 'confirmUserAttachedToProject'},on:{"closeModal":_vm.closeModal}},[_c('div',{staticClass:"text-center"},[_c('br'),(_vm.projects && _vm.projects.length > 0)?_c('div',{staticClass:"date-wrapper"},[_c('ul',{staticClass:"date-list",staticStyle:{"list-style":"none","padding-left":"0"}},_vm._l((_vm.projects),function(project,index){return _c('li',{key:project.id},[_c('RouterLink',{attrs:{"to":{name: 'main-admin-project-edit', params: {id: project.id}}}},[_vm._v(_vm._s(((index + 1) + "."))+" "+_vm._s(project.devis_name))])],1)}),0)]):_vm._e(),_c('br')])]),_c('ConfirmDialog',{attrs:{"show":_vm.confirmDialog},on:{"closeModal":function($event){_vm.confirmDialog = false; _vm.currentItem = null},"confirmModalAgree":function($event){return _vm.deleteEvent(_vm.currentItem)}}})]:[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":40,"width":5,"color":"primary","indeterminate":""}})],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }