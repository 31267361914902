/* eslint-disable */
import { AdminState } from './state';
import { getStoreAccessors } from 'typesafe-vuex';
import { State } from '../state';

export const getters = {
    adminUsers: (state: AdminState) => state.users,
    adminOneUser: (state: AdminState) => (userId: number) => {
        const filteredUsers = state.users.filter((user) => user.id === userId);
        if (filteredUsers.length > 0) {
            return { ...filteredUsers[0] };
        }
    },

    //PROJECTS
    getProjects: (state: AdminState) => state.projects,
    // getCategories: (state: AdminState) => state.categories,
    getserviceCategories: (state: AdminState) => state.serviceCategories,
    adminOneProject: (state: AdminState) => (projectId: number) => {
        const filteredProjects = state.projects.filter((project) => project.id === projectId);
        if (filteredProjects.length > 0) {
            return { ...filteredProjects[0] };
        }
    },

    //ROOMS
    getRooms: (state: AdminState) => state.rooms,
    getSettings: (state: AdminState) => state.settings,
    adminOneRoom: (state: AdminState) => (roomId: any) => {
        return state.adminOneRoom
    },
    //QUOTAS
    getQuotas: (state: AdminState) => state.quotas,
    adminOneQuota: (state: AdminState) => (quotaId: any) => {
        const filteredQuotas = state.quotas.filter((quota) => quota.id === quotaId);
        if (filteredQuotas.length > 0) {
            return { ...filteredQuotas[0] };
        }
    },
    //PRODUCTS
    getProducts: (state: AdminState) => state.products,
    adminOneProduct: (state: AdminState) => (productId: any) => {
        const filteredProducts = state.products.filter((product) => product.id === productId);
        if (filteredProducts.length > 0) {
            return { ...filteredProducts[0] };
        }
    },

    //QUOTAS
    getCombinations: (state: AdminState) => state.serviceCombinations,
    oneCombination: (state: AdminState) => (combinationId: any) => {
        const filteredCombinations = state.serviceCombinations.filter((combination) => combination.id === combinationId);
        if (filteredCombinations.length > 0) {
            return { ...filteredCombinations[0] };
        }
    },
    isQuotaReadOnly: (state: AdminState) => (quotaId: number) => {
        const quota = state.quotas.find((quota) => quota.id === quotaId);
        if (quota) {
            return quota.custom_status === 7;
        }
        return false
    },
    // MESSAGES
    getMessages: (state: AdminState) => state.messages.filter(m => m.create_date !== null),

    // Services
    getServicesOfCategory: (state: AdminState) => (catId: string) => state.serviceByCatId.map(s => {
        let position = s.categories.find(c => c.category_id === +catId)?.position
        return {...s, position}
    }),
    getCategoryServices: (state: AdminState) => state.serviceCategories,
    getUnitShortName: (state: AdminState) => (unitId: number) => {
        const unit = state.units.find((unit) => unit.id === unitId);
        if (unit) {
            return unit.short_name
        }
        return "-"
    }
};

const { read } = getStoreAccessors<AdminState, State>('');

export const readAdminOneUser = read(getters.adminOneUser);
export const readAdminUsers = read(getters.adminUsers);
//PROJECTS GETTERS
export const readProjects = read(getters.getProjects);
// export const readCategories = read(getters.getCategories);

export const readAdminOneProject = read(getters.adminOneProject);
//ROOMS GETTERS
export const readRooms = read(getters.getRooms);
export const readAdminOneRoom = read(getters.adminOneRoom);
//ROOMS GETTERS
export const readQuotas = read(getters.getQuotas);
export const readAdminOneQuota = read(getters.adminOneQuota);
//COMBINATIONS GETTERS
export const readCombinations = read(getters.getCombinations);
export const readOneCombination = read(getters.oneCombination);
export const readIsQuotaReadOnly = read(getters.isQuotaReadOnly);

export const getSettings = read(getters.getSettings)

//ROOMS GETTERS
export const readProducts = read(getters.getProducts);
export const readAdminOneProduct = read(getters.adminOneProduct);

// MESSAGES
export const readMessages = read(getters.getMessages);

// Services
export const readCategoryServices = read(getters.getCategoryServices)
export const readServicesOfCategory = read(getters.getServicesOfCategory)

// Units
export const readUnitShortName = read(getters.getUnitShortName);