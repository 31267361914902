/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import {
  dispatchCreateRoomCategory,
} from '@/store/admin/actions';
import { dispatchGetRoomsPreselectedCategoriesTree } from '@/store/categories/actions';
import { readCategoriesWithoutTypeOfWork, readPreselectedCategoriesWithoutTypeOfWork } from '@/store/categories/getters'


@Component
export class RoomCategories extends Vue {
  valid = false;
  isActive = true;
  categories: any = [];
  name = '';
  image = '';
  defaultRoom = false;
  active: Array<object> = [];
  selectedCategory: any = [];
  selectedPreselectedCategory: any = [];
  openTree = [1];
  openTree2 = [1];
  roomCategories: any = [];
  roomPreselectedCategories: any = [];
  selectedImg: any = null;
  imageSrc: any = null;
  categoriesList = 0;
  fieldRules = [
    value => !!value || `${this.$t('required')}.`
  ];

  get categoriesTree() {
    const categoriesTree = Object.values(readCategoriesWithoutTypeOfWork(this.$store))
    const categoriesFormatted = categoriesTree.map(c => {
      if (c) {
        const category: any = c

        if (category.children && Object.keys(category.children).length > 0) {
          category.children = Object.values(category.children)
          return category
        } else {
          category.children = []
          return category
        }
      } else {
        return []
      }
    });
    return categoriesFormatted
  }

  get categoriesPreselectedTree() {
    const categoriesTree = Object.values(readPreselectedCategoriesWithoutTypeOfWork(this.$store))
    const categoriesFormatted = categoriesTree.map(c => {
      if (c) {
        const category: any = c

        if (category.children && Object.keys(category.children).length > 0) {
          category.children = Object.values(category.children)
          return category
        } else {
          category.children = []
          return category
        }
      } else {
        return []
      }
    });
    return categoriesFormatted
  }

  get imageToShow() {
    if (this.imageSrc) {
      return this.imageSrc
    } else {
      return this.selectedImg
    }
  }

  addImage(event) {
    this.image = event
    if (event) {
      let reader = new FileReader();
      let vm = this;

      reader.onload = function (e) {
        vm.imageSrc = e?.target?.result;
      };

      reader.readAsDataURL(event);
    }
  }

  reset() {
    this.categories = [];
    this.name = '';
    this.image = '';
    this.defaultRoom = false;
    this.$validator.reset();
  }

  cancel() {
    this.$router.back();
  }

  saveSelectedCategory(value) {
    this.roomCategories = value?.map(id => {
      const serviceCategoryValue = {
        service_id: 0,
        category_id: 0,
        preselected: true
      }
      serviceCategoryValue.category_id = id;
      return serviceCategoryValue
    })
  }
  saveSelectedPreselectedCategory(value) {
    this.roomPreselectedCategories = value?.map(id => {
      const serviceCategoryValue = {
        service_id: 0,
        category_id: 0,
        preselected: false
      }
      serviceCategoryValue.category_id = id;
      return serviceCategoryValue
    })
  }

  async updateRoomCategories(roomId: number) {
    const roomCategories = [...this.roomCategories, ...this.roomPreselectedCategories].map(category => {
      category.room_id = roomId
      return category
    });
    const checkCategories = roomCategories && roomCategories.length ? roomCategories : [{ room_id: roomId, category_id: 0 }]
    await dispatchCreateRoomCategory(this.$store, checkCategories)
    await dispatchGetRoomsPreselectedCategoriesTree(this.$store, roomId)
  }
}