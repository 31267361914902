/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';
import { readToken } from "@/store/main/getters";
import { dispatchGetServices, dispatchGetServicesByCatId } from '@/store/admin/actions';
import { api } from "@/api";
import { dispatchDeleteCategory, dispatchGetCategories, dispatchGetParentCategories } from '@/store/categories/actions';
import { readCategories } from '@/store/categories/getters';

interface IParentCategories {
    name: string;
    id: number;
    children: IParentCategories[]
}

interface ICurrentCategory {
    id: number;
    parent_id: number;
    name: string;
    description: string;
    image: string;
}

@Component
export class Categories extends Vue {
    dialog = false
    activeCategory = -1
    valid = false;
    status = false;

    async handleMenuButton(value) {
        await dispatchDeleteCategory(this.$store, value.id)
        await dispatchGetCategories(this.$store);
    }

    handlePoupButton({ id }) {
        this.activeCategory = id
        setTimeout(() => {
            this.dialog = true
        }, 100)
    }

    async mounted(): Promise<void> {
        await dispatchGetCategories(this.$store);
    }
}


@Component
export class CreateCategory extends Vue {
    headers = [];
    selectedCategory: number[] = []
    valid = false
    name = ''
    description = ''
    parent_id = 0
    image = ''
    
    selectedImg: any = null;
    imageSrc: any = null;

    active = []
    open = [1]
    parentCategories: IParentCategories | null = null
    ruleRequired = [
        value => !!value || `${this.$t('required')}.`
    ];


    get categoriesTree() {
        return this.$store.state.category.categories
    }

    get parentCategoriesTree() {
        return this.categoriesTree.length ? this.$store.state.category.parentCategoriesTree : null
    }

    get imageToShow() {
        if (this.imageSrc) {
            return this.imageSrc
        } else {
            return this.selectedImg
        }
    }

    addImage(event) {
        this.image = event
        if (event) {
            let reader = new FileReader();
            let vm = this;

            reader.onload = function (e) {
                vm.imageSrc = e?.target?.result;
            };

            reader.readAsDataURL(event);
        }
    }
    reset() {
        this.selectedCategory = []
        this.name = ''
        this.description = ''
        this.parent_id = 0
    }
    cancel() {
        this.$router.back();
    }
    saveSelectedCategory(item) {
        if (this.selectedCategory.length >= 2) {
            this.selectedCategory.shift()
        }
        this.parent_id = item[0]
    }
    async getParentCategories() {
        const parentCategories = await dispatchGetParentCategories(this.$store)
        let categoriesForTree: any = []
        categoriesForTree.push(parentCategories)
        this.parentCategories = categoriesForTree
    }

    async mounted(): Promise<void> {
        await this.getParentCategories()
        await dispatchGetCategories(this.$store);
    }
}

@Component
export class EditCategory extends CreateCategory {
    currentCategory: ICurrentCategory | null = null;

    get token() {
        return readToken(this.$store)
    }

    get hasParent() {
        return this.currentCategory && this.currentCategory.parent_id > 1
    }

    async getParentCategories() {
        let categoryTreeObject: any = {}
        const parentCategoryId = +this.$route.params.id
        const parentCategories = await dispatchGetParentCategories(this.$store) as IParentCategories;
        const filteredCategoryChild = parentCategories?.children.filter(child => child.id !== parentCategoryId);
        categoryTreeObject = { ...parentCategories }
        categoryTreeObject.children = filteredCategoryChild
        let categoriesForTree: any = []
        categoriesForTree.push(categoryTreeObject)
        this.parentCategories = categoriesForTree
    }

    async getCategoryById() {
        const response = await api.getCategoryById(this.token, this.$route.params.id)
        this.currentCategory = response.data as ICurrentCategory
        if (this.currentCategory) { this.fillModel() }
    }

    fillModel() {
        if (this.currentCategory) {
            this.selectedCategory.push(this.currentCategory.parent_id)
            this.name = this.currentCategory.name
            this.description = this.currentCategory.description || ''
            this.parent_id = this.currentCategory.parent_id
            this.selectedImg = this.currentCategory.image
        }
    }

    async mounted(): Promise<void> {
        await this.getParentCategories()
        await this.getCategoryById()
        await dispatchGetServices(this.$store)
        await dispatchGetServicesByCatId(this.$store, this.$route.params.id)
    }
}