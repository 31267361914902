







/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { dispatchCreateService, dispatchCreateServiceCategory } from '@/store/admin/actions';
import ServiceForm from "./ServiceForm.vue";

@Component({
    components: {
        "service-form": ServiceForm
    }
})
export default class ServicesBasicSettings extends Vue {

    type = 'create';

    //METHODS
    async submit(value) {
        if (value.valid) {
            const serviceConfig = {
                name: value.name,
                description: value.description,
                short_description: value.short_description,
                reference: value.reference,
                default_price: value.default_price,
                tax: value.tax,
                additional_units: value.additional_units,
                unit_id: value.unit_id,
            }
            const service: any = await dispatchCreateService(this.$store, serviceConfig).then(async response => {
                const serviceCategories = value?.serviceCategories?.map(category => {
                    category.service_id = response.id
                    return category
                })
               if (serviceCategories && serviceCategories.length) {
                   await dispatchCreateServiceCategory(this.$store, serviceCategories)
               }
               return response
            });

            if (!value.disabledRedirect) {
                await this.$router.push('/main/admin/services');
            }
            if (service?.id && value.disabledRedirect) {
                await this.$router.replace({name: "service-edit", params: {id: service.id}})
            }

        }
    }
}
