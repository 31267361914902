









/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { dispatchUpdateProduct } from '@/store/admin/actions';
import ProductForm from "@/views/main/admin/Products/ProductForm.vue";

@Component({
    components: {
        "product-form": ProductForm
    }
})
export default class EditProduct extends Vue {

    async submit(value) {
        if (await this.$validator.validateAll()) {
            const formData = new FormData()
            formData.append('name', value.name);
            formData.append('image', value.image);
            formData.append('description', value.description === null ? '' : value.description);
            formData.append('short_description', value.short_description === null ? '' : value.short_description);
            formData.append('price', value.price);
            formData.append('reference', value.reference);

            await dispatchUpdateProduct(this.$store, {id: value.id, formData});
            if (!value.disabledRedirect) {
                await this.$router.push('/main/admin/products/all');
            }
        }
    }
}
