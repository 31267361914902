const env = process.env.VUE_APP_ENV;

let envApiUrl = '';
let clientQuotaUrl = '';
let defaultLocale = ''
let imageFolder = '';

if (env === 'production') {
  clientQuotaUrl = `https://${process.env.VUE_APP_CLIENT_PROD}`;
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_PROD}`;
  defaultLocale = `${process.env.VUE_APP_DEFAULT_LANG_PROD}`;
  imageFolder = `${process.env.VUE_APP_PRINT_IMAGE_FOLDER_STAG}`;

} else if (env === 'staging') {
  clientQuotaUrl = `https://${process.env.VUE_APP_CLIENT_STAG}`;
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_STAG}`;
  defaultLocale = `${process.env.VUE_APP_DEFAULT_LANG_STAG}`;
  imageFolder = `${process.env.VUE_APP_PRINT_IMAGE_FOLDER_STAG}`;
} else {
  clientQuotaUrl = `https://${process.env.VUE_APP_CLIENT_DEV}`;
  envApiUrl = `https://${process.env.VUE_APP_DOMAIN_DEV}`;
  defaultLocale = `${process.env.VUE_APP_DEFAULT_LANG_STAG}`;
  imageFolder = `${process.env.VUE_APP_PRINT_IMAGE_FOLDER_STAG}`;
}

export const apiUrl = envApiUrl;
export const appName = process.env.VUE_APP_NAME;
export const clientImageFolder = imageFolder;
export const VHClientQuotaUrl = clientQuotaUrl;
export const locale = defaultLocale;