import { api } from '@/api';
import { ActionContext } from 'vuex';
import { i18n } from '@/plugins/i18n';
import { getStoreAccessors } from 'typesafe-vuex';
import { CategoryState } from './state';
import { State } from '../state';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { commitSetCategories, commitSetCategoriesTree, commitSetParentCategoriesTree, commitSetPreselectedCategoriesTree } from './mutations';

type MainContext = ActionContext<CategoryState, State>;

export const actions = {
    async actionGetCategories(context: MainContext) {
        try {
            const response = await api.getCategories(context.rootState.main.token);
            if (response) {
                commitSetCategories(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetCategoriesTree(context: MainContext) {
        try {
            const response = await api.getCategoriesTree(context.rootState.main.token);
            if (response) {
                commitSetCategoriesTree(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetParentCategories(context: MainContext) {
        try {
            const response = await api.getParentCategories(context.rootState.main.token, 1);
            if (response) {
                commitSetParentCategoriesTree(context, response.data)
                return response.data;
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateCategory(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createCategory(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // commitSetCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('categorySuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            if (response) {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
                return error
            }
        }
    },
    async actionUpdateCategory(context: MainContext, payload) {
        const loadingNotification = { content: i18n.t('saving'), showProgress: true };
        commitAddNotification(context, loadingNotification);
        await api.updateCategory(context.rootState.main.token, payload.id, payload.formData).then(response => {
            // commitSetCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('categorySuccessfullyUpdated'), color: 'success' });
        }).catch(async error => {
            const { response } = error;
            if (response) {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
                return error
            } else {
                commitRemoveNotification(context, loadingNotification);
                commitAddNotification(context, { content: i18n.t('categorySuccessfullyUpdated'), color: 'success' });
            }
        });
    },
    async actionDeleteCategory(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteCategory(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('categorySuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    async actionGetRoomsPreselectedCategoriesTree(context: MainContext, roomId) {
        try {
            const response = await api.getroomPreselectedCategoryTree(context.rootState.main.token, roomId);
            if (response) {
                commitSetPreselectedCategoriesTree(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
}

const { dispatch } = getStoreAccessors<CategoryState, State>('');
export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
export const dispatchGetCategories = dispatch(actions.actionGetCategories);
export const dispatchGetCategoriesTree = dispatch(actions.actionGetCategoriesTree);
export const dispatchGetRoomsPreselectedCategoriesTree = dispatch(actions.actionGetRoomsPreselectedCategoriesTree);
export const dispatchGetParentCategories = dispatch(actions.actionGetParentCategories);
export const dispatchUpdateCategory = dispatch(actions.actionUpdateCategory);
export const dispatchDeleteCategory = dispatch(actions.actionDeleteCategory);