














































































































































































    /* eslint-disable */
    import { Vue, Component, Prop, Emit } from 'vue-property-decorator'
    import {
        dispatchGetServiceCategories,
        dispatchGetUnits,
        dispatchGetServiceById
    } from "@/store/admin/actions";
    import {api} from "@/api";
import { dispatchGetCategories, dispatchGetCategoriesTree } from '@/store/categories/actions';
import { readCategoriesWithoutTypeOfWork } from '@/store/categories/getters';
    @Component
    export default class ServiceForm extends Vue {
        //PROPS
        @Prop(Boolean) readonly readOnly : boolean | undefined;
        @Prop(String) readonly title : string | undefined;
        @Prop(String) readonly formType : string | undefined;

        //EMIT
        @Emit()
        submit(disabledRedirect) {
            if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
                return {
                    name: this.name,
                    description: this.description,
                    short_description: this.short_description,
                    reference: this.reference,
                    default_price: this.default_price,
                    tax: this.tax,
                    additional_units: this.additional_units,
                    serviceCategories: this.serviceCategories,
                    unit_id: this.unit_id,
                    valid: true,
                    disabledRedirect
                }
            }
        }

        //DATA
        valid: boolean = false;
        name: string = '';
        description: string = '';
        short_description: string = '';
        reference: string = '';
        default_price: string = '';
        tax: number | string = '';
        additional_units: number | string = 0;
        unit_id: string | number = '';
        parent_id: string | number = '';
        active: Array<object> = [];
        openTree = [1];
        selectedCategory: Array<object> = [];
        serviceCategories: Array<object> | null = null;
        service: any = null;
        fieldRules = [
            value => !!value || `${this.$t('required')}.`
        ];
        wysiwygOptions = {
            debug: 'info',
            modules: {
                toolbar: [
                    ['bold', 'italic', 'underline'],        // toggled buttons
                    // ['blockquote', 'code-block'],

                    // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                    [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                    // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                    // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                    // [{ 'direction': 'rtl' }],                         // text direction
                    //
                    // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                    // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                    //
                    // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                    // [{ 'font': [] }],
                    // [{ 'align': [] }],

                    ['clean']                                         // remove formatting button
                ]
            },
            placeholder: this.$t('description'),
            readOnly: true,
            theme: 'snow'
        }

        editType = 'edit';

        //COMPUTED
        get unitList () {
            return this.$store.state.admin.units
        }
        get categories () {
            return readCategoriesWithoutTypeOfWork(this.$store)
        }

        //METHODS
        handleEditUnit(unit) {
            let route = this.$router.resolve({name: 'settingsPage', query: {unit: new URLSearchParams(unit).toString()}});
            window.open(route.href, '_blank');
        }
        async getServiceById() {
            const serviceId = this.$route.params.id;
            await dispatchGetServiceById(this.$store, serviceId);
            this.service = this.$store.state.admin.serviceById
        }
        fillModel() {
            if (this.service) {
                this.name = this.service.name;
                this.description = this.service.description;
                this.short_description = this.service.short_description;
                this.reference = this.service.reference;
                this.default_price = this.service.default_price;
                this.tax = this.service.tax;
                this.additional_units = this.service.additional_units;
                this.selectedCategory = this.service.categories.map(category => {
                    return category.category_id
                });
                this.unit_id = this.service.unit_id
            }
        }
        reset() {
            this.selectedCategory = [];
            this.default_price = '';
            this.description = '';
            this.short_description = '';
            this.tax = 0;
            this.parent_id = 0;
            this.unit_id = 0
        }
        cancel() {
            this.$router.back();
        }
        saveSelectedCategory(value) {
            this.serviceCategories = value?.map(id => {
                const serviceCategoryValue = {
                    service_id: 0,
                    category_id: 0
                }
                serviceCategoryValue.category_id = id;
                return serviceCategoryValue
            })
        }
        async mounted(): Promise<void> {
            await dispatchGetCategories(this.$store);
            await dispatchGetUnits(this.$store);
            await dispatchGetCategoriesTree(this.$store);
            if (this.formType === this.editType) {
                await dispatchGetServiceCategories(this.$store, this.$route.params.id);
                await this.getServiceById();
                this.fillModel()
            }
        }
    }
