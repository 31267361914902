







    /* eslint-disable */
    import {Vue, Component} from 'vue-property-decorator'
    import { readCategoryServices } from '@/store/admin/getters';
    import { readCategories } from '@/store/categories/getters';
    import {
        dispatchCreateServiceCategory,
        dispatchGetServiceCategories,
        dispatchUpdateService
    } from '@/store/admin/actions';
    import ServiceForm from "./ServiceForm.vue";
    import {api} from "@/api";
import { dispatchGetCategories } from '@/store/categories/actions';

    @Component({
        components: {
            "service-form": ServiceForm
        }
    })
    export default class EditService extends Vue {

        //DATA
        type = 'edit';

        //COMPUTED
        get categories() {
            return readCategories(this.$store);
        }

        //METHODS
        async submit(value) {
            if (value.valid) {
                const serviceConfig = {
                    name: value.name,
                    description: value.description,
                    short_description: value.short_description,
                    reference: value.reference,
                    default_price: value.default_price,
                    tax: value.tax,
                    additional_units: value.additional_units,
                    unit_id: value.unit_id,
                };

                await dispatchUpdateService(this.$store, {id: this.$route.params.id, serviceConfig}).then(async () => {
                    const serviceCategories = value?.serviceCategories?.map((category) => {
                        let serviceCategoriesFromStore = readCategoryServices(this.$store).find(s => s.category_id === category.category_id)
                        if (serviceCategoriesFromStore) {
                            return serviceCategoriesFromStore
                        }
                        category.service_id = this.$route.params.id
                        category.position = 0
                        return category
                    })
                    const checkCategories = serviceCategories && serviceCategories.length ? serviceCategories : [{service_id: this.$route.params.id, category_id: 0, position: 0}] 
                    await dispatchCreateServiceCategory(this.$store, checkCategories).then(async () => {})
                    if (!value.disabledRedirect) {
                        await this.$router.push('/main/admin/services');
                    }
                });
            }
        }
        //HOOKS
        async mounted(): Promise<void> {
            await dispatchGetCategories(this.$store);
            await dispatchGetServiceCategories(this.$store, this.$route.params.id);
        }
    }
