





















































/* eslint-disable */
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { EditCategory as EditCategoryMixin } from './mixins'
import CategoryService from "./CategoryServices/CategoryServices.vue"
import Services from "./Services/Services.vue"
import { dispatchUpdateCategory } from '@/store/categories/actions'
import { readCategoryTreeForCategoryPage } from '@/store/categories/getters'

@Component({
    components: { CategoryService, Services },
})
export default class EditCategory extends Mixins(EditCategoryMixin) {
    get categories() {
        return this.currentCategory?.id ? readCategoryTreeForCategoryPage(this.$store)(this.currentCategory.id) : []
    }

    get openTreeItem() {
        return [this.$route.query.type || 1]
    }

    async submit(disabledRedirect) {
        if (this.currentCategory && (this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            const formData = new FormData()
            const parentId = this.parent_id ? `${this.parent_id}` : '1';

            formData.append('name', this.name);
            formData.append('description', this.description);
            formData.append('image', this.image);
            formData.append('id', `${this.currentCategory.id}`);
            formData.append('parent_id', parentId);

            await dispatchUpdateCategory(this.$store, { id: this.currentCategory.id, formData });
            if (!disabledRedirect) {
                await this.$router.push('/main/admin/categories/all');
            }
        }
    }

}
