


















import { Component, Vue } from 'vue-property-decorator';
import { Store } from 'vuex';
import { readUserProfile } from '@/store/main/getters';

@Component
export default class Dashboard extends Vue {
    // eslint-disable-next-line getter-return
    get greetedUser() {
        const userProfile = readUserProfile(this.$store);
        if (userProfile) {
            if (userProfile.full_name) {
                return userProfile.full_name;
            } else {
                return userProfile.email;
            }
        }
    }
}
