import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { CategoryState } from "./state";

const defaultState: CategoryState = {
    filterByTypeOfWorks: '',
    categories: null,
    categoriesTree: null,
    preselectedCategoriesTree: null,
    parentCategoriesTree: [],
}

export const categoryModule = { 
    state: defaultState,
    mutations,
    actions,
    getters,
};