


















































































/* eslint-disable */
import { IEmailSettings, IEmailSettingsUpdateDto } from '@/interfaces';
import { dispatchCreateEmailSettings, dispatchUpdateEmailSettings } from '@/store/admin/actions';
import { Component, Vue, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    CropImage: () => import('@/components/ui/CropImage.vue')
  }
})

export default class EmailSettings extends Vue {
  @Prop(Object) settingsProp!: IEmailSettings;
  settings = {} as IEmailSettings
  file: File | null = null
  counter = 0

  @Watch('settingsProp', { immediate: true })
    public async onSettingsPropChange(
        newVal: IEmailSettings
    ) {
      this.settings = {...newVal}
    }

  setLogo(payload: {name: string, file: File}) {
    this.file = payload.file
  }

  created() {
    this.$nextTick(() => {
      this.counter++
    })
  }

  async submit() {
    if (!this.settings.id && this.file) {
      await dispatchCreateEmailSettings(this.$store, {...this.settings, logo: this.file })
    }
    if (this.settings.id) {
        let formData = {} as IEmailSettingsUpdateDto
        if (this.file) {
          formData.logo = this.file
        }
        formData = Object.assign({}, this.settings, formData)
        if (!(formData.logo instanceof Blob)) {
          delete formData.logo
        }
        await dispatchUpdateEmailSettings(this.$store, formData)
    }
  }
}
