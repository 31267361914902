
























































































































































/* eslint-disable */
import { Component, Vue, Watch } from "vue-property-decorator";
import { IUserProfile, IUserProfileUpdate } from "@/interfaces";
import { dispatchGetUser, dispatchGetUsers, dispatchUpdateUser } from "@/store/admin/actions";
import { readAdminOneUser } from "@/store/admin/getters";

@Component({
  components: {
    AlertDialog: () => import("@/components/ui/AlertDialog.vue")
  }
})
export default class EditUser extends Vue {
  public alertDialog = false;
  public valid = true;
  public fullName: string = "";
  public email: string = "";
  public phone: any = "";
  public address: any = "";
  public city: any = "";
  public zip_code: any = "";
  public isActive: boolean = true;
  public isSuperuser: boolean = false;
  public setPassword = false;
  public password1: string = "";
  public password2: string = "";

  public onClickIsSuperuser(): void {
    if (this.isProjectManager)
      this.alertDialog = true;
  }

  public async mounted() {
    await dispatchGetUser(this.$store, +this.$route.params.id);
    this.reset();
  }

  public reset() {
    this.$validator.reset();
    if (this.user) {
      this.fullName = this.user.full_name;
      this.email = this.user.email;
      this.isActive = this.user.is_active;
      this.isSuperuser = this.user.is_superuser;
      this.phone = this.user.phone;
      this.address = this.user.address;
      this.city = this.user.city;
      this.zip_code = this.user.zip_code;
    }
  }

  public cancel() {
    this.$router.back();
  }

  public async submit(disabledRedirect) {
    if ((this.$refs.editUserForm as Vue & { validate: () => boolean }).validate()) {
      const updatedProfile: IUserProfileUpdate = {};
      if (this.fullName) {
        updatedProfile.full_name = this.fullName;
      }
      if (this.email) {
        updatedProfile.email = this.email;
      }
      updatedProfile.phone = this.phone;
      updatedProfile.city = this.city;
      updatedProfile.address = this.address;
      updatedProfile.zip_code = this.zip_code;
      updatedProfile.is_active = this.isActive;
      updatedProfile.is_superuser = this.isSuperuser;
      if (this.setPassword && this.password1) {
        updatedProfile.password = this.password1;
      }
      await dispatchUpdateUser(this.$store, {
        id: this.user!.id,
        user: updatedProfile,
      });
      if (!disabledRedirect) {
        this.$router.push("/main/admin/users");
      }
    }
  }

  get user() {
    return readAdminOneUser(this.$store)(+this.$router.currentRoute.params.id);
  }

  get isProjectManager() {
    return this.user?.is_superuser && this.user?.projects_m?.length > 0
  }
}
