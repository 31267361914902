







































/* eslint-disable */
import axios from "axios";
import {apiUrl} from "@/env";
import { Component, Vue } from 'vue-property-decorator';
import {readProjects} from '@/store/admin/getters';
import {dispatchGetProject, dispatchDeleteProject} from '@/store/admin/actions';




@Component
export default class Projects extends Vue {
    //DATA
    serverResponse: any = {};
    headers = [
        {
            text: this.$t('projectName'),
            sortable: true,
            value: 'devis_name',
            align: 'left',
        },
        {
            text: this.$t('city'),
            sortable: true,
            value: 'city',
            align: 'left',
        },
        {
            text: this.$t('address'),
            sortable: true,
            value: 'address',
            align: 'left',
        },
        {
            text: this.$t('userName'),
            sortable: true,
            value: 'user.full_name',
            align: 'left',
        },
        {
            text: this.$t('email'),
            sortable: true,
            value: 'user.email',
            align: 'left',
        },
        {
            text: this.$t('startOfWorkDate'),
            sortable: true,
            value: 'start_date',
            align: 'left',
        },
        {
            text: this.$t('endOfWorkDate'),
            sortable: true,
            value: 'end_date',
            align: 'left',
        },
        {
            text: '',
            value: 'button',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        },
    ]

    //COMPUTED
    get projects() {
        return readProjects(this.$store).map(project => {
          return {
            ...project,
            start_date: this.serverResponse[`${project.start_date}`]
          }
        });
    }
    get authHeaders() {
      return {
          headers: {
              Authorization: `Bearer ${this.$store.state.main.token}`,
          },
      };
    }
    //METHODS
    async handleMenuButton(item) {
        await dispatchDeleteProject(this.$store, item.id)
        await dispatchGetProject(this.$store);
    }
    handleEditButton(value) {
        this.$router.push({name: 'main-admin-project-edit', params: {id: value.id}})
    }
    handleTableRowClick(value) {
        // this.$router.push({name: 'main-admin-project-view', params: {id: value.id}})
    }
    async dicts() {
        const dicts = await axios.get<any>(`${apiUrl}/api/v1/projects/dicts`, this.authHeaders)
        this.serverResponse = dicts?.data?.start_date || {}
    }
    //HOOKS
    async mounted(): Promise<void> {
        await dispatchGetProject(this.$store);
        await this.dicts()
    }
}
