









































/* eslint-disable */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import draggable from "vuedraggable";
import {
    dispatchGetServicesByCatId,
    dispatchUpdateServicePosition
} from '@/store/admin/actions';
import {readServicesOfCategory} from "@/store/admin/getters";
import DataTableRowHandler from "@/components/ui/DataTableRowHandler.vue" 

@Component({
  components: {
    draggable,
    DataTableRowHandler
  }
})
export default class CategoryServices extends Vue {
  @Prop([String, Number]) readonly catId!: "";

  headers: any = [
    {
      text: '',
      sortable: false,
      value: 'button-drag',
      align: 'left',
    },
    {
      text: this.$t('serviceName'),
      sortable: false,
      value: 'name',
      align: 'left',
    },
    {
      text: this.$t('description'),
      sortable: false,
      value: 'description',
      align: 'left',
    },
  ];

  moveItemService: any = null
  dropItemService: any = null
  updatePositionLoader: boolean = false

  get services() {
    let list = readServicesOfCategory(this.$store)(this.catId || this.$route.params.id)
    return Array.isArray(list) ? list : []
  }

  @Watch('catId', { immediate: true })
  async onValueChanged(val: number): Promise<void> {
    await dispatchGetServicesByCatId(this.$store, this.catId)
  }

  onMoveCallback(evt, originalEvent) {
    const item = evt.draggedContext.element;
    const itemIdx = evt.draggedContext.futureIndex;

    this.moveItemService = evt.draggedContext.element
    this.dropItemService = this.services[itemIdx]

    return true;
  }
  onDropCallback({newIndex, oldIndex}) {
    this.updatePosition({newIndex, oldIndex})
  }

  async updatePosition({newIndex, oldIndex}) {
    let moveItemPosition = this.moveItemService.position
    let dropItemPosition = this.dropItemService.position
    let catId = this.catId || this.$route.params.id
    let moveItemServiceCategories = this.moveItemService.categories.map(sc => {
      if (sc.category_id === +catId) {
        sc.position = dropItemPosition !== moveItemPosition ? dropItemPosition : newIndex
      }
      return sc
    })
    let dropItemServiceCategories = this.dropItemService.categories.map(sc => {
      if (sc.category_id === +catId) {
        sc.position = moveItemPosition !== dropItemPosition ? moveItemPosition : oldIndex
      }
      return sc
    })

    this.updatePositionLoader = true

    await dispatchUpdateServicePosition(this.$store, [catId, moveItemServiceCategories, dropItemServiceCategories])

    this.updatePositionLoader = false
    this.moveItemService = null
    this.dropItemService = null
  }
}
