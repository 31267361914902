





































/* eslint-disable */
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
@Component
export default class ConfirmDialog extends Vue {
    @Prop(Boolean) readonly show: false = false;
    @Prop({type: String, default: "confirmModalDeleteDefaultText"}) readonly question!: String;
    @Prop({type: String, default: "430"}) readonly maxWidth!: String;
    @Prop({type: String, default: "confirmModalNotAgreeText"}) readonly notAgreeText!: String;
    @Prop({type: String, default: "confirmModalAgreeText"}) readonly agreeText!: String;
    @Prop(Boolean) readonly reverse!: false;
    

    dialog = false;
    

    @Watch('show', { immediate: true })
    onValueChanged(newVal: boolean): void {
        this.dialog = newVal;
    }
}
