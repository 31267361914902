


































































































































































































































/* eslint-disable */
import {Vue, Component, Prop, Emit, Watch} from 'vue-property-decorator'
import {readAdminOneQuota, readMessages} from '@/store/admin/getters';
import {
    dispatchGetQuotas,
    dispatchGetQuotasStatuses,
    dispatchCreateProjectHistory,
    dispatchGetProject,
    dispatchGetPdfCertifications,
    dispatchGetPdfAssurance,
    dispatchDeletePdfCertifications,
    dispatchUpdatePdfCertifications,
    dispatchUpdatePdfAssurance,
    dispatchDeletePdfAssurance
} from '@/store/admin/actions';
import { format } from 'date-fns'
import { IQuotaPdfSettings } from '@/interfaces';
@Component
export default class QuotaForm extends Vue {
    //PROPS
    @Prop(Boolean) readonly readOnly : boolean | undefined;
    @Prop(String) readonly title : string | undefined;
    @Prop() readonly quotaId : any | undefined;
    @Prop() readonly projectId : any | undefined;

    //EMIT
    @Emit()
    submit(disabledRedirect) {
        if (this.quotaPdfSettingsFormData.assuranceOwnPDF && this.quotaPdfSettingsFormData.assuranceOwnPDF.length === 0) {
          this.deleteAssuranceOwnPDF()
        } else {
          this.updateAssuranceOwnPDF(this.quotaPdfSettingsFormData.assuranceOwnPDF)
        }
        if (this.quotaPdfSettingsFormData.certificationsOwnPDF && this.quotaPdfSettingsFormData.certificationsOwnPDF.length === 0) {
          this.deleteCertificationsOwnPDF()
        } else {
          this.updateCertificationsOwnPDF(this.quotaPdfSettingsFormData.certificationsOwnPDF)
        }
        return {
            name: this.name,
            edit: this.custom_status <= 2 ? true : false,
            custom_status: this.custom_status,
            statusHistory: this.toSaveStatusHistory ? this.statusHistory : null,
            paymentConditions: this.paymentConditions,
            hours_of_execution: this.hours_of_execution,
            start_work_date: this.start_work_date,
            visit_date: this.visit_date,
            show_assurances: this.show_assurances,
            show_certificates: this.show_certificates,
            disabledRedirect
        }
    }

    @Watch('paymentConditions.acompte.value')
      acompteChange(newValue, oldValue) {
        this.validationHandler('acompte', newValue, oldValue)
    }
    @Watch('paymentConditions.demarage.value')
      demarageChange(newValue, oldValue) {
        this.validationHandler('demarage', newValue, oldValue)
    }
    @Watch('paymentConditions.situation.value')
      situationChange(newValue, oldValue) {
        this.validationHandler('situation', newValue, oldValue)
    }

    //DATA
    start_work_date = '';
    visit_date = '';
    datePickerMenu = false;
    visitDatePickerMenu = false;
    toSaveStatusHistory = false;

    paymentConditions = {
      acompte: {
        value: 20,
        error: ''
      },
      demarage: {
        value: 20,
        error: ''
      },
      situation: {
        value: 60,
        error: ''
      }
    }
    hours_of_execution = 0;
    name = '';
    valid = false;
    custom_status: any = '';
    edit = false;
    statusHistory: any = {}

    quotaPdfSettingsFormData = {
      certificationsOwnPDF: [],
      assuranceOwnPDF: []
    } as IQuotaPdfSettings

    show_certificates = true
    show_assurances = true

    //HOOKS


    //COMPUTED
    get quota(): any {
        let quotaId = Number(this.$route.params.id)
        return readAdminOneQuota(this.$store)(quotaId);
    }
    get projects() {
        return this.$store.state.admin.projects
    }
    get customStatuses(): any {
        const quotasStatuses = this.$store.state.admin.quotasStatuses
        const quotasStatusesId = Object.keys(quotasStatuses)
        const formattedData = quotasStatusesId.map((statusId) => {
            return {
                edit: +statusId === 1 || +statusId === 2 ,
                id: +statusId,
                value: quotasStatuses[statusId]
            }
        });
        return formattedData
    }
    get currentDate() {
        return (new Date()).toISOString().split('T')[0]
    }
    get validationHistoryStatusDate() {
        const D = new Date();
        return ('0' + D.getDate()).slice(-2) + '-' + ('0' + (D.getMonth() + 1)).slice(-2) + '-' + D.getFullYear()
    }
    get messages(): any {
        return readMessages(this.$store);
    }

    get date() {
      return (d) => {
        return format(new Date(d), 'dd-MM-yyyy')
      }
    }
    get quotaPdfSettings() {
      return this.$store.state.admin.quotasPdfFiles
    }
    //METHODS
    validationHandler(modelName, newValue, oldValue) {
        const paymentConditionsValues: any = Object.keys(this.paymentConditions)
        const paymentConditions: any = this.paymentConditions
        const theSumOfAll = paymentConditionsValues.reduce(function(accum, current) {
            const value = paymentConditions[current].value
            return accum + +value
        }, 0)

        if (theSumOfAll > 100) {
            this.paymentConditions[modelName].error = 'The sum of all fields can not be bigger than 100%'
        } else {
            this.paymentConditions[modelName].error = ''
        }
    }
    async customStatusHandler(value) {
        this.toSaveStatusHistory = false
        this.statusHistory = {}
        if (this.customStatuses) {
            this.edit = this.customStatuses[value-1].edit
        }
        if(value === 3) {
            this.historySave(value, 3)
        }
        if(value === 5) {
            this.historySave(value, 5)
        }
    }
    async getQuotas() {
        if (this.quotaId){
            await dispatchGetQuotas(this.$store, this.projectId) 
        }
    }
    historySave(value, type) {
        this.toSaveStatusHistory = true
        let date = ''
        if (type === 3) {
            date = this.validationHistoryStatusDate
        }
        if (type === 5) {
            date = this.currentDate
        }
        const currentProject = this.projects.find(project => project.id === +this.projectId);
        this.statusHistory = {
            date: date,
            quota_id: this.quotaId,
            status_id: value,
            manager_id:  currentProject ? currentProject.manager_id : null,
        };
    }
    fillModel() {
        if (this.quota) {
            this.name = this.quota.name;
            this.edit = this.quota.edit;
            this.custom_status = this.quota.custom_status;
            this.hours_of_execution = this.quota.hours_of_execution;
            this.paymentConditions.acompte.value = this.quota.price_stage_1;
            this.paymentConditions.demarage.value = this.quota.price_stage_2;
            this.paymentConditions.situation.value = this.quota.price_stage_3;
            this.start_work_date = this.quota.start_work_date;
            this.visit_date = this.quota.visit_date;
            this.show_assurances = this.quota.show_assurances;
            this.show_certificates = this.quota.show_certificates;
        } else {
            this.custom_status = 1;
            this.edit = true
        }
    }
    reset() {
        this.edit = false;
        this.custom_status = ''
        this.name = '';
        this.show_assurances = true
        this.show_certificates = true
        this.$validator.reset();
    }
    cancel() {
        this.$router.back();
    }
    async mounted() {
        await this.getQuotas()
        await dispatchGetQuotasStatuses(this.$store)
        await dispatchGetProject(this.$store);
        await dispatchGetPdfCertifications(this.$store, this.quotaId);
        await dispatchGetPdfAssurance(this.$store, this.quotaId);

        this.quotaPdfSettingsFormData = {...this.$store.state.admin.quotasPdfFiles}
        this.fillModel()
    }
    async updateCertificationsOwnPDF(payload: File[]) {
      if (this.quotaPdfSettingsFormData.certificationsOwnPDF[0] instanceof File) {
        await dispatchUpdatePdfCertifications(this.$store, {quota_id: this.quotaId, data: payload})
      }
    }
    async deleteCertificationsOwnPDF() {
      await dispatchDeletePdfCertifications(this.$store, this.quotaId)
    }
    async updateAssuranceOwnPDF(payload: File[]) {
      if (this.quotaPdfSettingsFormData.assuranceOwnPDF[0] instanceof File) {
        await dispatchUpdatePdfAssurance(this.$store, {quota_id: this.quotaId, data: payload})
      }
    }
    async deleteAssuranceOwnPDF() {
      await dispatchDeletePdfAssurance(this.$store, this.quotaId)
    }
    
}
