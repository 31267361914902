/* eslint-disable */
//TODO NEED TO OPTIMISE ACTIONS LOGIC
import orderBy from "lodash.orderby";
import { api } from '@/api';
import { ActionContext } from 'vuex';
import { i18n } from '@/plugins/i18n'
import {
    IUserProfileCreate,
    IUserProfileUpdate,
    IProjectProfileCreate,
    IProjectProfileUpdate,
    IRoomProfileCreate,
    IRoomProfileUpdate,
    ICategoryProfile,
    ICategoryProfileCreate,
    ICategoryProfileUpdate,
    ISettings,
    IQuotaRoomServices,
    IQuotaRoomProducts,
    IRoomItem,
    IPlatformParametersCreateDto,
    IPlatformParametersUpdateDto,
    IEmailSettingsUpdateDto,
    IPdfContentUpdateDto,
    IPositionDto,
} from '@/interfaces';
import {
    commitSetUsers,
    commitSetUser,
    commitSetProjects,
    commitSetProject,
    // commitSetCategories,
    // commitSetCategory,
    commitSetRooms,
    commitSetRoom,
    commitSetQuotas,
    commitSetQuota,
    commitSetSettings,
    commitSetServiceCombination,
    commitSetServiceCombinations,
    commitSetService,
    commitSetServiceById,
    commitSetServices,
    commitSetServiceCategory,
    commitSetServiceCategories,
    commitSetProducts,
    commitSetProduct,
    commitSetRelatedProduct,
    commitSetServiceProduct,
    commitSetServiceProducts,
    commitSetServiceProductPrices,
    commitSetUnits,
    // commitSetCategoriesTree,
    commitSetQuotasStatuses,
    commitSetRoomsCategory,
    commitSetRelatedServiceProduct,
    // commitSetParentCategoriesTree,
    commitSetUnit,
    commitSetSuperUsers,
    commitSetAllQuotas,
    commitSetProjectHistory,
    commitSetServiceCombinationProducts,
    commitSetMessages,
    commitSetPdfQuotaDefaultLegalsInforamation,
    commitSetPdfQuotaDefaultAssurance,
    commitSetPdfQuotaCertifications,
    commitSetPdfQuotaAssurance,
    commitSetPdfQuotaDefaultCertifications,
    commitUpdateQuota,
    commitSetPlatformParameters,
    commitSetEmailSettings,
    commitSetPdfContent,
    commitSetServicesByCatId,
    // commitSetPreselectedCategoriesTree,
    commitSetAdminOneRoom
} from './mutations';
import { State } from '../state';
import { getStoreAccessors } from 'typesafe-vuex';
import { AdminState } from './state';
import { dispatchCheckApiError } from '../main/actions';
import { commitAddNotification, commitRemoveNotification } from '../main/mutations';
import { getName, itemAmount, itemPrice, priceFormat } from '@/utils/quotaSummary';
import { readUnitShortName } from "./getters";
import { clientImageFolder } from "@/env";
import { format } from "date-fns";

type MainContext = ActionContext<AdminState, State>;

export const actions = {
    //Actions user
    async actionGetUsers(context: MainContext, payload: any) {
        try {
            const response = await api.getUsers(context.rootState.main.token, payload.queryParams);
            if (response) {
                commitSetUsers(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetUser(context: MainContext, id: number) {
        if (!id) return;
        try {
            const response = await api.getUser(context.rootState.main.token, id);
            if (response) {
                commitSetUsers(context, [response.data]);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetSuperUsers(context: MainContext, payload: any) {
        try {
            const response = await api.getUsers(context.rootState.main.token, payload.queryParams);
            if (response) {
                commitSetSuperUsers(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateUser(context: MainContext, payload: { id: number, user: IUserProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateUser(context.rootState.main.token, payload.id, payload.user),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('userSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateUser(context: MainContext, payload: IUserProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUser(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUser(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('userSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },

    async actionDeleteUser(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteUser(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('userSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },

    //Actions project
    async actionGetProjects(context: MainContext) {
        try {
            const response = await api.getProjects(context.rootState.main.token);
            if (response) {
                commitSetProjects(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateProjects(context: MainContext, payload: IProjectProfileCreate) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createProject(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProject(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('projectSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateProject(context: MainContext, payload: { id: any, project: IProjectProfileUpdate }) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateProject(context.rootState.main.token, payload.id, payload.project),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProject(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('projectSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteProject(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteProject(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('projectSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },

    //Actions categories
    // async actionGetCategories(context: MainContext) {
    //     try {
    //         const response = await api.getCategories(context.rootState.main.token);
    //         if (response) {
    //             commitSetCategories(context, response.data);
    //         }
    //     } catch (error) {
    //         const { response } = error;
    //         commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //         await dispatchCheckApiError(context, error);
    //         return error
    //     }
    // },
    // async actionGetCategoriesTree(context: MainContext) {
    //     try {
    //         const response = await api.getCategoriesTree(context.rootState.main.token);
    //         if (response) {
    //             commitSetCategoriesTree(context, response.data);
    //         }
    //     } catch (error) {
    //         const { response } = error;
    //         commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //         await dispatchCheckApiError(context, error);
    //         return error
    //     }
    // },
    // async actionGetParentCategories(context: MainContext) {
    //     try {
    //         const response = await api.getParentCategories(context.rootState.main.token, 1);
    //         if (response) {
    //             commitSetParentCategoriesTree(context, response.data)
    //             return response.data;
    //         }
    //     } catch (error) {
    //         const { response } = error;
    //         commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //         await dispatchCheckApiError(context, error);
    //         return error
    //     }
    // },
    // async actionCreateCategory(context: MainContext, payload) {
    //     try {
    //         const loadingNotification = { content: i18n.t('saving'), showProgress: true };
    //         commitAddNotification(context, loadingNotification);
    //         const response = (await Promise.all([
    //             api.createCategory(context.rootState.main.token, payload),
    //             await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
    //         ]))[0];
    //         // commitSetCategory(context, response.data);
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: i18n.t('categorySuccessfullyCreated'), color: 'success' });
    //         return response.data
    //     } catch (error) {
    //         const { response } = error;
    //         if (response) {
    //             commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //             await dispatchCheckApiError(context, error);
    //             return error
    //         }
    //     }
    // },
    // async actionUpdateCategory(context: MainContext, payload) {
    //     const loadingNotification = { content: i18n.t('saving'), showProgress: true };
    //     commitAddNotification(context, loadingNotification);
    //     await api.updateCategory(context.rootState.main.token, payload.id, payload.formData).then(response => {
    //         commitSetCategory(context, response.data);
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: i18n.t('categorySuccessfullyUpdated'), color: 'success' });
    //     }).catch(async error => {
    //         const { response } = error;
    //         if (response) {
    //             commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //             await dispatchCheckApiError(context, error);
    //             return error
    //         } else {
    //             commitRemoveNotification(context, loadingNotification);
    //             commitAddNotification(context, { content: i18n.t('categorySuccessfullyUpdated'), color: 'success' });
    //         }
    //     });
    // },
    // async actionDeleteCategory(context: MainContext, payload) {
    //     try {
    //         const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
    //         commitAddNotification(context, loadingNotification);
    //         await api.deleteCategory(context.rootState.main.token, payload);
    //         commitRemoveNotification(context, loadingNotification);
    //         commitAddNotification(context, { content: i18n.t('categorySuccessfullyDeleted'), color: 'success' });
    //     } catch (error) {
    //         const { response } = error;
    //         if (response.status == 409) {
    //             commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
    //         }
    //         else {
    //             commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //             await dispatchCheckApiError(context, error);
    //         }
    //         return error
    //     }
    // },


    //Actions rooms
    async actionGetRooms(context: MainContext) {
        try {
            const response = await api.getRooms(context.rootState.main.token);
            if (response) {
                commitSetRooms(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetRoomById(context: MainContext, roomId: number) {
        try {
            const response = await api.getRoomById(context.rootState.main.token, roomId);
            if (response) {
                commitSetAdminOneRoom(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateRoom(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createRoom(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('roomSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateRoom(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateRoom(context.rootState.main.token, payload.id, payload.formData),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            await dispatchGetRooms(context);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('roomSuccessfullyUpdated'), color: 'success' });
            return response.data
        } catch (error) {
            await dispatchCheckApiError(context, error);
        }
    },
    async actionUpdateRoomPosition(context: MainContext, payload: IPositionDto[]) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.updateRoomsPosition(context.rootState.main.token, payload),
            dispatchGetRooms(context)
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('roomSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateRoomsCategory(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createRoomCategory(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetRoomsCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('roomCategorySuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetRoomsCategories(context: MainContext, serviceCategoryId) {
        try {
            const response = await api.getServiceCategories(context.rootState.main.token, serviceCategoryId);
            if (response) {
                commitSetServiceCategories(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // async actionGetRoomsPreselectedCategoriesTree(context: MainContext, roomId) {
    //     try {
    //         const response = await api.getroomPreselectedCategoryTree(context.rootState.main.token, roomId);
    //         if (response) {
    //             commitSetPreselectedCategoriesTree(context, response.data);
    //         }
    //     } catch (error) {
    //         const { response } = error;
    //         commitAddNotification(context, { content: response.data.detail, color: 'error' });
    //         await dispatchCheckApiError(context, error);
    //         return error
    //     }
    // },
    async actionDeleteRoom(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteRoom(context.rootState.main.token, payload);
            await dispatchGetRooms(context);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('roomSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },


    //Actions quotas
    async actionGetQuotas(context: MainContext, projectId) {
        try {
            const response = await api.getQuotasById(context.rootState.main.token, projectId);
            if (response) {
                commitSetQuotas(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetAllQuotas(context: MainContext) {
        try {
            const response = await api.getAllQuotas(context.rootState.main.token);
            if (response) {
                commitSetAllQuotas(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetQuotasStatuses(context: MainContext) {
        try {
            const response = await api.getQuotasStatuses(context.rootState.main.token);
            if (response) {
                commitSetQuotasStatuses(context, { ...response.data });
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateQuota(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createQuota(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetQuota(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('quotaSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteQuota(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteQuota(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('quotaSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    async actionUpdateQuota(context, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            const { quota_data } = payload
            if (payload.notification) { commitAddNotification(context, loadingNotification); }
            const promises: any[] = [
                api.updateQuota(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]

            if (quota_data.custom_status === 7) {
                promises.push(
                    await context.dispatch('actionCreateQuotaSummary', {
                        quota_id: quota_data?.id,
                        project_id: quota_data.project_id,
                        owner_id: quota_data?.owner_id
                    })
                )
            }
            const response: any = (await Promise.all(promises))[0];
            commitSetQuota(context, response.data);
            commitUpdateQuota(context, response.data)
            commitRemoveNotification(context, loadingNotification);
            if (payload.notification) { commitAddNotification(context, { content: i18n.t('quotaSuccessfullyUpdated'), color: 'success' }); }
        } catch (error) {
            console.log(error)
            commitAddNotification(context, { content: i18n.t('error'), color: 'error' });
            return error
        }
    },
    async actionGetSettings(context: MainContext) {
        try {
            const response = await api.getSettings(context.rootState.main.token);
            if (response) {
                commitSetSettings(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateSettings(context: MainContext, payload: ISettings) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateSettings(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetRoom(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('settingsSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },

    async actionSaveAccessToken(context: MainContext, data) {
        try {
            const response = await api.saveToken(context.rootState.main.token, data);
            if (response) {
                return response.data;
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    //Actions combinations
    async actionGetServiceCombinations(context: MainContext, serviceID) {
        try {
            const response = await api.getServiceCombinations(context.rootState.main.token, serviceID);
            if (response) {
                commitSetServiceCombinations(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateServiceCombination(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCombination(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetServiceCombination(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCombinationSuccessfullyCreated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateServiceCombinationProducts(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCombinationProducts(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetServiceCombinationProducts(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCombinationProductSuccessfullyCreated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteServiceCombinationProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.deleteServiceCombinationProduct(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCombinationProductSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateServiceCombination(context, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateServiceCombination(context.rootState.main.token, payload.id, payload.dataObject),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // commitSetServiceCombination(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('combinationSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteServiceCombination(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteServiceCombination(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCombinationSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: i18n.t('serviceCombinationErrorDeleted'), color: 'error' }); 
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    //Actions services
    async actionGetServices(context: MainContext, catId?: string) {
        try {
            const response = await api.getServices(context.rootState.main.token);
            if (response) {
                commitSetServices(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetServicesByCatId(context: MainContext, catId: string) {
        try {
            let params = {} as { category_id?: string }
            if (catId) {
                params.category_id = catId
            }
            const response = await api.getServices(context.rootState.main.token, params);
            if (response) {
                commitSetServicesByCatId(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetServiceCategories(context: MainContext, serviceCategoryId) {
        try {
            const response = await api.getServiceCategories(context.rootState.main.token, serviceCategoryId);
            if (response) {
                commitSetServiceCategories(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateService(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createService(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetService(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateService(context, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateService(context.rootState.main.token, payload.id, payload.serviceConfig),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetService(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateServiceCategory(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCategory(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetServiceCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCategorySuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionAssociateServices(context: MainContext, payload) {
        const { deleteService, createService } = payload
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            let promises:any[] = []
            promises.push(api.createServiceCategory(context.rootState.main.token, createService))
            if (deleteService) {
                promises.push(api.deleteServiceCategory(context.rootState.main.token, deleteService))
            }
            promises.push(await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)))
            const response = (await Promise.all(promises))[0];
            commitSetServiceCategory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceCategorySuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateServicePosition(context: MainContext, [catId, moveItemServiceCategories, dropItemServiceCategories]) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceCategory(context.rootState.main.token, moveItemServiceCategories),
                api.createServiceCategory(context.rootState.main.token, dropItemServiceCategories),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            await dispatchGetServicesByCatId(context, catId)
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('categorySuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteService(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteService(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    async actionGetServiceById(context: MainContext, serviceId) {
        try {
            const response = await api.getServiceById(context.rootState.main.token, serviceId);
            if (response) {
                commitSetServiceById(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    //ACTION PRODUCTS
    async actionGetProducts(context: MainContext) {
        try {
            const response = await api.getProducts(context.rootState.main.token);
            if (response) {
                commitSetProducts(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    //ACTION SERVICES PRODUCTS
    async actionCreateServiceProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceProduct(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetServiceProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceProductSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetServiceProducts(context: MainContext) {
        try {
            const response = await api.getServiceProducts(context.rootState.main.token);
            if (response) {
                commitSetServiceProducts(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateServiceProductPosition(context: MainContext, payload: IPositionDto[]) { 
        try {
            await api.updateServiceProductPosition(context.rootState.main.token, payload);
            dispatchGetServiceProducts(context)
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetRelatedServiceProductById(context: MainContext, payload) {
        try {
            const response = await api.getRelatedServiceProductById(context.rootState.main.token, payload);
            if (response) {
                commitSetRelatedServiceProduct(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteServiceProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteServiceProduct(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceProductSuccessfullyDeleted'), color: 'success' });
            return 'deleted'
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    //SERVICE PRODUCT PRICE
    async actionCreateServiceProductPrice(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createServiceProductPrice(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetRelatedProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('productPriceSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetServiceProductPrices(context: MainContext) {
        try {
            const response = await api.getServiceProductPrices(context.rootState.main.token);
            if (response) {
                commitSetServiceProductPrices(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteServiceProductPrice(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteServiceProductPrice(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceProductPriceSuccessfullyDeleted'), color: 'success' });
            return 'deleted'
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    async actionUpdateServiceProductPrice(context, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateServiceProductPrice(context.rootState.main.token, payload.id, payload.updatedProduct),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetRelatedProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('serviceProductPriceSuccessfullyUpdated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateProductServicePrice(context, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateProductServicePrice(context.rootState.main.token, payload.id,
                    {
                        price: payload.price,
                        service_id: payload.service_id,
                        product_id: payload.product_id,
                        service_combination_id: payload.service_combination_id
                    }
                ),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            // commitSetRelatedProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('productServicePriceSuccessfullyUpdated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    //Actions units
    async actionCreateUnit(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createUnit(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUnit(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('unitSuccessfullyCreated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateUnit(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response: any = (await Promise.all([
                api.updateUnit(context.rootState.main.token, payload.id, payload.data),
                await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetUnit(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('unitSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteUnit(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteUnit(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('unitSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    async actionGetUnits(context: MainContext) {
        try {
            const response = await api.getUnits(context.rootState.main.token);
            if (response) {
                commitSetUnits(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetUnitById(context: MainContext, unitId) {
        try {
            const response = await api.getUnitById(context.rootState.main.token, unitId);
            if (response) {
                return response.data
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    //Actions products
    async actionCreateProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createProduct(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('productSuccessfullyCreated'), color: 'success' });
            return response.data
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response: any = (await Promise.all([
                api.updateProduct(context.rootState.main.token, payload.id, payload.formData),
                await new Promise<void>((resolve, _) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProduct(context, response.data);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('productSuccessfullyUpdated'), color: 'success' });
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeleteProduct(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('deleting'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            await api.deleteProduct(context.rootState.main.token, payload);
            commitRemoveNotification(context, loadingNotification);
            commitAddNotification(context, { content: i18n.t('productSuccessfullyDeleted'), color: 'success' });
        } catch (error) {
            const { response } = error;
            if (response.status == 409) {
                commitAddNotification(context, { content: i18n.t('error409'), color: 'error' })
            }
            else {
                commitAddNotification(context, { content: response.data.detail, color: 'error' });
                await dispatchCheckApiError(context, error);
            }
            return error
        }
    },
    //ACTIONS HISTORY
    async actionCreateProjectHistory(context: MainContext, payload) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createProjectHistory(context.rootState.main.token, payload),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetProjectHistory(context, response.data);
            commitRemoveNotification(context, loadingNotification);
        } catch (error) {
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionGetProjectHistories(context: MainContext, payload) {
        try {
            const response = await api.getProjectHistories(context.rootState.main.token, payload);
            if (response) {
                commitSetProjectHistory(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },

    async actionGetMessages(context: MainContext, payload) {
        try {
            const response = await api.getMessages(context.rootState.main.token, payload);
            if (response) {
                commitSetMessages(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },

    // Default certifications
    async actionGetDefaultPdfCertifications(context: MainContext) {
        try {
            const response = await api.getQuotaDefaultCertifications(context.rootState.main.token);
            if (response) {
                commitSetPdfQuotaDefaultCertifications(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateDefaultPdfCertifications(context: MainContext, data: File[]) {
        try {
            const { quotaPdfSettings: { certificationsDefaultPDF } } = context.state
            const response = certificationsDefaultPDF && certificationsDefaultPDF.length > 0 ?
                await api.updateQuotaDefaultCertifications(context.rootState.main.token, data) :
                await api.createQuotaDefaultCertifications(context.rootState.main.token, data)
            if (response) {
                commitSetPdfQuotaCertifications(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // Certifications
    async actionGetPdfCertifications(context: MainContext, quota_id: number) {
        try {
            const response = await api.getQuotaCertifications(context.rootState.main.token, quota_id);
            if (response) {
                commitSetPdfQuotaCertifications(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdatePdfCertifications(context: MainContext, data: { quota_id: number, data: File[] }) {
        try {
            const { quotasPdfFiles: { certificationsOwnPDF } } = context.state
            const response = certificationsOwnPDF && certificationsOwnPDF.length > 0 ?
                await api.updateQuotaCertifications(context.rootState.main.token, data.quota_id, data.data) :
                await api.createQuotaCertifications(context.rootState.main.token, data.quota_id, data.data)
            if (response) {
                commitSetPdfQuotaCertifications(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeletePdfCertifications(context: MainContext, quota_id: number) {
        if (!context.state.quotasPdfFiles.certificationsOwnPDF) return
        try {
            const response = await api.deleteQuotaCertifications(context.rootState.main.token, quota_id)
            if (response) {
                commitSetPdfQuotaCertifications(context, []);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // Default assurance
    async actionGetDefaultPdfAssurance(context: MainContext) {
        try {
            const response = await api.getQuotaDefaultAssurance(context.rootState.main.token);
            if (response) {
                commitSetPdfQuotaDefaultAssurance(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateDefaultPdfAssurance(context: MainContext, data: File[]) {
        try {
            const { quotaPdfSettings: { assuranceDefaultPDF } } = context.state
            const response = assuranceDefaultPDF && assuranceDefaultPDF.length > 0 ?
                await api.updateQuotaDefaultAssurance(context.rootState.main.token, data) :
                await api.createQuotaDefaultAssurance(context.rootState.main.token, data)
            if (response) {

                commitSetPdfQuotaDefaultAssurance(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // Assurance
    async actionGetPdfAssurance(context: MainContext, quota_id: number) {
        try {
            const response = await api.getQuotaAssurance(context.rootState.main.token, quota_id);
            if (response) {
                commitSetPdfQuotaAssurance(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdatePdfAssurance(context: MainContext, data: { quota_id: number, data: File[] }) {
        try {
            const { quotasPdfFiles: { assuranceOwnPDF } } = context.state
            const response = assuranceOwnPDF && assuranceOwnPDF.length > 0 ?
                await api.updateQuotaAssurance(context.rootState.main.token, data.quota_id, data.data) :
                await api.createQuotaAssurance(context.rootState.main.token, data.quota_id, data.data)
            if (response) {
                commitSetPdfQuotaAssurance(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionDeletePdfAssurance(context: MainContext, quota_id: number) {
        if (!context.state.quotasPdfFiles.assuranceOwnPDF) return
        try {
            const response = await api.deleteQuotaAssurance(context.rootState.main.token, quota_id)
            if (response) {
                commitSetPdfQuotaAssurance(context, []);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateQuotaSummary(context: MainContext, data: { quota_id: number, project_id: number, owner_id: number }) {
        const { quota_id, project_id, owner_id } = data
        if (quota_id) {
            let quotaRooms: { id: number, name: string }[] = [];
            let quotaRoomsIds: number[] = [];
            let quotaRoomServices: IQuotaRoomServices[] = [];
            let quotaRoomCustomServices: IQuotaRoomServices[] = [];
            let quotaRoomProducts: IQuotaRoomProducts[] = [];
            let subcategories: { id: number, name: string, parentId: number, parentName: string }[] = []

            let fournitures: { name: string, priceWithTax: number | string, categories: unknown[], pricePdf: string }[] = [{ name: 'Fournitures', priceWithTax: 0, categories: [], pricePdf: '-' }]
            let HT = 0;
            let TTC = 0;
            let REMISE = 0;
            let items: any[] = [];

            let responseQuotaRooms = await api.getQuotaRooms(context.rootState.main.token, quota_id);
            quotaRooms = responseQuotaRooms.data
            quotaRoomsIds = quotaRooms.map(room => room.id)

            if (quotaRoomsIds.length > 0) {
                try {
                    let responseQuotaRoomServices = await api.getQuotaRoomServices(context.rootState.main.token, quotaRoomsIds);
                    quotaRoomServices = responseQuotaRoomServices.data
                    // quotaRoomServices = quotaRoomServices.filter(s => s.category_id !== 1)
                    quotaRoomCustomServices = quotaRoomServices.filter((s) => s.category_id === 1).map(s => ({ ...s, type: 'custom-service' }))
                    let responseQuotaRoomProducts = await api.getQuotaRoomProducts(context.rootState.main.token, quotaRoomsIds);
                    quotaRoomProducts = responseQuotaRoomProducts.data

                    let { data: responseCategories} = await api.getCategoriesTree(context.rootState.main.token)
                    let level1Tree = {};
                    for (const catKey in responseCategories) {
                        if (responseCategories[catKey].children) {
                            for (const childKey in responseCategories[catKey].children) {
                                level1Tree[childKey] = responseCategories[catKey].children[childKey];
                            }
                        }
                    }
                    let tree = Object.keys(level1Tree).map(catKey => {
                        let cat = { ...level1Tree[catKey] }
                        let subcat = cat.children ? Object.keys(cat.children).map(subCatKey => {
                            return {
                                ...cat.children[subCatKey]
                            }
                        }) : []
                        cat.children = subcat
                        return cat
                    });
                    tree?.filter((c) => c.children)
                        .forEach((cat) => {
                            let currentCat = { ...cat };
                            subcategories.push(
                                ...currentCat.children.map((c) => {
                                    return {
                                        ...c,
                                        parentName: cat.name,
                                        parentId: cat.id,
                                    };
                                })
                            );
                        });

                    items?.push(
                        ...quotaRoomServices.map((s) => {
                            let categoryCat = subcategories?.find((cat) => cat.id === s.category_id);
                            return {
                                ...s,
                                type: "service",
                                category_id: categoryCat?.id || null,
                                category_name: categoryCat?.name || null,
                                category_parent_id: categoryCat?.parentId || null,
                                category_parent_name: categoryCat?.parentName || null,
                            };
                        })
                    );

                    items.push(...quotaRoomProducts
                        ?.filter((product) => product.quota_room_id !== null && product.quota_room_service_id !== null)
                        .map((product) => {
                            let service = quotaRoomServices?.find(
                                (service) => service.id === product.quota_room_service_id
                            );

                            let categoryCat =
                                subcategories?.find(
                                    (cat) => cat.id === service?.category_id
                                ) || null;
                            return {
                                ...product,
                                type: product?.custom_product_id && !product?.service_product_id ? "custom-product" : "product",
                                category_id: categoryCat?.id || null,
                                category_name: categoryCat?.name || null,
                                category_parent_id: categoryCat?.parentId || null,
                                category_parent_name: categoryCat?.parentName || null,
                                amount: service?.amount || 0,
                            };
                        })
                    );

                    let list = quotaRooms?.map((room) => {
                        let roomItemOfProductList: any = {
                            roomName: room.name,
                            items: [],
                            priceWithTax: 0
                        };
                        let roomPriceWithTax = 0;
                        let customServicePriceWithTax = 0
                        let displayCustomServices = false;

                        let customServices = quotaRoomCustomServices
                            ?.filter((s) => s.quota_room_id === room.id)
                            .map((item) => {
                                displayCustomServices = true;
                                customServicePriceWithTax += item?.price_with_tax || 0;
                                roomPriceWithTax += item?.price_with_tax || 0;
                                return [
                                    {
                                        key: "name",
                                        value: getName(item),
                                    },
                                    {
                                        key: "price",
                                        value: priceFormat(itemPrice(item)) + " €",
                                    },
                                    {
                                        key: "amount",
                                        value: itemAmount(item)
                                    },
                                    {
                                        key: "unit",
                                        value: "u",
                                    },
                                    {
                                        key: "total",
                                        value: priceFormat(item?.price_with_tax) + " €",
                                    },
                                ];
                            })
                        let categories = new Set(
                            items
                                .filter((item) => item.quota_room_id === room.id && item.category_id !== null)
                                .map((item) => item.category_parent_id)
                        )

                        let roomItem: any = {
                            editIconAction: {
                                name: "RoomId",
                                params: {
                                    roomId: room.id,
                                },
                                query: {
                                    project_id: project_id,
                                    quota_id,
                                },
                            },
                            roomId: room.id,
                            name: room.name,
                            price: roomPriceWithTax,
                            customServices: {
                                name: "Prestations supplémentaires",
                                price: 0,
                                items: [...customServices],
                            },
                            categories: [...categories].map((key, index) => {
                                let priceWithTax = 0;
                                let categoryIndex = index + 1;
                                let subCat = items.filter((item) => {
                                    if (
                                        item.quota_room_id === room.id &&
                                        item.category_parent_id === key
                                    ) {
                                        priceWithTax += item?.discount_price || 0;
                                        roomPriceWithTax += item?.discount_price || 0;
                                        return true;
                                    }
                                });
                                return {
                                    categoryIndex: categoryIndex,
                                    category_id: key,
                                    name: !key ? null : subCat[0]?.category_parent_name,
                                    price: priceFormat(priceWithTax) + " €",
                                    pricePdf: priceWithTax > 0 ? priceFormat(priceWithTax) + " € TTC" : "-",
                                    subcategories: [
                                        ...new Set(subCat.map((item) => item.category_id)),
                                    ].map((key, index) => {
                                        let subcategoryIndex = `${categoryIndex}.${index + 1}`;
                                        let quota_room_service: any[] = [];
                                        let quota_room_products: any[] = [];
                                        let items: any[] = subCat.filter((item) => item.category_id === key)
                                        items.forEach((item) => {
                                                if (item.type === "service") {
                                                    quota_room_service.push({
                                                        ...item,
                                                        position: item?.position?.position || 0,
                                                    });
                                                }
                                                if (item.type === "product") {
                                                    quota_room_products.push(item);
                                                }
                                            });
                                        quota_room_service = orderBy(
                                            quota_room_service,
                                            ["position"],
                                            ["asc"]
                                        );
                                        quota_room_products.forEach((product) => {
                                            let quotaRoomServiceIndex = quota_room_service.findIndex(
                                                (service) => product.quota_room_service_id === service.id
                                            );
                                            if (quotaRoomServiceIndex || quotaRoomServiceIndex === 0) {
                                                quota_room_service?.splice(
                                                    quotaRoomServiceIndex + 1,
                                                    0,
                                                    product
                                                );
                                            }
                                        });
                                        items = [...quota_room_service];
                                        return {
                                            subcategoryIndex: subcategoryIndex,
                                            sub_category_id: key,
                                            name: !key ? null : items[0]?.category_name,
                                            items: items.map((item, index) => {
                                                HT += item?.discount_price_without_tax || 0;
                                                TTC += item?.discount_price || 0;
                                                REMISE += item?.price_with_tax - item?.discount_price || 0;

                                                if (item.type === "custom-product") {
                                                    roomItemOfProductList.items.push({
                                                        priceWithTax: priceFormat(item.price_with_tax),
                                                        pricePdf: item.price_with_tax > 0 ? priceFormat(item.price_with_tax) : "-",
                                                        ...item.custom_product,
                                                        client_price: priceFormat(item.custom_product?.client_price),
                                                        image: `${clientImageFolder}/custom-product.jpg`
                                                    })
                                                    roomItemOfProductList.priceWithTax += item?.price_with_tax || 0;
                                                    fournitures[0].priceWithTax += item?.price_with_tax || 0;
                                                }

                                                if (item.type === "product") {
                                                    roomItemOfProductList.items.push({
                                                        priceWithTax: priceFormat(item.price_with_tax),
                                                        pricePdf: item.price_with_tax > 0 ? priceFormat(item.price_with_tax) : "-",
                                                        ...item.product
                                                    })
                                                    roomItemOfProductList.priceWithTax += item?.price_with_tax || 0;
                                                    fournitures[0].priceWithTax += item?.price_with_tax || 0;
                                                }

                                                const distance = {
                                                    distanceTop: item.type === 'service' && index > 0,
                                                    distanceBottom: (item.type === 'product' || item.type === 'custom-product') && index === items.length - 1,
                                                    borderBottom: (item.type === 'product' || item.type === 'custom-product') || items[index+1]?.type === 'service' || index === items.length - 1
                                                }

                                                let hasDiscount = item.discount_fixed || item.discount_percentage
                                                let discount = '0'
                                                let discount_number = 0
                                                let discount_type = ''
                                                if (item?.discount_fixed) {
                                                    discount = `${priceFormat(item?.discount_fixed)}€`
                                                    discount_type = 'fix'
                                                    discount_number = item?.discount_fixed
                                                } else if (item?.discount_percentage) {
                                                    discount = `${priceFormat(item?.discount_percentage)}%`
                                                    discount_type = 'percent',
                                                        discount_number = item?.discount_percentage
                                                } else {
                                                    discount = ''
                                                }

                                                let discountPrice = !hasDiscount ? priceFormat(item?.discount_price) + " €" : `
                                                <div class="price-wrap">
                                                <span class="old-price">${priceFormat(item?.price_with_tax) + " €"}</span>
                                                <span class="discount-price">${priceFormat(item?.discount_price) + " €"}</span>
                                                </div>
                                            `

                                                let discountPriceValue =
                                                    !hasDiscount ?
                                                        {
                                                            type: "single",
                                                            data: priceFormat(item?.discount_price) + " €"
                                                        } :
                                                        {
                                                            type: "multiple",
                                                            data: {
                                                                old_price: `${priceFormat(item?.price_with_tax) + " €"}`,
                                                                discount_price: `${priceFormat(item?.discount_price) + " €"}`,
                                                                discount_type,
                                                                discount_number
                                                            }
                                                        };
                                                return [
                                                    {
                                                        key: "name",
                                                        value: getName(item),
                                                        ...distance
                                                    },
                                                    {
                                                        key: "price",
                                                        value: priceFormat(itemPrice(item)) + " €",
                                                        ...distance
                                                    },
                                                    {
                                                        key: "amount",
                                                        value: itemAmount(item),
                                                        ...distance
                                                    },
                                                    {
                                                        key: "unit",
                                                        value: `${readUnitShortName(context)(item?.service?.unit_id)}`,
                                                        ...distance
                                                    },
                                                    {
                                                        item,
                                                        key: "total",
                                                        value: discountPrice,
                                                        discountPriceValue: discountPriceValue,
                                                        ...distance
                                                    },
                                                ];
                                            }),
                                        };
                                    }),
                                };
                            })
                        }

                        roomItem.price = priceFormat(roomPriceWithTax) + " €";
                        roomItem.pricePdf = roomPriceWithTax > 0 ? priceFormat(roomPriceWithTax) + " € TTC" : '- € TTC';
                        roomItem.customServices.price = priceFormat(customServicePriceWithTax) + " €";

                        if (!displayCustomServices) {
                            delete roomItem.customServices;
                        }

                        fournitures[0].categories.push({
                            ...roomItemOfProductList,
                            priceWithTax: priceFormat(roomItemOfProductList.priceWithTax),
                            pricePdf: roomItemOfProductList.priceWithTax > 0 ? priceFormat(roomItemOfProductList.priceWithTax) + " € TTC" : "- € TTC"
                        })
                        return roomItem;
                    })

                    fournitures[0].pricePdf = fournitures[0].priceWithTax as number > 0 ? priceFormat(fournitures[0].priceWithTax) + " € TTC" : "- € TTC"
                    fournitures[0].priceWithTax = priceFormat(fournitures[0].priceWithTax)

                    const data = {
                        HT: priceFormat(HT),
                        REMISE: priceFormat(REMISE),
                        TTC: priceFormat(TTC),
                        TVA: priceFormat((TTC - HT)),
                        numberHT: HT,
                        numberREMISE: REMISE,
                        numberTTC: TTC,
                        numberTVA: TTC - HT,
                        list: list,
                        products: fournitures,
                        dateSigned: format(new Date(), "dd/MM/yyyy"),
                    }
                    
                    return api.createQuotaData(context.rootState.main.token, { quota_id, owner_id, data: `${JSON.stringify(data)}`})
                } catch(ex) {
                    console.log(ex)
                }
            }

        }

    },
    // Platform Parameters
    async actionGetPlatformParameters(context: MainContext) {
        try {
            const response = await api.getPlatformParameters();
            if (response) {
                commitSetPlatformParameters(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreatePlatformParameters(context: MainContext, data: IPlatformParametersCreateDto & {name: string}) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const {main_logo, logo, favicon} = data
            const response = (await Promise.all([
                api.createPlatformParameters(context.rootState.main.token, data.name, { main_logo, logo, favicon }),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPlatformParameters(context, response.data);
            commitRemoveNotification(context, loadingNotification);
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdatePlatformParameters(context: MainContext, data: IPlatformParametersUpdateDto & {name: string}) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updatePlatformParameters(context.rootState.main.token, data.name, data),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetPlatformParameters(context, response.data);
            commitRemoveNotification(context, loadingNotification);
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // Email settings
    async actionGetEmailSettings(context: MainContext) {
        try {
            const response = await api.getEmailSettings(context.rootState.main.token);
            if (response) {
                commitSetEmailSettings(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionCreateEmailSettings(context: MainContext, data: IEmailSettingsUpdateDto) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.createEmailSettings(context.rootState.main.token, data),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetEmailSettings(context, {...response.data, id: 1});
            commitRemoveNotification(context, loadingNotification);
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdateEmailSettings(context: MainContext, data: IEmailSettingsUpdateDto) {
        try {
            const loadingNotification = { content: i18n.t('saving'), showProgress: true };
            commitAddNotification(context, loadingNotification);
            const response = (await Promise.all([
                api.updateEmailSettings(context.rootState.main.token, data),
                await new Promise<void>((resolve, reject) => setTimeout(() => resolve(), 500)),
            ]))[0];
            commitSetEmailSettings(context, {...response.data});
            commitRemoveNotification(context, loadingNotification);
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    // Pdf logo
    async actionGetPdfContent(context: MainContext) {
        try {
            const response = await api.getPdfContent(context.rootState.main.token);
            if (response) {
                commitSetPdfContent(context, response.data);
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
    async actionUpdatePdfContent(context: MainContext, data: IPdfContentUpdateDto ) {
        try {
            const { pdfContent: { id } } = context.state
            const response = id !== null ?
                await api.updatePdfContent(context.rootState.main.token, data) :
                await api.createPdfContent(context.rootState.main.token, data)
            if (response) {
                // commitSetPdfLogo(context, {...response.data, id: 1});
            }
        } catch (error) {
            const { response } = error;
            commitAddNotification(context, { content: response.data.detail, color: 'error' });
            await dispatchCheckApiError(context, error);
            return error
        }
    },
};

const { dispatch } = getStoreAccessors<AdminState, State>('');

export const dispatchCreateUser = dispatch(actions.actionCreateUser);
export const dispatchGetUsers = dispatch(actions.actionGetUsers);
export const dispatchGetUser = dispatch(actions.actionGetUser);
export const dispatchGetSuperUsers = dispatch(actions.actionGetSuperUsers);
export const dispatchUpdateUser = dispatch(actions.actionUpdateUser);
export const dispatchDeleteUser = dispatch(actions.actionDeleteUser);

//exports project
export const dispatchCreateProject = dispatch(actions.actionCreateProjects);
export const dispatchGetProject = dispatch(actions.actionGetProjects);
export const dispatchUpdateProject = dispatch(actions.actionUpdateProject);
export const dispatchDeleteProject = dispatch(actions.actionDeleteProject);

//exports rooms
export const dispatchCreateRoom = dispatch(actions.actionCreateRoom);
export const dispatchGetRooms = dispatch(actions.actionGetRooms);
export const dispatchGetRoomById = dispatch(actions.actionGetRoomById);
export const dispatchUpdateRoom = dispatch(actions.actionUpdateRoom);
export const dispatchUpdateRoomPosition = dispatch(actions.actionUpdateRoomPosition);
export const dispatchCreateRoomCategory = dispatch(actions.actionCreateRoomsCategory);
export const dispatchDeleteRoom = dispatch(actions.actionDeleteRoom);
// exports units
export const dispatchCreateUnit = dispatch(actions.actionCreateUnit);
export const dispatchGetUnits = dispatch(actions.actionGetUnits);
export const dispatchUpdateUnit = dispatch(actions.actionUpdateUnit);
export const dispatchDeleteUnit = dispatch(actions.actionDeleteUnit);
export const dispatchGetUnitById = dispatch(actions.actionGetUnitById);
//exports categories
// export const dispatchCreateCategory = dispatch(actions.actionCreateCategory);
// export const dispatchGetCategories = dispatch(actions.actionGetCategories);
// export const dispatchGetCategoriesTree = dispatch(actions.actionGetCategoriesTree);
// export const dispatchGetRoomsPreselectedCategoriesTree = dispatch(actions.actionGetRoomsPreselectedCategoriesTree);
// export const dispatchGetParentCategories = dispatch(actions.actionGetParentCategories);
// export const dispatchUpdateCategory = dispatch(actions.actionUpdateCategory);
// export const dispatchDeleteCategory = dispatch(actions.actionDeleteCategory);

//exports quota
export const dispatchGetQuotas = dispatch(actions.actionGetQuotas);
export const dispatchGetAllQuotas = dispatch(actions.actionGetAllQuotas);
export const dispatchGetQuotasStatuses = dispatch(actions.actionGetQuotasStatuses);
export const dispatchCreateQuota = dispatch(actions.actionCreateQuota);
export const dispatchUpdateQuota = dispatch(actions.actionUpdateQuota);
export const dispatchDeleteQuota = dispatch(actions.actionDeleteQuota);
export const dispatchGetSettings = dispatch(actions.actionGetSettings)
export const dispatchUpdateSettings = dispatch(actions.actionUpdateSettings)
export const dispatchSaveAccessToken = dispatch(actions.actionSaveAccessToken)

//exports products
export const dispatchGetProducts = dispatch(actions.actionGetProducts);
export const dispatchCreateProduct = dispatch(actions.actionCreateProduct);
export const dispatchUpdateProduct = dispatch(actions.actionUpdateProduct);
export const dispatchDeleteProduct = dispatch(actions.actionDeleteProduct);
//exports combination
export const dispatchGetServiceCombinations = dispatch(actions.actionGetServiceCombinations);
export const dispatchCreateServiceCombination = dispatch(actions.actionCreateServiceCombination);
export const dispatchUpdateServiceCombination = dispatch(actions.actionUpdateServiceCombination);
export const dispatchDeleteServiceCombination = dispatch(actions.actionDeleteServiceCombination);



export const dispatchDeleteServiceCombinationProduct = dispatch(actions.actionDeleteServiceCombinationProduct);
export const dispatchCreateServiceCombinationProducts = dispatch(actions.actionCreateServiceCombinationProducts);
//exports services
export const dispatchGetServices = dispatch(actions.actionGetServices);
export const dispatchGetServicesByCatId = dispatch(actions.actionGetServicesByCatId);
export const dispatchGetServiceById = dispatch(actions.actionGetServiceById);
export const dispatchGetServiceCategories = dispatch(actions.actionGetServiceCategories);
export const dispatchCreateService = dispatch(actions.actionCreateService);
export const dispatchUpdateService = dispatch(actions.actionUpdateService);
export const dispatchCreateServiceCategory = dispatch(actions.actionCreateServiceCategory);
export const dispatchCreateAssociateServices = dispatch(actions.actionAssociateServices);
export const dispatchUpdateServicePosition = dispatch(actions.actionUpdateServicePosition);
export const dispatchDeleteService = dispatch(actions.actionDeleteService);
//exports products
export const dispatchCreateServiceProduct = dispatch(actions.actionCreateServiceProduct);
//exports related products
export const dispatchCreateServiceProductPrice = dispatch(actions.actionCreateServiceProductPrice);
export const dispatchGetServiceProducts = dispatch(actions.actionGetServiceProducts);
export const dispatchGetRelatedServiceProducts = dispatch(actions.actionGetRelatedServiceProductById);
export const dispatchGetServiceProductPrices = dispatch(actions.actionGetServiceProductPrices);
export const dispatchDeleteServiceProduct = dispatch(actions.actionDeleteServiceProduct);
export const dispatchDeleteServiceProductPrice = dispatch(actions.actionDeleteServiceProductPrice);
export const dispatchUpdateServiceProductPrice = dispatch(actions.actionUpdateServiceProductPrice);
export const dispatchUpdateServiceProductPosition = dispatch(actions.actionUpdateServiceProductPosition);
export const dispatchUpdateProductServicePrice = dispatch(actions.actionUpdateProductServicePrice);
//project history
export const dispatchCreateProjectHistory = dispatch(actions.actionCreateProjectHistory);
export const dispatchGetProjectHistories = dispatch(actions.actionGetProjectHistories);
// MESSAGES
export const dispatchGetMessages = dispatch(actions.actionGetMessages);
// PDF Settings default
export const dispatchGetDefaultPdfCertifications = dispatch(actions.actionGetDefaultPdfCertifications)
export const dispatchUpdateDefaultPdfCertifications = dispatch(actions.actionUpdateDefaultPdfCertifications)
export const dispatchGetDefaultPdfAssurance = dispatch(actions.actionGetDefaultPdfAssurance)
export const dispatchUpdateDefaultPdfAssurance = dispatch(actions.actionUpdateDefaultPdfAssurance)

// PDF Certifications
export const dispatchGetPdfCertifications = dispatch(actions.actionGetPdfCertifications)
export const dispatchUpdatePdfCertifications = dispatch(actions.actionUpdatePdfCertifications)
export const dispatchDeletePdfCertifications = dispatch(actions.actionDeletePdfCertifications)

// PDF Assurance
export const dispatchGetPdfAssurance = dispatch(actions.actionGetPdfAssurance)
export const dispatchUpdatePdfAssurance = dispatch(actions.actionUpdatePdfAssurance)
export const dispatchDeletePdfAssurance = dispatch(actions.actionDeletePdfAssurance)

// Quota summary
export const dispatchCreateQuotaSummary = dispatch(actions.actionCreateQuotaSummary)
// Platform Parameters
export const dispatchGetPlatformParameters = dispatch(actions.actionGetPlatformParameters)
export const dispatchCreatePlatformParameters = dispatch(actions.actionCreatePlatformParameters)
export const dispatchUpdatePlatformParameters = dispatch(actions.actionUpdatePlatformParameters)
// Email Settings
export const dispatchGetEmailSettings = dispatch(actions.actionGetEmailSettings)
export const dispatchCreateEmailSettings = dispatch(actions.actionCreateEmailSettings)
export const dispatchUpdateEmailSettings = dispatch(actions.actionUpdateEmailSettings)
// Pdf logo
export const dispatchGetPdfContent = dispatch(actions.actionGetPdfContent)
export const dispatchUpdatePdfContent = dispatch(actions.actionUpdatePdfContent)
