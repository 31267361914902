












    /* eslint-disable */
    import Vue from 'vue'
    import { Component } from 'vue-property-decorator'
    import {IQuotaProfileCreate} from '@/interfaces';
    import {
        dispatchUpdateQuota,
        dispatchGetQuotas,
        dispatchCreateProjectHistory,
        dispatchGetMessages
    } from '@/store/admin/actions';
    import {readAdminOneQuota, readIsQuotaReadOnly} from '@/store/admin/getters';
    import QuotaForm from "./QuotaForm.vue";
import { commitSetMessages } from '@/store/admin/mutations';

    @Component({
        components: {
            "quota-form": QuotaForm
        }
    })
    export default class EditQuota extends Vue {
        //COMPUTED
        get quotaId() {
            return +this.$route.params.id
        }
        get quota(): any {
            return readAdminOneQuota(this.$store)(this.quotaId);
        }
        get projectId() {
            return this.$route.params.project_id
        }
        get readOnly(): any {
            let quotaId = Number(this.$route.params.id)
            return readIsQuotaReadOnly(this.$store)(quotaId);
        }
        //METHODS
        async submit(values) {
            if (await this.$validator.validateAll()) {
                const updatedConfig: any = {
                    project_id: '',
                    edit: '',
                    name: '',
                    custom_status: '',
                    hours_of_execution: '',
                    price_stage_1: 0,
                    price_stage_2: 0,
                    price_stage_3: 0,
                    visit_date: '',
                };
                updatedConfig.id = this.quota.id,
                updatedConfig.owner_id = this.quota.owner_id,
                updatedConfig.name = values.name;
                updatedConfig.project_id = this.projectId;
                updatedConfig.edit = values.edit;
                updatedConfig.custom_status = values.custom_status;
                updatedConfig.hours_of_execution = values.hours_of_execution;
                updatedConfig.price_stage_1 = values.paymentConditions.acompte.value;
                updatedConfig.price_stage_2 = values.paymentConditions.demarage.value;
                updatedConfig.price_stage_3 = values.paymentConditions.situation.value;
                updatedConfig.start_work_date = values.start_work_date;
                updatedConfig.visit_date = values.visit_date;
                updatedConfig.show_assurances = values.show_assurances;
                updatedConfig.show_certificates = values.show_certificates;
                await dispatchUpdateQuota(this.$store, {quota_id: this.quotaId, quota_data: updatedConfig, notification: true});
                if (values.statusHistory) {await dispatchCreateProjectHistory(this.$store, values.statusHistory);}
                if (!values.disabledRedirect) {
                    await this.$router.push('/main/admin/quotas/list');
                }
            }
        }
        async mounted() {
            await dispatchGetQuotas(this.$store, this.projectId)
            await dispatchGetMessages(this.$store, {quota_id: this.quotaId})
        }
        beforeDestroy() {
            commitSetMessages(this.$store, [])
        }
    }

