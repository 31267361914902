

































































/* eslint-disable */
import {Vue, Prop, Emit, Component} from 'vue-property-decorator'

@Component
export default class TableExpandable extends Vue {
    //PROPS
    @Prop(Array) readonly headers : Array<object> | undefined;
    @Prop(Array) readonly items : Array<object> | undefined;

    //EMIT
    @Emit()deleteEvent(item) {
        return item
    }
    @Emit()editEvent(item) {
        return item
    }
    @Emit()popupEvent(item) {
      return item
    }

    //DATA
    expanded = [];
    singleExpand = true;
    valid = false;
    status = false;

    //METHODS
    formatChildList(item) {
        if (item) {
            return item.children ? Object.values(item.children) : []
        } else {
            return []
        }
    }
    // handleClickRow(item) {
    //     debugger
    // }

}
