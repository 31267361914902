var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('manageProducts'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/products/create"}},[_vm._v(_vm._s(_vm.$t('createProduct')))])],1),(true)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.products,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleEditButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.currentItem = item; _vm.dialog = true}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}],null,false,3394078858)}):[_c('div',{staticClass:"text-center"},[_c('v-progress-circular',{attrs:{"size":40,"width":5,"color":"primary","indeterminate":""}})],1)],_c('ConfirmDialog',{attrs:{"show":_vm.dialog},on:{"closeModal":function($event){_vm.dialog = false; _vm.currentItem = null},"confirmModalAgree":function($event){return _vm.deleteProduct(_vm.currentItem)}}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }