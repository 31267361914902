/* eslint-disable */
import axios from 'axios';
import { apiUrl } from '@/env';
import {
  IUserProfile,
  IUserProfileUpdate,
  IUserProfileCreate,
  IProjectProfileCreate,
  IProjectProfile,
  IRoomProfile,
  IRoomProfileCreate,
  IRoomProfileUpdate,
  ICategoryProfile,
  ICategoryProfileCreate,
  ICategoryProfileUpdate,
  ISettings,
  IPlatformParametersCreateDto,
  IPlatformParametersUpdateDto,
  IEmailSettingsUpdateDto,
  IPdfContentUpdateDto,
  IServiceCategory,
  IPositionDto,
} from './interfaces';
// TODO NEED TO OPTIMISE LOGIC IN THIS COMPONENT (add interceptor, token, rewrite methods etc.)
function authHeaders(token: string) {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
}

export const api = {
  async logInGetToken(username: string, password: string) {
    const params = new URLSearchParams();
    params.append('username', username);
    params.append('password', password);

    return axios.post(`${apiUrl}/api/v1/login/access-token`, params);
  },
  async getMe(token: string) {
    return axios.get<IUserProfile>(`${apiUrl}/api/v1/users/me`, authHeaders(token));
  },
  async updateMe(token: string, data: IUserProfileUpdate) {
    return axios.put<IUserProfile>(`${apiUrl}/api/v1/users/me`, data, authHeaders(token));
  },
  async getUsers(token: string, queryParams?) {

    return axios.get(`${apiUrl}/api/v1/users/${queryParams}`, authHeaders(token));
  },
  async getUser(token: string, userId: number) {
    return axios.get(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async updateUser(token: string, userId: number, data: IUserProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/users/${userId}`, data, authHeaders(token));
  },
  async createUser(token: string, data: IUserProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/users/`, data, authHeaders(token));
  },
  async deleteUser(token: string, userId: number) {
    return axios.delete(`${apiUrl}/api/v1/users/${userId}`, authHeaders(token));
  },
  async passwordRecovery(email: string) {
    return axios.post(`${apiUrl}/api/v1/password-recovery/${email}`);
  },
  async resetPassword(password: string, token: string) {
    return axios.post(`${apiUrl}/api/v1/reset-password/`, {
      new_password: password,
      token,
    });
  },

  //PROJECTS API
  async getProjectById(token: string, projectId: any) {
    return axios.get<IProjectProfile[]>(`${apiUrl}/api/v1/projects/${projectId}`, authHeaders(token));
  },
  async getProjects(token: string) {
    return axios.get<IProjectProfile[]>(`${apiUrl}/api/v1/projects/?skip=0&limit=1000`, authHeaders(token));
  },
  async createProject(token: string, data: IProjectProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/projects/`, data, authHeaders(token));
  },
  async updateProject(token: string, projectId: any, data: IProjectProfileCreate) {
    return axios.put(`${apiUrl}/api/v1/projects/${projectId}`, data, authHeaders(token));
  },
  async deleteProject(token: string, projectId: any) {
    return axios.delete(`${apiUrl}/api/v1/projects/${projectId}`, authHeaders(token));
  },
  //ROOMS API
  async getRooms(token: string) {
    return axios.get<IRoomProfile[]>(`${apiUrl}/api/v1/rooms/`, authHeaders(token));
  },
  async getRoomById(token: string, roomId: number) {
    return axios.get<IRoomProfile>(`${apiUrl}/api/v1/rooms/${roomId}`, authHeaders(token));
  },
  async updateRoomsPosition(token: string, data: IPositionDto[]) {
    return axios.put(`${apiUrl}/api/v1/rooms/`, data, authHeaders(token));
  },
  async createRoom(token: string, data: IRoomProfileCreate) {
    return axios.post(`${apiUrl}/api/v1/rooms/`, data, authHeaders(token));
  },
  async updateRoom(token: string, roomId: any, data: IRoomProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/rooms/${roomId}`, data, authHeaders(token));
  },
  async updateRoomPosition(token: string, roomId: any, position: string) {
    let formData = new FormData()
    formData.append('position', position);
    return axios.put(`${apiUrl}/api/v1/rooms/${roomId}`, formData, authHeaders(token));
  },
  async deleteRoom(token: string, roomId: any) {
    return axios.delete(`${apiUrl}/api/v1/rooms/${roomId}`, authHeaders(token));
  },
  //QUOTAS API
  async getQuotasStatuses(token: string) {
    return axios.get(`${apiUrl}/api/v1/quotas/statuses`, authHeaders(token));
  },
  async getQuotasById(token: string, projectId) {
    return axios.get(`${apiUrl}/api/v1/quotas/?project_id=${projectId}`, authHeaders(token));
  },
  async getAllQuotas(token: string) {
    return axios.get(`${apiUrl}/api/v1/quotas/`, authHeaders(token));
  },
  async createQuota(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/quotas/`, data, authHeaders(token));
  },
  async updateQuota(token: string, data) {
    return axios.put(`${apiUrl}/api/v1/quotas/${data.quota_id}`, data.quota_data, authHeaders(token));
  },
  async deleteQuota(token: string, quotaId: any) {
    return axios.delete(`${apiUrl}/api/v1/quotas/${quotaId}`, authHeaders(token));
  },

  //Categories API
  async getParentCategories(token: string, parent_id: number) {
    return axios.get<ICategoryProfile[]>(`${apiUrl}/api/v1/categories/parent/${parent_id}`, authHeaders(token));
  },
  async getCategoriesTree(token: string) {
    return axios.get(`${apiUrl}/api/v1/categories/tree`, authHeaders(token));
  },
  async getCategoryById(token: string, categoryId) {
    return axios.get<ICategoryProfile>(`${apiUrl}/api/v1/categories/${categoryId}`, authHeaders(token));
  },
  async getCategories(token: string) {
    return axios.get<ICategoryProfile[]>(`${apiUrl}/api/v1/categories/tree`, authHeaders(token));
  },
  async createCategory(token: string, data: ICategoryProfileUpdate) {
    return axios.post(`${apiUrl}/api/v1/categories/`, data, authHeaders(token));
  },
  async updateCategory(token: string, categoryId: any, data: ICategoryProfileUpdate) {
    return axios.put(`${apiUrl}/api/v1/categories/${categoryId}`, data, authHeaders(token));
  },
  async deleteCategory(token: string, categoryId: any) {
    return axios.delete(`${apiUrl}/api/v1/categories/${categoryId}`, authHeaders(token));
  },

  async getSettings(token: string) {
    return axios.get<ISettings[]>(`${apiUrl}/api/v1/settings/zoho`, authHeaders(token));
  },
  async updateSettings(token: string, data: ISettings) {
    return axios.put(`${apiUrl}/api/v1/settings/zoho`, data, authHeaders(token));
  },
  async saveToken(token: string, data: string){
    return axios.post<ISettings>(`${apiUrl}/api/v1/settings/access_token/zoho`, data, authHeaders(token));
  },

  //Products API
  async getProducts(token: string) {
    return axios.get(`${apiUrl}/api/v1/products/`, authHeaders(token));
  },
  async getProductById(token: string, productId) {
    return axios.get(`${apiUrl}/api/v1/products/${productId}`, authHeaders(token));
  },
  async createProduct(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/products/`, data, authHeaders(token));
  },
  async updateProduct(token: string, productId: any, data) {
    return axios.put(`${apiUrl}/api/v1/products/${productId}`, data, authHeaders(token));
  },
  async deleteProduct(token: string, productId: any) {
    return axios.delete(`${apiUrl}/api/v1/products/${productId}`, authHeaders(token));
  },
  //Combinations API
  async getServiceCombinations(token: string, serviceID) {
    return axios.get(`${apiUrl}/api/v1/service_combinations/?&service_id=${serviceID}`, authHeaders(token));
  },
  async createServiceCombination(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/service_combinations/`, data, authHeaders(token));
  },
  async updateServiceCombination(token: string, combinationId, data) {
    return axios.put(`${apiUrl}/api/v1/service_combinations/${combinationId}`, data, authHeaders(token));
  },
  async deleteServiceCombination(token: string, combinationId: any) {
    return axios.delete(`${apiUrl}/api/v1/service_combinations/${combinationId}`, authHeaders(token));
  },
  async createServiceCombinationProducts(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/service_combination_products/`, data, authHeaders(token));
  },
  async deleteServiceCombinationProduct(token: string, data) {
    return axios.delete(`${apiUrl}/api/v1/service_combination_products/${data.service_combination_id}?service_product_id=${data.service_product_id}`, authHeaders(token));
  },
  //Service API
  async getServices(token: string, params = {}) {
    return axios.get(`${apiUrl}/api/v1/services/`, {...authHeaders(token), params});
  },
  async getServiceById(token: string, serviceId) {
    return axios.get(`${apiUrl}/api/v1/services/${serviceId}`, authHeaders(token));
  },
  async createService(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/services/`, data, authHeaders(token));
  },
  async deleteService(token: string, serviceId: any) {
    return axios.delete(`${apiUrl}/api/v1/services/${serviceId}`, authHeaders(token));
  },
  async createServiceCategory(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/service_categories/`, data, authHeaders(token));
  },
  async deleteServiceCategory(token: string, data: IServiceCategory) {
    return axios.delete(`${apiUrl}/api/v1/service_categories/`, {...authHeaders(token), data});
  },
  async updateService(token: string, serviceId: any, data) {
    return axios.put(`${apiUrl}/api/v1/services/${serviceId}`, data, authHeaders(token));
  },
  async getServiceCategories(token: string, serviceId) {
    return axios.get(`${apiUrl}/api/v1/service_categories/${serviceId}`, authHeaders(token));
  },
  //Related products
  async createServiceProductPrice(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/service_product_prices/`, data, authHeaders(token));
  },
  //service products
  async createServiceProduct(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/service_products/`, data, authHeaders(token));
  },
  async updateServiceProductPosition(token: string, data: IPositionDto[]) {
    return axios.put(`${apiUrl}/api/v1/service_products/`, data, authHeaders(token));
  },
  async getServiceProducts(token: string) {
    return axios.get(`${apiUrl}/api/v1/service_products/`, authHeaders(token));
  },
  async getRelatedServiceProductById(token: string, payload) {
    return axios.get(`${apiUrl}/api/v1/service_products/?skip=${payload.skip}&limit=${payload.limit}&service_id=${payload.id}`, authHeaders(token));
  },
  async getServiceProductPrices(token: string) {
    return axios.get(`${apiUrl}/api/v1/service_product_prices/`, authHeaders(token));
  },
  async deleteServiceProduct(token: string, serviceProductId: any) {
    return axios.delete(`${apiUrl}/api/v1/service_products/${serviceProductId}`, authHeaders(token));
  },
  async deleteServiceProductPrice(token: string, serviceProductPriceId: any) {
    return axios.delete(`${apiUrl}/api/v1/service_product_prices/${serviceProductPriceId}`, authHeaders(token));
  },
  async updateServiceProductPrice(token: string, serviceProductPriceId: any, data) {
    return axios.put(`${apiUrl}/api/v1/service_product_prices/${serviceProductPriceId}`, data, authHeaders(token));
  },
  async updateProductServicePrice(token: string, serviceProductId: any, data) {
    return axios.put(`${apiUrl}/api/v1/service_products/${serviceProductId}`, data, authHeaders(token));
  },
  //units
  async getUnits(token: string) {
    return axios.get(`${apiUrl}/api/v1/units/?skip=0&limit=100`, authHeaders(token));
  },
  async createUnit(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/units/`, data, authHeaders(token));
  },
  async updateUnit(token: string, unitId: any, data) {
    return axios.put(`${apiUrl}/api/v1/units/${unitId}`, data, authHeaders(token));
  },
  async getUnitById(token: string, unitId) {
    return axios.get(`${apiUrl}/api/v1/units/${unitId}`, authHeaders(token));
  },
  async deleteUnit(token: string, unitId: any) {
    return axios.delete(`${apiUrl}/api/v1/units/${unitId}`, authHeaders(token));
  },
  // rooms
  async createRoomCategory(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/room_categories/`, data, authHeaders(token));
  },
  async getroomPreselectedCategoryTree(token: string, roomId: number) {
    return axios.get(`${apiUrl}/api/v1/room_categories/tree/${roomId}?preselected=true`, authHeaders(token));
  },
  //HISTORIES
  async createProjectHistory(token: string, data) {
    return axios.post(`${apiUrl}/api/v1/project_histories/`, data, authHeaders(token));
  },
  async getProjectHistories(token: string, params) {
    return axios.get(`${apiUrl}/api/v1/project_histories/?quota_id=${params.quota_id}&status_id=${params.status_id}`, authHeaders(token));
  },
  async getMessages(token: string, params) {
    return axios.get(`${apiUrl}/api/v1/messages/?quota_id=${params.quota_id}`, authHeaders(token));
  },
  // Quota legal information
  async getLegalInformations(token: string, ) {
    return axios.get(`${apiUrl}/api/v1/quota_legal_information/`, authHeaders(token));
  },
  async createLegalInformation(token: string, data: string) {
    return axios.post(`${apiUrl}/api/v1/quota_legal_information/`, {description: data}, authHeaders(token));
  },
  async updateLegalInformation(token: string, legalInformationId: number, data: string ) {
    return axios.put(`${apiUrl}/api/v1/quota_legal_information/${legalInformationId}`, {description: data}, authHeaders(token));
  },
  // Quota certification
  async getQuotaCertifications(token: string, quotaId: number) {
    return axios.get(`${apiUrl}/api/v1/quota_certifications/?quota_id=${quotaId}`, authHeaders(token));
  },
  async createQuotaCertifications(token: string, quotaId: number, data: File[]) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.post(`${apiUrl}/api/v1/quota_certifications/?quota_id=${quotaId}`, formData, authHeaders(token));
  },
  async updateQuotaCertifications(token: string, quotaId: number, data: File[] ) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.put(`${apiUrl}/api/v1/quota_certifications/${quotaId}`, formData, authHeaders(token));
  },
  async deleteQuotaCertifications(token: string, quotaId: number ) {
    return axios.delete(`${apiUrl}/api/v1/quota_certifications/${quotaId}`, authHeaders(token));
  },
  // Quota default certification
  async getQuotaDefaultCertifications(token: string) {
    return axios.get(`${apiUrl}/api/v1/quota_default_certifications/`, authHeaders(token));
  },
  async createQuotaDefaultCertifications(token: string, data: File[]) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.post(`${apiUrl}/api/v1/quota_default_certifications/`, formData, authHeaders(token));
  },
  async updateQuotaDefaultCertifications(token: string, data: File[] ) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.put(`${apiUrl}/api/v1/quota_default_certifications/`, formData, authHeaders(token));
  },
  // Default assurance
  async getQuotaDefaultAssurance(token: string) {
    return axios.get(`${apiUrl}/api/v1/quota_default_assurances/`, authHeaders(token));
  },
  async createQuotaDefaultAssurance(token: string, data: File[]) {
    let formData = new FormData()
    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.post(`${apiUrl}/api/v1/quota_default_assurances/`, formData, authHeaders(token));
  },
  async updateQuotaDefaultAssurance(token: string, data: File[] ) {
    let formData = new FormData()
    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.put(`${apiUrl}/api/v1/quota_default_assurances/`, formData, authHeaders(token));
  },
  // Quota assurance
  async getQuotaAssurance(token: string, quotaId: number) {
    return axios.get(`${apiUrl}/api/v1/quota_assurances/?quota_id=${quotaId}`, authHeaders(token));
  },
  async createQuotaAssurance(token: string, quotaId: number, data: File[]) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.post(`${apiUrl}/api/v1/quota_assurances/?quota_id=${quotaId}`, formData, authHeaders(token));
  },
  async updateQuotaAssurance(token: string, quotaId: number, data: File[] ) {
    let formData = new FormData()

    for (let file of data) {
      formData.append("files", file, file.name);
    }
    return axios.put(`${apiUrl}/api/v1/quota_assurances/${quotaId}`, {description: data}, authHeaders(token));
  },
  async deleteQuotaAssurance(token: string, quotaId: number) {
    return axios.delete(`${apiUrl}/api/v1/quota_assurances/${quotaId}`, authHeaders(token));
  },
  // Quota rooms
  async getQuotaRooms(token: string, quotaId: number) {
    return axios.get(`${apiUrl}/api/v1/quota_rooms/?quota_id=${quotaId}`, authHeaders(token));
  },
  // Quota room services
  async getQuotaRoomServices(token: string, quota_room_id: number | number[]) {
    let params = Array.isArray(quota_room_id) ? quota_room_id?.join(',') : quota_room_id
    return axios.get(`${apiUrl}/api/v1/quota_room_service/?quota_room_id=${params}`, authHeaders(token));
  },
  // Quota room products
  async getQuotaRoomProducts(token: string, quota_room_id: number | number[]) {
    let params = Array.isArray(quota_room_id) ? quota_room_id?.join(',') : quota_room_id
    return axios.get(`${apiUrl}/api/v1/quota_room_products/?quota_room_id=${params}`, authHeaders(token));
  },
  // Quota data
  async getQuotaData(token: string, quotaId: number) {
    return axios.get(`${apiUrl}/api/v1/quota_data/?quota_id=${quotaId}`, authHeaders(token));
  },
  async createQuotaData(token: string, data: {quota_id: number, owner_id: number, data: string}) {
    return axios.post(`${apiUrl}/api/v1/quota_data/`, data, authHeaders(token));
  },
  // platform_parameters
  async getPlatformParameters() {
    return axios.get(`${apiUrl}/api/v1/platform_parameters/`);
  },
  async createPlatformParameters(token: string, companyName: string, data: IPlatformParametersCreateDto) {
    let formData = new FormData()
    formData.append("main_logo", data.main_logo, data.main_logo.name);
    formData.append("logo", data.logo, data.logo.name);
    formData.append("favicon", data.favicon, data.favicon.name);
    
    return axios.post(`${apiUrl}/api/v1/platform_parameters/?name=${companyName}`, formData, authHeaders(token));
  },
  async updatePlatformParameters(token: string, companyName: string, data: IPlatformParametersUpdateDto) {
    let formData: FormData | null = new FormData()
    if (data.main_logo) {
      formData.append("main_logo", data.main_logo, data.main_logo.name);
    }
    if (data.logo) {
      formData.append("logo", data.logo, data.logo.name);
    }
    if (data.favicon) {
      formData.append("favicon", data.favicon, data.favicon.name);
    }
    if (!data.main_logo && !data.logo && !data.favicon) {
      formData = null
    }
    return axios.put(`${apiUrl}/api/v1/platform_parameters/?name=${companyName}`, formData, authHeaders(token));
  },
  // Email settings
  async getEmailSettings(token: string) {
    return axios.get(`${apiUrl}/api/v1/email_settings/`, authHeaders(token));
  },
  async createEmailSettings(token: string, data: IEmailSettingsUpdateDto) {
    let formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key]);
    }
    
    return axios.post(`${apiUrl}/api/v1/email_settings/`, formData, authHeaders(token));
  },
  async updateEmailSettings(token: string, data: IEmailSettingsUpdateDto) {
    let formData = new FormData()
    for (let key in data) {
      if (data[key]) {
        formData.append(key, data[key]);
      }
    }
    
    return axios.put(`${apiUrl}/api/v1/email_settings/`, formData, authHeaders(token));
  },
  // Pdf content
  async getPdfContent(token: string) {
    return axios.get(`${apiUrl}/api/v1/pdf_content/`, authHeaders(token));
  },
  async createPdfContent(token: string, data: IPdfContentUpdateDto) {
    let formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return axios.post(`${apiUrl}/api/v1/pdf_content/`, formData, authHeaders(token));
  },
  async updatePdfContent(token: string, data: IPdfContentUpdateDto) {
    let formData = new FormData()
    for (let key in data) {
      formData.append(key, data[key]);
    }
    return axios.put(`${apiUrl}/api/v1/pdf_content/`, formData, authHeaders(token));
  },
};