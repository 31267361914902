














































































/* eslint-disable */
import {Vue, Component, Prop, Emit} from 'vue-property-decorator'
import {readAdminOneProduct} from "@/store/admin/getters";
import {dispatchGetProducts} from "@/store/admin/actions";

@Component
export default class FormProduct extends Vue {
    //PROPS
    @Prop(Boolean) readonly readOnly : boolean | undefined;
    @Prop(String) readonly title : string | undefined;

    //EMIT
    @Emit()
    submit(disabledRedirect) {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            return {
                id: +this.$route.params.id,
                name: this.name,
                image: this.image,
                price: this.price,
                reference: this.reference ? this.reference : '',
                description: this.description,
                short_description: this.short_description,
                disabledRedirect: disabledRedirect
            }
        } else {
            return null
        }
    }

    //DATA

    valid = false;
    wysiwygOptions = {
        // debug: 'info',
        modules: {
            toolbar: [
                ['bold', 'italic', 'underline'],        // toggled buttons
                // ['blockquote', 'code-block'],

                // [{ 'header': 1 }, { 'header': 2 }],               // custom button values
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                // [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                // [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                // [{ 'direction': 'rtl' }],                         // text direction
                //
                // [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
                // [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                //
                // [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                // [{ 'font': [] }],
                // [{ 'align': [] }],

                ['clean']                                         // remove formatting button
            ]
        },
        placeholder: this.$t('description'),
        readOnly: true,
        theme: 'snow'
    }
    name = '';
    reference = '';
    description = '';
    short_description = '';
    image = '';
    price = '';
    selectedImg = null;
    imageSrc: any = null;
    ruleRequired = [
        value => !!value || `${this.$t('required')}.`
    ];

    //COMPUTED
    get product() {
        return readAdminOneProduct(this.$store)(+this.$route.params.id);
    }
    get imageToShow() {
        if (this.imageSrc) {
            return this.imageSrc
        }else {
            return this.selectedImg
        }
    }
    //METHODS
    addImage(event) {
        this.image = event
        if (event) {
            let reader = new FileReader();
            let vm = this;

            reader.onload = function(e) {
                vm.imageSrc = e?.target?.result;
            };

            reader.readAsDataURL(event);
        }
    }
    reset() {
        this.name = ''
        this.description = ''
        this.image = ''
        this.price = ''
        this.reference = ''
    }
    cancel() {
        this.$router.back();
    }
    fillModel() {
        if (this.product) {
            this.name = this.product.name
            this.description = this.product.description
            this.short_description = this.product.short_description
            this.price = this.product.price
            this.selectedImg = this.product.image
            this.reference = this.product.reference
        }
    }
    //HOOKS
    async mounted() {
        await dispatchGetProducts(this.$store);
        this.fillModel()
    }
}
