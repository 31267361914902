















































/* eslint-disable */
import { Vue, Component, Mixins } from 'vue-property-decorator'
import { CreateCategory as CreateCategoryMixin } from './mixins'
import { dispatchCreateCategory } from '@/store/categories/actions'
import { readCategoryTreeForTypeOfWorkPage } from '@/store/categories/getters';

@Component
export default class CreateTypeOfWork extends Mixins(CreateCategoryMixin) {
    get categories() {
        return readCategoryTreeForTypeOfWorkPage(this.$store)
    }

    async submit(disabledRedirect) {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            const formData = new FormData()

            const parentId = '1';
            formData.append('name', this.name);
            formData.append('image', this.image);
            formData.append('parent_id', parentId);
            formData.append('description', this.description);

            const category: any = await dispatchCreateCategory(this.$store, formData);
            if (!disabledRedirect) {
                await this.$router.push('/main/admin/type-of-work/all');
            }
            if (category?.id && disabledRedirect) {
                await this.$router.replace(`/main/admin/type-of-work/edit/${category.id}`);
            }
        }
    }
}
