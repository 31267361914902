var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pl-5 pr-5 pb-5",attrs:{"justify":"end"}},[[_c('div',{staticClass:"text-center"},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""},on:{"click":_vm.handleCreateCombination}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('createCombination'))+" ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-10"},[_c('v-text-field',{attrs:{"type":"text","label":_vm.$t('name'),"required":"","outlined":""},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{attrs:{"type":"number","prepend-inner-icon":"mdi-currency-eur","label":_vm.$t('price'),"required":"","outlined":""},model:{value:(_vm.price),callback:function ($$v) {_vm.price=$$v},expression:"price"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),(!_vm.editButton)?_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.submit}},[_vm._v(" "+_vm._s(_vm.$t('save'))+" ")]):_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.update}},[_vm._v(" "+_vm._s(_vm.$t('update'))+" ")])],1)],1)],1)],1)]],2)],1),_c('v-data-table',{staticClass:"expand-table-style",attrs:{"headers":_vm.headers,"items":_vm.combinations,"show-select":"","single-select":"","locale":_vm.$i18n.locale},on:{"item-selected":_vm.handlerDefaultCombination},scopedSlots:_vm._u([{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleEditButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.deleteEvent(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('delete')))])],1)],1)],1)]}}]),model:{value:(_vm.defaultCombination),callback:function ($$v) {_vm.defaultCombination=$$v},expression:"defaultCombination"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }