





































































































































































































































/* eslint-disable */
import draggable from "vuedraggable";
import { Component, Vue, Watch } from 'vue-property-decorator'
import { readCombinations } from '@/store/admin/getters';
import { readCategories } from '@/store/categories/getters';
import {
    dispatchCreateServiceCombinationProducts,
    dispatchCreateServiceProduct,
    dispatchCreateServiceProductPrice,
    dispatchDeleteServiceCombinationProduct,
    dispatchDeleteServiceProduct,
    dispatchDeleteServiceProductPrice,
    dispatchGetProducts,
    dispatchGetRelatedServiceProducts,
    dispatchGetServiceById,
    dispatchGetServiceCombinations,
    dispatchGetUnitById,
    dispatchUpdateProductServicePrice,
    dispatchUpdateServiceProductPosition,
    dispatchUpdateServiceProductPrice
} from '@/store/admin/actions';
import {commitAddNotification} from "@/store/main/mutations";
import { dispatchGetCategories } from '@/store/categories/actions';
import { IPositionDto } from "@/interfaces";

@Component({
  components: {
    draggable
  }
})
export default class RelatedProducts extends Vue {
    //DATA
    productAutocompleteValue: any = [];
    defaultCombination: any = [];
    columns = 1;
    panel = -1;
    valid = true;
    status = false;

    activeButtonAddNewRange = false;
    disableProductList = false;
    date = new Date().getMilliseconds();

    rangeList: any = [];
    serviceUnit: any = {};

    dialog: boolean = false;
    productToEdit = {
        id: '',
        price: '',
        service_id: '',
        product_id: '',
        name: '',
        service_combination_id: ''
    };
    rangeErrorFrom = '';
    updatePositionLoader = false;

    defaultProduct: number | null = null

    @Watch('panel')
    public onPanelChange(
        newVal
    ) {
      if (newVal < 0) {
        this.defaultProduct = null
      } else {
        let product = this.combinations[newVal].service_products.find(i => i.position === 0) || this.combinations[newVal].service_products[0]

        if (product) {
          this.defaultProduct = product.service_product_id
        }
        
      }
    }

    //COMPUTED
    get categories() {
        return readCategories(this.$store);
    }
    get products() {
        return this.$store.state.admin.products.map(item => {
            let updatedProductData = {}
            updatedProductData = {...item, disabled: this.disableProductList}
            return updatedProductData
        })
    }
    get currentService() {
        return this.$store.state.admin.serviceById
    }
    get serviceId() {
        return this.$route.params.id
    }
    get combinations() {
        const typeList = readCombinations(this.$store);
        if (typeList.length) {
            return typeList.reduce((result: any, currentType) => {

                let typeRangeList: any = [];
                let updType: any = {
                    ...currentType,
                    productAutocompleteValue: []
                };
                delete updType.service_products;
                // fill updated service product item
                updType.service_products = currentType.service_products.map(serviceProduct => {
                    const updServiceProduct = {
                        ...serviceProduct,
                    };
                    updServiceProduct.range = serviceProduct.range;
                    updServiceProduct.service_id = currentType.service_id;
                    return updServiceProduct
                }).sort((prev, next) => prev.service_product_id - next.service_product_id);
                // add productAutocompleteValue field to type object
                updType.productAutocompleteValue = updType.service_products.map(serviceProduct => {
                    const selectedProduct = this.products.find(product => product.id === serviceProduct.product_id)
                    if(selectedProduct) {
                        return selectedProduct
                    }
                });
                // fill and update type ranges
                currentType.service_products.forEach(serviceProduct => {
                    serviceProduct.range.forEach(prodRange => {
                        let updProdRange = {...prodRange};
                        updProdRange.service_combination_id = serviceProduct.service_combination_id;
                        updProdRange.service_product_id = serviceProduct.service_product_id;
                        updProdRange.service_combination_id = currentType.id;
                        typeRangeList.push(updProdRange)
                    })
                });
                if (typeRangeList.length) {
                   const updatedUnitList: any = [];
                    typeRangeList.forEach(productPrice => {
                        const updatedProduct = {
                            service_product_price_id: productPrice.id,
                            id: productPrice.id,
                            price: productPrice.price,
                            service_product_id: productPrice.service_product_id
                        };
                        const newRange: any = {
                            id: productPrice.id,
                            amount_max: productPrice.amount_max,
                            amount_min: productPrice.amount_min,
                            products: []
                        };
                        const productPriceExistIndex = updatedUnitList.findIndex(range => range.amount_max == productPrice.amount_max && range.amount_min == productPrice.amount_min);

                        if(productPriceExistIndex !== -1) {
                            updatedUnitList[productPriceExistIndex].products.push(updatedProduct)
                            updatedUnitList[productPriceExistIndex].products.sort((prev, next) => prev.service_product_id - next.service_product_id);

                        } else {
                            newRange.products.unshift(updatedProduct)
                            updatedUnitList.push(newRange)
                            newRange.products.sort((prev, next) => prev.service_product_id - next.service_product_id)
                        }
                    });
                    updType.range = updatedUnitList.sort((prev, next) => prev.amount_min - next.amount_min);
                }
                result.push(updType);

                return result
            }, [])
        } else {
            return []
        }
    }
    //METHODS
    rangeValidationMin(range, index, rangeList) {
        if (rangeList && rangeList.length >= 1) {
            if (+range.amount_min >= +range.amount_max) {
                let val = +JSON.parse(JSON.stringify(range.amount_max)) + 1
                // this.rangeErrorFrom = `The value must be less then ${+JSON.parse(JSON.stringify(range.amount_max))}`
                return `min_value:'${val}`
            }
            if (index > 0) {
                let previousIndex = +JSON.parse(JSON.stringify(index)) - 1
                let prevMax = +rangeList[previousIndex].amount_max
                let curMin = +range.amount_min

                if (curMin <= prevMax) {
                    const min = +JSON.parse(JSON.stringify(rangeList[previousIndex].amount_max)) + 1;
                    const max = +JSON.parse(JSON.stringify(range.amount_max)) - 1;
                    // this.rangeErrorFrom = min == max ? `The value must be ${min}` : `The value must be between ${min} and ${max}`
                    return `between:${min},${max}`
                }
            }
        }
    }
    rangeValidationMax(range, index, rangeList) {
        if (rangeList && rangeList.length >= 1) {
            if (+range.amount_min >= +range.amount_max) {
                let val = +JSON.parse(JSON.stringify(range.amount_min)) + 1
                // this.rangeErrorFrom = `The value must be less then ${+JSON.parse(JSON.stringify(range.amount_max))}`
                return `min_value:'${val}`
            }
            if (index > 0) {
                let previousIndex = +JSON.parse(JSON.stringify(index)) - 1
                let prevMin = +rangeList[previousIndex].amount_min
                let curMax = +range.amount_max

                if (curMax <= prevMin) {
                    const min = +JSON.parse(JSON.stringify(rangeList[previousIndex].amount_min)) + 1;
                    // const max = +JSON.parse(JSON.stringify(range.amount_max)) - 1;
                    // this.rangeErrorFrom = min == max ? `The value must be ${min}` : `The value must be between ${min} and ${max}`
                    return `min_value:${min}`
                }
            }
        }
    }
    handleEditServiceProductPrice(value) {
        this.dialog = true;
        this.productToEdit.id = value.service_product_id;
        this.productToEdit.service_combination_id = value.service_combination_id;
        this.productToEdit.name = value.name;
        this.productToEdit.price = value.price;
        this.productToEdit.service_id = value.service_id;
        this.productToEdit.product_id = value.product_id

    }
    async handlerProductAutocomplete(value, typeId) {
        const type = this.combinations.find(type => type.id === typeId);
        const existServiceProducts = type.service_products.map(product => {
            const mainProduct = this.products.find(prod => prod.id === product.product_id);
            if (mainProduct) {
                let updMainProduct = {
                    ...mainProduct
                }
                updMainProduct.service_combination_id = typeId;
                updMainProduct.service_product_id = product.service_product_id;
                return updMainProduct
            }
        });
        let productToAdd = value.find(product => {
            let diffId = existServiceProducts.find(serviceProd => serviceProd.id === product.id);
            if (!diffId) {
                return product
            } else {
                return undefined
            }
        }, {});
        let productToDelete = existServiceProducts.find(serviceProduct => {
            let diffId = value.find(selProd => selProd.id === serviceProduct.id);
            if (!diffId) {
                return serviceProduct
            } else {
                return undefined
            }
        }, {});

        if (productToAdd) {
            const selectedProduct = {
                service_id : type.service_id,
                product_id : productToAdd.id,
                service_combination_id : typeId,
                price : productToAdd.price
            };

            await dispatchCreateServiceProduct(this.$store, selectedProduct).then(async response => {
                if (type.range && type.range.length) {
                    const product = this.products.find(product => product.id === response.product_id);
                    type.range.forEach(unit => {
                        const productRange = {
                            service_product_id: response.id,
                            name: product.name,
                            price: product.price
                        }
                        const existProductRange = unit.products.find(productRange => productRange.id === product.id);
                        if (!existProductRange) {
                            unit.products.push(productRange);
                            unit.products.forEach(product => {
                                const rangeItem = {
                                    service_product_id: product.service_product_id,
                                    amount_min: unit.amount_min,
                                    amount_max: unit.amount_max,
                                    price: product.price
                                };
                                if (!product.service_product_price_id) {
                                    this.rangeList.push(rangeItem);
                                }

                            })

                        }
                    });

                    let listOfRange = this.rangeList

                    const selfStore = this.$store;
                    const queueForSending = function (listOfRange) {
                        let queue: any = {};
                        listOfRange.forEach((rangeItem, index) => {
                            queue[index] = new Promise((resolve) => {
                                dispatchCreateServiceProductPrice(selfStore, rangeItem).then(response => {
                                    resolve(response)
                                })
                            })
                        });
                        return Object.values(queue)
                    };
                    const promisesForSend = queueForSending(listOfRange);

                    await Promise.all(promisesForSend).then(async response => {
                        this.rangeList = []
                        this.disableProductList = false

                    });
                }
                await this.getServiceCombinations();
            });
            return
        }

        if (productToDelete) {
            const selfStore = this.$store
            let productPricesForDelete: any = []
            if(type.range && type.range.length) {
                type.range.forEach(unit => {
                    let productForDelete = unit.products.find(productPrice => productPrice.service_product_id === productToDelete.service_product_id);
                    if (productForDelete) {
                        productPricesForDelete.push(productForDelete)
                    }
                })
                const queueForSending = function (products) {
                    let queue: any = {}
                    products.forEach((productItem, index) => {
                        queue[index] = new Promise((resolve) => {
                            dispatchDeleteServiceProductPrice(selfStore, productItem.id).then(response => {
                                resolve(response)
                            })
                        })
                    });
                    return Object.values(queue)
                };
                const promisesForSend = queueForSending(productPricesForDelete);

                if (promisesForSend.length) {
                    await Promise.all(promisesForSend).then(async response => {
                        if (response.length) {
                            await dispatchDeleteServiceCombinationProduct(this.$store, productToDelete).then(async response => {
                                if (productToDelete.service_product_id) {
                                  await dispatchDeleteServiceProduct(this.$store, productToDelete.service_product_id);
                                }
                                await this.getRelatedServiceProducts();
                                await this.getServiceCombinations();
                            })
                            await this.getServiceCombinations();
                        }
                    })
                }
            } else {
                await dispatchDeleteServiceCombinationProduct(this.$store, productToDelete).then(async response => { 
                    if (productToDelete.service_product_id) {
                      await dispatchDeleteServiceProduct(this.$store, productToDelete.service_product_id);
                    }
                    await this.getRelatedServiceProducts();
                    await this.getServiceCombinations();
                });

                await this.getRelatedServiceProducts();
                await this.getServiceCombinations();
            }
        }
        await this.getServiceCombinations();
    }
    async updateServiceProductPrice() {
        await dispatchUpdateProductServicePrice(this.$store, this.productToEdit)
        this.dialog = false
        await this.getRelatedServiceProducts();
        await this.getServiceCombinations();
    }
    async updatePrice(value){
        if (value) {
            if (value.product.id) {
                const updatedProduct = {
                    amount_min: value.unit.amount_min,
                    amount_max: value.unit.amount_max,
                    price: value.product.price,
                    service_product_id: value.product.service_product_id
                };
                await dispatchUpdateServiceProductPrice(this.$store, {id: value.product.id, updatedProduct}).then(async resp => {
                    await this.getServiceCombinations();
                });
            } else {
                // commitAddNotification(this.$store, { content: 'fdsfds', color: 'warning' });
            }
        }
    }
    async updateRange(value){
        this.$validator.validate().then( async valid => {
            if (valid) {
                const selfStore = this.$store;
                const rangeList: any = [];
                    value.products.forEach(product => {
                        const rangeItem = {
                            service_product_price_id: product.service_product_price_id,
                            service_product_id: product.service_product_id,
                            amount_min: value.amount_min,
                            amount_max: value.amount_max,
                            price: product.price
                        };
                        rangeList.push(rangeItem)
                    });

                const queueForSending = function (rangeList) {
                    let queue: any = {}
                    rangeList.forEach((rangeItem, index) => {
                        queue[index] = new Promise((resolve) => {
                            dispatchUpdateServiceProductPrice(selfStore, {id: rangeItem.service_product_price_id, updatedProduct: {
                                    amount_min: rangeItem.amount_min,
                                    amount_max: rangeItem.amount_max,
                                    price: rangeItem.price,
                                    service_product_id: rangeItem.service_product_id,
                            }}).then(response => {
                                resolve(response)
                            })
                        })
                    });
                    return Object.values(queue)
                };
                const promisesForSend = queueForSending(rangeList);
                await Promise.all(promisesForSend).then(async response => {
                    await this.getServiceCombinations().then(response => {
                    });
                });
            } else {
                this.rangeErrorFrom = ' '
            }
        });
    }
    handlerAddNewRange(type) {
        let typeRangeList = type.range || [];
        this.date = new Date().getMilliseconds();

        if (typeRangeList && typeRangeList.length) {
            const productToAdd = typeRangeList[typeRangeList.length - 1]
            const clonedProducts = JSON.parse(JSON.stringify(productToAdd.products))
            clonedProducts.forEach(prod => {
                delete prod?.service_product_price_id
            });
            typeRangeList.push({
                id: typeRangeList.length+1,
                amount_min: +productToAdd.amount_max + 1,
                amount_max: +productToAdd.amount_max + 2,
                products: clonedProducts
            })
            this.activeButtonAddNewRange = true
            this.addRange(typeRangeList)
        } else {
            const randomRange = JSON.parse(JSON.stringify(typeRangeList.length))
            const productsForRange = type.service_products.map(serviceProduct => {
                const product = this.products.find(product => product.id == serviceProduct.product_id);
                return {
                    id: product.id,
                    service_product_id: serviceProduct.service_product_id,
                    name: product.name,
                    price: product.price
                }
            });
            typeRangeList.push({
                id: 1,
                amount_min: randomRange,
                amount_max: randomRange + 1,
                products: [...productsForRange]
            })
            this.activeButtonAddNewRange = true
            this.addRange(typeRangeList)
        }
    }
    async addRange(ranges) {
        const selfStore = this.$store;
        const rangeList: any = [];
        ranges.forEach(unit => {

            unit.products.forEach(product => {

                const rangeItem = {
                    service_product_id: product.service_product_id,
                    amount_min: unit.amount_min,
                    amount_max: unit.amount_max,
                    price: product.price
                };
                if (!product.service_product_price_id) {
                    rangeList.push(rangeItem)
                }

            })
        });

        const queueForSending = function (rangeList) {
          let queue: any = {}
          rangeList.forEach((rangeItem, index) => {
             queue[index] = new Promise((resolve) => {
                 dispatchCreateServiceProductPrice(selfStore, rangeItem).then(response => {
                    resolve(response)
                 })
             })
          });
          return Object.values(queue)
        };
        const promisesForSend = queueForSending(rangeList);

        await Promise.all(promisesForSend).then(async response => {
            await this.getServiceCombinations().then(response => {
                this.activeButtonAddNewRange = false
            });
        })
    }
    async deleteRange(unit, index){
        const selfStore = this.$store
        const queueForSending = function (products) {
            let queue: any = {}
            products.forEach((productItem, index) => {
                queue[index] = new Promise((resolve) => {
                    dispatchDeleteServiceProductPrice(selfStore, productItem.id).then(response => {
                        resolve(response)
                    })
                })
            });
            return Object.values(queue)
        };
        const promisesForSend = queueForSending(unit.products);

        await Promise.all(promisesForSend).then(async response => {
            await this.getServiceCombinations();
        })
    }
    async getUnitById(){
        const serviceUnit = await dispatchGetUnitById(this.$store, this.currentService.unit_id);
        this.serviceUnit = serviceUnit.short_name
    }
    async getRelatedServiceProducts(){
        const requestParams = {
            id: this.serviceId,
            limit: '100',
            skip: '0',
        }
        await dispatchGetRelatedServiceProducts(this.$store, requestParams)
    }
    async getServiceCombinations() {
        await dispatchGetServiceCombinations(this.$store, this.serviceId);
        const defaultCombination = this.combinations.find(combination => combination.default === true);
        this.defaultCombination.push(defaultCombination)
    }

    updateDefaultProduct(payload) {
      if (this.panel !== undefined && this.panel !== null && this.combinations !== null) {
        this.updatePosition(payload, this.panel )
      }
    }

    async updatePosition(currentProduct, panelIndex = 0) {
      if (this.panel === undefined || this.panel === null) return
      let list: IPositionDto[] = this.combinations[panelIndex].service_products.map((product) => {
        if (currentProduct.service_product_id === product.service_product_id) {
          return {
            id: product.service_product_id,
            position: 0
          }
        }
        return {
          id: product.service_product_id,
          position: 1
        }
      })

      await dispatchUpdateServiceProductPosition(this.$store, list) 
    }

    //HOOKS
    async mounted(): Promise<void> {
        await dispatchGetCategories(this.$store);
        await dispatchGetProducts(this.$store);
        await dispatchGetServiceById(this.$store, this.serviceId);
        await this.getUnitById();
        await this.getRelatedServiceProducts();
        await this.getServiceCombinations();
    }
}
