















































/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { readProducts } from '@/store/admin/getters';
import { dispatchGetProducts, dispatchDeleteProduct } from '@/store/admin/actions';

@Component({
  components: {
      ConfirmDialog: () => import("@/components/ui/ConfirmDialog.vue")
  }
})
export default class Categories extends Vue {
    dialog = false;
    currentItem = null;

    //DATA
    headers = [
        {
            text: '',
            sortable: false,
            align: 'center',
        },
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        },
        {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('shortDescription'),
            sortable: true,
            value: 'short_description',
            align: 'left',
        },
        {
            text: '',
            value: 'button-edit',
            width: '50px',
            sortable: false,
            align: 'center',
        },
        {
            text: '',
            value: 'button-menu',
            width: '50px',
            sortable: false,
            align: 'center',
        }
    ];
    valid = false;
    status = false;

    //COMPUTED
    get products() {
        return this.$store.state.admin.products.sort((prev, next) => prev.id - next.id);
    }

    //METHODS
    async deleteProduct(value) {
      if (value) {
        await dispatchDeleteProduct(this.$store, value.id)
        await dispatchGetProducts(this.$store);
      }
    }
    handleEditButton(value) {
        this.$router.push({name: 'main-admin-product-edit', params: {id: value.id}})
    }
    //HOOKS
    async mounted(): Promise<void> {
        await dispatchGetProducts(this.$store);
    }
}
