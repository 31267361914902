var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"light":""}},[_c('v-toolbar-title',[_vm._v(" "+_vm._s(_vm.$t('manageRooms'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","to":"/main/admin/rooms/create"}},[_vm._v(_vm._s(_vm.$t('createRoom')))])],1),_c('v-data-table',{key:_vm.rerenderList,attrs:{"headers":_vm.headers,"items":_vm.list,"locale":_vm.$i18n.locale},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('draggable',{attrs:{"list":_vm.list,"tag":"tbody","disabled":_vm.updatePositionLoader},on:{"end":_vm.onDropCallback}},_vm._l((props.items),function(item,index){return _c('data-table-row-handler',{key:index,attrs:{"item":item,"headers":_vm.headers},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('noServicesAttached'))+" ")]},proxy:true},{key:"item.button-drag",fn:function(){return [_c('v-icon',[_vm._v("mdi-drag")])]},proxy:true},{key:"item.default",fn:function(ref){
var item = ref.item;
return [_c('v-radio-group',{staticClass:"my-0",attrs:{"hide-details":""},model:{value:(_vm.defaultRoom),callback:function ($$v) {_vm.defaultRoom=$$v},expression:"defaultRoom"}},[_c('v-radio',{attrs:{"value":item.id},on:{"change":function($event){return _vm.updateDefaultRoom(item)}}})],1)]}},{key:"item.button-edit",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.handleEditButton(item)}}},[_vm._v("mdi-pencil")])]}},{key:"item.button-menu",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v(" mdi-dots-vertical")])]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.handleDeleteButton(item)}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("delete")))])],1)],1)],1)]}}],null,true)})}),1)]}}])}),_c('ConfirmDialog',{attrs:{"show":_vm.dialog},on:{"closeModal":function($event){_vm.dialog = false; _vm.currentItem = null},"confirmModalAgree":function($event){return _vm.deleteRoom(_vm.currentItem)}}}),_c('AlertDialog',{attrs:{"show":_vm.alertDialog,"title":"defaultRoomCantDeleted"},on:{"closeModal":function($event){_vm.alertDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }