


































































































































/* eslint-disable */

import {Component, Vue, Watch} from 'vue-property-decorator';
import {readAdminUsers} from '@/store/admin/getters';
import { dispatchDeleteUser, dispatchGetUsers } from '@/store/admin/actions';
import { commitClearUserList } from '@/store/admin/mutations';

@Component({
      components: {
          AlertDialog: () => import("@/components/ui/AlertDialog.vue"),
          ConfirmDialog: () => import("@/components/ui/ConfirmDialog.vue")
      }
  })
export default class AdminUsers extends Vue {
  public tab = null
  public alertDialog = false
  public confirmDialog = false
  public currentItem: {id: string, is_superuser: boolean, projects?: {id: number, devis_name: string}[], projects_m?: {id: number, devis_name: string}[]} | null = null
  public headersAll = [
    {
      text: this.$t('fullName'),
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: this.$t('email'),
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: this.$t('isActive'),
      sortable: true,
      value: 'check_isActive',
      align: 'left',
    },
    {
      text: this.$t('isSuperuser'),
      sortable: true,
      value: 'check_superUser',
      align: 'left',
    },
    {
      text: this.$t('actions'),
      value: 'button',
      width: '50px',
      sortable: false,
      align: 'center',
    }
  ];
  public headersAdmin = [
    {
      text: this.$t('fullName'),
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: this.$t('email'),
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: this.$t('projectName'),
      sortable: false,
      value: 'projects_m',
      align: 'left',
    },
    {
      text: this.$t('isActive'),
      sortable: true,
      value: 'check_isActive',
      align: 'left',
    },
    {
      text: this.$t('isSuperuser'),
      sortable: true,
      value: 'check_superUser',
      align: 'left',
    },
    {
      text: this.$t('actions'),
      value: 'button',
      width: '50px',
      sortable: false,
      align: 'center',
    },
    {
      text: '',
      value: 'button-menu',
      width: '50px',
      sortable: false,
      align: 'center',
    },
  ];
  public headersUser = [
    {
      text: this.$t('fullName'),
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: this.$t('email'),
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: this.$t('projectName'),
      sortable: false,
      value: 'projects',
      align: 'left',
    },
    {
      text: this.$t('isActive'),
      sortable: true,
      value: 'check_isActive',
      align: 'left',
    },
    {
      text: this.$t('isSuperuser'),
      sortable: true,
      value: 'check_superUser',
      align: 'left',
    },
    {
      text: this.$t('actions'),
      value: 'button',
      width: '50px',
      sortable: false,
      align: 'center',
    },
    {
      text: '',
      value: 'button-menu',
      width: '50px',
      sortable: false,
      align: 'center',
    },
  ];
  search = '';
  itemsPerPage = 20
  nav: Array<{title: string, queryParams: string}> = [
      {
          title: `${this.$t('all')}`,
          queryParams: ''
      },
      {
          title: `${this.$t('admins')}`,
          queryParams: '?skip=0&limit=3500&super_user=true'
      },
      {
          title: `${this.$t('users')}`,
          queryParams: '?skip=0&limit=3500&super_user=false'
      }
  ];
  //COMPUTED
  get users() {
    return readAdminUsers(this.$store);
  }
  get headers() {
    switch(true) {
      case this.tab === 0:
        return this.headersAll
      case this.tab === 1:
        return this.headersAdmin
      case this.tab === 2:
        return this.headersUser
      default:
        return []
    }
  }

  get projects() {
    let list = this.currentItem && this.tab === 1 ? this.currentItem.projects_m : (this.currentItem?.projects || [])
    return list
  }
  async getUsers(queryParams?) {
      await commitClearUserList(this.$store)
      await dispatchGetUsers(this.$store, {queryParams: queryParams || '?limit=3500'});
  }
  handleEditButton(value) {
    this.$router.push({name: 'main-admin-users-edit', params: {id: value.id}})
  }
  async handleMenuButton(user) {
    this.currentItem = user
    if (user.projects?.length > 0 || user.projects_m?.length > 0) {
      this.alertDialog = true
      return false;
    }
    this.confirmDialog = true
  }

  closeModal() {
    this.alertDialog = false
    setTimeout(() => {this.currentItem = null}, 300)
  }

  async deleteEvent(value) {
      if (value) {
          await dispatchDeleteUser(this.$store, value.id);
          await this.getUsers(this.nav[this.tab ?? 0].queryParams);
      }
  }

  async mounted() {
      await this.getUsers()
  }
}
