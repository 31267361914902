











/* eslint-disable */
import Vue from 'vue'
import { Component, Prop, Emit } from 'vue-property-decorator'
import {IQuotaProfileCreate} from '@/interfaces';
import {dispatchUpdateQuota, dispatchGetQuotas} from '@/store/admin/actions';
import {readAdminOneQuota} from '@/store/admin/getters';
import QuotaForm from "./QuotaForm.vue";

@Component({
    components: {
        "quota-form": QuotaForm
    }
})
export default class EditQuota extends Vue {
    //COMPUTED
    get quotaId() {
        return +this.$route.params.quotaId
    }
    //METHODS
    async submit(values) {
        if (await this.$validator.validateAll()) {
            // const formData = new FormData()
            const quotaId = this.$route.params.quotaId;
            const updatedConfig = {
                quota_name: '',
                project_id: '',
                edit: '',
                name: '',
                id: '',
            };
            updatedConfig.quota_name = values.name;
            updatedConfig.project_id = this.$route.params.id;
            updatedConfig.id = quotaId;
            updatedConfig.edit = values.edit;
            await dispatchUpdateQuota(this.$store, updatedConfig);
            await this.$router.push('/main/admin/projects/all');
        }
    }
    async mounted() {
        await dispatchGetQuotas(this.$store, this.$route.params.id)
    }
}

