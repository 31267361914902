import Vue from 'vue';
import VeeValidate from 'vee-validate';
import validationMessagesEn from 'vee-validate/dist/locale/en';
import validationMessagesFr from 'vee-validate/dist/locale/fr';
import { i18n } from '@/plugins/i18n'


Vue.use(VeeValidate, {
    i18nRootKey: 'validations',
    i18n,
    dictionary: {
        en: validationMessagesEn,
        fr: validationMessagesFr,
    }
});
