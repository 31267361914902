







































































































































































































































/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator';
import {
    dispatchGetProject,
    dispatchGetQuotas, dispatchGetQuotasStatuses,
    dispatchGetSuperUsers,
    dispatchGetUsers
} from '@/store/admin/actions';
import {readAdminOneProject, readAdminUsers, readQuotas} from '@/store/admin/getters';
import axios from "axios";
import {apiUrl} from "@/env";
import {readToken} from "@/store/main/getters";
@Component
export default class ViewProject extends Vue {


    //DATA
    valid = true;
    serverResponse: any = null;
    date = (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    menu1 = false;
    menu2 = false;

    headers = [
        {
            text: this.$t('ID'),
            sortable: true,
            value: 'id',
            align: 'left',
        }, {
            text: this.$t('name'),
            sortable: true,
            value: 'name',
            align: 'left',
        },
        {
            text: this.$t('edit'),
            sortable: true,
            value: 'edit',
            align: 'left',
        },
        {
            text: this.$t('customStatus'),
            sortable: true,
            value: 'custom_status',
            align: 'left',
        },
    ];

    address =  '';
    city =  '';
    type_of_object =  '';
    type_of_renovation =  '';
    product_class =  '';
    criterias: any = [];
    budget =  '';
    start_date = '';
    end_date =  '';
    state_of_object =  '';
    simple_choice =  '';
    square =  '';
    need_an_architect =  '';
    need_an_education =  '';
    need_credit =  '';
    selectedUser: any = null;
    devis_name = '';
    selectedProjectManager: any = null;

    //COMPUTED
    get token() {
        return readToken(this.$store)
    }
    get authHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${this.$store.state.main.token}`,
            },
        };
    }
    get quotas() {
        return readQuotas(this.$store).map(quota => {
            const updatedQuota: any = {
                ...quota
            }
            updatedQuota.custom_status = this.customStatusList[quota.custom_status];
            return updatedQuota
        });
    }
    get customStatusList(): any {
        return this.$store.state.admin.quotasStatuses
    }
    get project() {
        return readAdminOneProject(this.$store)(+this.$router.currentRoute.params.id);
    }
    get users() {
        return readAdminUsers(this.$store);
    }
    get typeOfObjectValues() {
        const typeOfObject = this.serverResponse.type_of_object
        return this.createValuesForInput(typeOfObject)
    }
    get typeOfRenovationValues() {
        const typeOfRenovation = this.serverResponse.type_of_renovation
        return this.createValuesForInput(typeOfRenovation)
    }
    get simpleChoiceValues() {
        const simpleChoice = this.serverResponse.simple_choice
        return this.createValuesForInput(simpleChoice)
    }
    get productClassValues() {
        const productClass = this.serverResponse.product_class
        return this.createValuesForInput(productClass)
    }
    get criteriasValues() {
        const criteriasValues = this.serverResponse.criterias
        return this.createValuesForInput(criteriasValues)
    }
    get budgetValues() {
        const budgetValues = this.serverResponse.budget

        const valuesList: any = []
        const entries = Object.entries(budgetValues)
        for (const [key, value] of entries) {
            const valueItem = {text: value, value: value}
            valuesList.push(valueItem)
        }
        return valuesList
    }
    get dateValues() {
      const start_date = this.serverResponse.start_date
      return this.createValuesForInput(start_date)
    }
    get stateOfObjectValues() {
        const stateOfObjectValues = this.serverResponse.state_of_object
        return this.createValuesForInput(stateOfObjectValues)
    }
    get superUsers() {
        return this.$store.state.admin.superUsers
    }

    //METHODS
    cancel() {
        this.$router.back();
    }
    fillModel() {
        if (this.project) {
            const criteriasArray =  this.project.criterias.replace(/\s+/g, '').split(",")
            this.address = this.project.address
            this.city = this.project.city
            this.type_of_object = this.project.type_of_object
            this.type_of_renovation = this.project.type_of_renovation
            this.product_class = this.project.product_class
            this.criterias = criteriasArray
            this.start_date = `${this.project.start_date}`
            this.end_date = this.project.end_date as string
            this.state_of_object = this.project.state_of_object
            this.simple_choice = this.project.simple_choice
            this.square = this.project.square
            this.budget = this.serverResponse.budget[this.project.budget]
            this.need_credit = this.project.need_credit
            this.need_an_education = this.project.need_an_education
            this.need_an_architect = this.project.need_an_architect
            this.devis_name = this.project.devis_name
            this.selectedProjectManager = this.superUsers.find(user => {
                const projectManagerId: any = this.project?.manager_id
                return user.id == projectManagerId
            })
            this.selectedUser = this.users.find(user => {
                const projectOwnerId: any = this.project?.owner_id
                return user.id == projectOwnerId
            })
        }
    }
    createValuesForInput(dict): any {
        if (dict) {
            const valuesList: any = []
            const entries = Object.entries(dict)
            for (const [key, value] of entries) {
                const valueItem = {text: value, value: key}
                valuesList.push(valueItem)
            }
            return valuesList
        }
    }
    async dicts() {
        const dicts = await axios.get<any>(`${apiUrl}/api/v1/projects/dicts`, this.authHeaders)
        this.serverResponse = dicts.data
    }
    async mounted(): Promise<void> {
        await dispatchGetProject(this.$store);
        await dispatchGetUsers(this.$store, {queryParams: '?limit=3500'});
        await dispatchGetSuperUsers(this.$store, {queryParams: '?limit=3500&super_user=true'});
        await dispatchGetQuotas(this.$store, this.$route.params?.id);
        await dispatchGetQuotasStatuses(this.$store)
        await this.dicts();
        this.fillModel();
    }
}
