







































































































































































































































































/* eslint-disable */
import {Component, Vue, Watch} from 'vue-property-decorator';
import { IProjectProfileCreate } from '@/interfaces';
import {dispatchGetProject, dispatchCreateProject, dispatchGetUsers, dispatchGetSuperUsers} from '@/store/admin/actions';
import axios from "axios";
import {apiUrl} from "@/env";

import { readAdminUsers, readProjects} from "@/store/admin/getters";

@Component
export default class CreateProjects extends Vue {
    //DATA
    valid =  false;
    serverResponse: any = {};
    date =  (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10);
    menu1 =  false;
    menu2 =  false;
    start_date = '';
    end_date =  '';
    address =  '';
    address_construction =  '';
    email =  '';
    initial_message =  '';
    postal_code =  '';
    type_of_extension = ''
    type_of_elevation = ''
    city =  '';
    type_of_object =  '';
    type_of_renovation =  '';
    product_class =  '';
    criterias =  [];
    budget =  0;
    state_of_object =  '';
    need_an_architect =  '';
    need_an_education =  '';
    need_credit =  '';
    simple_choice =  '';
    square =  '';
    selectedUser: any = null;
    fieldRules = [
        value => !!value || `${this.$t('required')}.`
    ];
    rulesNumber = [ v => {
    if(v) {
        return /^\d+$/.test(v) || 'Seul numéro'
      } else {
        return true
    }
    }];
    devis_name = '';
    selectedProjectManager: any = null;
    // multipleFieldRules = [(v) =>  !!v.length || 'Required.'];

    //COMPUTED
    get authHeaders() {
        return {
            headers: {
                Authorization: `Bearer ${this.$store.state.main.token}`,
            },
        };
    }
    get projects() {
        return readProjects(this.$store);
    }
    get usersWithoutProjects() {
        return readAdminUsers(this.$store).filter(user => !user.projects || (user.projects && user.projects.length === 0));
    }
    get users() {
        return readAdminUsers(this.$store)
    }
    get superUsers() {
        return this.$store.state.admin.superUsers
    }
    get typeOfElevationValues() {
        const typeOfRenovation = this.serverResponse.type_of_elevation
        return this.createValuesForInput(typeOfRenovation)
    }
    get typeOfExtensionValues() {
        const typeOfRenovation = this.serverResponse.type_of_extension
        return this.createValuesForInput(typeOfRenovation)
    }
    get typeOfObjectValues() {
        const typeOfObject = this.serverResponse.type_of_object
        return this.createValuesForInput(typeOfObject)
    }
    get typeOfRenovationValues() {
        const typeOfRenovation = this.serverResponse.type_of_renovation
        return this.createValuesForInput(typeOfRenovation)
    }
    get simpleChoiceValues() {
        const simpleChoice = this.serverResponse.simple_choice
        return this.createValuesForInput(simpleChoice)
    }
    get productClassValues() {
        const productClass = this.serverResponse.product_class
        return this.createValuesForInput(productClass)
    }
    get criteriasValues() {
        const criteriasValues = this.serverResponse.criterias
        return this.createValuesForInput(criteriasValues)
    }
    get budgetValues() {
        const budgetValues = this.serverResponse.budget
        return this.createValuesForInput(budgetValues)
    }
    get dateValues() {
      const start_date = this.serverResponse.start_date
      return this.createValuesForInput(start_date)
    }
    get stateOfObjectValues() {
        const stateOfObjectValues = this.serverResponse.state_of_object
        return this.createValuesForInput(stateOfObjectValues)
    }

    //METHODS
    cancel() {
        this.$router.back();
    }
    async dicts() {
        const dicts = await axios.get<any>(`${apiUrl}/api/v1/projects/dicts`, this.authHeaders)
        this.serverResponse = dicts.data
    }
    reset() {
        this.address = ''
        this.city = ''
        this.type_of_object = ''
        this.type_of_renovation = ''
        this.product_class = ''
        this.criterias = []
        this.budget = 0
        this.start_date = ''
        this.end_date = ''
        this.state_of_object = ''
        this.simple_choice = ''
        this.square = ''
        this.devis_name = ''
        this.selectedUser = null
        this.selectedProjectManager = ''
        this.email = ''
        this.address_construction =  ''
        this.initial_message =  ''
        this.postal_code =  ''
        this.type_of_extension = ''
        this.type_of_elevation = ''
    }
    createValuesForInput(dict): any {
        if (dict) {
            const valuesList: any = []
            const entries = Object.entries(dict)
            for (const [key, value] of entries) {
                const valueItem = {text: value, value: key}
                valuesList.push(valueItem)
            }
            return valuesList
        }
    }
    customFilter (item, queryText) {
      const textOne = item.full_name?.toLowerCase()
      const textTwo = item.email?.toLowerCase()
      const searchText = queryText.toLowerCase()

      return textOne?.indexOf(searchText) > -1 ||
        textTwo?.indexOf(searchText) > -1
    }
    async submit(disabledRedirect) {
        if ((this.$refs.form as Vue & { validate: () => boolean }).validate()) {
            const criteriasList = this.criterias.join(", ")

            const updatedConfig: IProjectProfileCreate = {
              address: this.address,
              city: this.city,
              type_of_object: this.type_of_object,
              type_of_renovation: this.type_of_renovation,
              need_an_architect: this.need_an_architect,
              need_an_education: this.need_an_education,
              product_class: this.product_class,
              criterias: criteriasList,
              budget: this.budget,
              need_credit: this.need_credit,
              start_date: this.start_date,
              end_date: this.end_date === "" ? null : this.end_date,
              state_of_object: this.state_of_object,
              simple_choice: this.simple_choice,
              square: this.square,
              owner_id: this.selectedUser?.id,
              devis_name: this.devis_name,
              manager_id: this.selectedProjectManager.id,
              email: this.email,
              address_construction: this.address_construction,
              initial_message: this.initial_message,
              postal_code: this.postal_code,
              type_of_extension: this.type_of_extension,
              type_of_elevation: this.type_of_elevation,
            };

            const project = await dispatchCreateProject(this.$store, updatedConfig);
            if (!disabledRedirect) {
              this.$router.push('/main/admin/projects/all');
            }
            if (project.id && disabledRedirect) {
              this.$router.replace(`/main/admin/projects/edit/${project.id}`);
            }
        }
    }
    setDefaultSimpleChoiceFields() {
      let value = this.simpleChoiceValues.find(i => i.text === 'non')?.value
      if (value) {
        this.need_an_architect = value
        this.need_an_education = value
        this.need_credit = value
      }
    }
    async mounted(): Promise<void> {
        await dispatchGetProject(this.$store);
        await dispatchGetUsers(this.$store, {queryParams: '?limit=3500'});
        await dispatchGetSuperUsers(this.$store, {queryParams: '?&super_user=true'});
        await this.dicts()
        this.setDefaultSimpleChoiceFields()
        this.reset();
    }
}

