













/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'

@Component
export default class Services extends Vue {

}
