










/* eslint-disable */
import { Vue, Component } from 'vue-property-decorator'
import { dispatchCreateProduct } from '@/store/admin/actions';
import ProductForm from "./ProductForm.vue";

@Component({
    components: {
        "product-form": ProductForm
    }
})
export default class CreateCategory extends Vue {

    //METHODS
    async submit(value) {
        if (value) {
            const formData = new FormData()

            formData.append('name', value.name);
            formData.append('image', value.image);
            value.description && formData.append('description', value.description);
            value.short_description && formData.append('short_description', value.short_description);
            formData.append('price', value.price);
            formData.append('reference', value.reference);

            const product: any = await dispatchCreateProduct(this.$store, formData);
            if (!value.disabledRedirect) {
                await this.$router.push('/main/admin/products/all');
            }
            if (product.id && value.disabledRedirect) {
                await this.$router.replace(`/main/admin/products/edit/${product.id}`);
            }
        }
    }
}
