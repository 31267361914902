













































































/* eslint-disable */
import { IPlatformParameters, IPlatformParametersCreateDto, IPlatformParametersUpdateDto } from '@/interfaces';
import { dispatchCreatePlatformParameters, dispatchUpdatePlatformParameters } from '@/store/admin/actions';
import { Component, Vue } from 'vue-property-decorator';

@Component({
    components: {
        CropImage: () => import('@/components/ui/CropImage.vue')
    }
})
export default class GeneralSettings extends Vue {
    parameters = {} as IPlatformParameters
    files = {} as IPlatformParametersCreateDto
    created() {
        this.parameters = {...this.$store.state.admin.platformParameters}
        if (this.$store.state.admin.platformParameters.favicon) {
            this.files.favicon = {}
            this.files.favicon.file = {}
            this.files.favicon.file.filename = "favicon"
        }
    }
    setLogo(payload: {name: string, file: File}) {
        this.files[payload.name] = payload.file
    }
    async submit() {
        if (!this.parameters.id) {
            await dispatchCreatePlatformParameters(this.$store, {name: this.parameters.name, ...this.files})
        }
        if (this.parameters.id) {
            const formData: IPlatformParametersUpdateDto = {}
            for (let key in this.files) {
                if (this.files[key] instanceof File || this.files[key] instanceof Blob) {
                    formData[key] = this.files[key]
                }
            }
            await dispatchUpdatePlatformParameters(this.$store, {name: this.parameters.name, ...formData})
        }
    }
}
